
import { Container } from "@mui/system";
import { useState } from "react";

import { Grid, IconButton, styled, Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { IAppContainerConfig } from "../../interfaces/IAppContainerConfig";
import { CommonProps } from "@mui/material/OverridableComponent";
import YaraIcon from "../icons/YaraIcon";
import { useNavigate } from "react-router-dom";
import { routes } from "../../consts/routes";
import { disabled } from "../../consts/features";

interface IProps {
    title: string;
    config: IAppContainerConfig;
    actionButtons: React.FC[];
    children?: React.ReactNode;
}
export function AppContainerBase(props: IProps & CommonProps){
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { 
        title,
        config,
        actionButtons,
        children,
        ...commonProps
    } = props;

    const defaultStyleProps = {
        paddingLeft: 0,
        paddingRight: 0
    };

    if(!commonProps.style) commonProps.style = defaultStyleProps;
    else commonProps.style = Object.assign({}, defaultStyleProps, commonProps.style);

    const [c] = useState<IAppContainerConfig>(config);

    const LayoutContainer = styled('div')(({theme}) => ({
        backgroundColor: theme.palette.Blue.main,
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        position: 'absolute',
        paddingLeft: 0,
        paddingRight: 0
    }));

    const onHomeClick = () => {
        if (!disabled('AppAuthorized'))
            navigate(routes.Home);
    }

    return (
    <LayoutContainer>
        <Container
            sx={theme => ({
                position: 'relative',
                backgroundColor: '#ffffff',
                padding: theme.spacing(c.spacing),
                borderTopLeftRadius: c.borderRadius,
                borderTopRightRadius: c.borderRadius,
                width: `calc(100% - ${c.sides}px)`,
                height: `calc(${c.headerHeight}px - ${c.top}px)`,
                marginTop: `${c.top}px`,
                marginBottom: '-1px'
            })}
        >
            <Grid container>
                <Grid item xs={10} sm={10} md={10} lg={10} style={{display: 'inline-flex'}}>
                    <IconButton sx={{padding: 0}} onClick={onHomeClick}>
                        <YaraIcon style={{ 
                            height: c.headerHeight/2, 
                            width: c.headerHeight/2, 
                            borderRadius: '5px',
                            marginRight: '10px'
                        }} />
                    </IconButton>
                    <Typography variant={c.titleVariant} style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>{t(title || '')}</Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} style={{display: 'inline-flex', justifyContent: 'flex-end'}}>
                    {actionButtons.map((ActionButton, i) => (<ActionButton key={`header-action-button-${i}`}/>))}
                </Grid>
            </Grid>
        </Container>
        <Container {...commonProps}
            sx={theme => ({
                backgroundColor: '#ffffff',
                padding: theme.spacing(2),
                paddingTop: 0,
                overflow: 'auto',
                width: `100%`,
                height: `calc(100% - ${c.headerHeight}px)`,
            })}
        >
            {children}
        </Container>
    </LayoutContainer>
    );
}