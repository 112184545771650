import { Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableHead, TableRow, TableBody, Typography, Box, TableCell, TableCellProps, Button } from "@mui/material";
import { decimalFormatUp, decimalFormat } from "../../../consts/decimalFormat";
import { IBatch, ICalculatorOutput, IFertilizer, IRecommendation } from "../../../interfaces/ICalculatorOutput";
import GridRow from "../../GridRow";
import ExtraFertilization from "../../icons/ExtraFertilization";
import InitialFertilization from "../../icons/InitialFertilization";
import { OutputMode } from "./CalculatorOutput";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ExpandModeIcon from '@mui/icons-material/ExpandMore';

enum RowDataVersion {
    Total = 0,
    Manure = 1,
    Fertilizer = 2
}

function Title(props: { text: string }){
    return <Typography fontWeight={"bold"} sx={{ marginTop: 'auto', marginBottom: 'auto'}}>
        {props.text}
    </Typography>
}

function Cell(props: { children: any} & TableCellProps) {
    const {
        children,
        sx,
        ...tableProps
    } = props;
    return (<TableCell sx={{
        padding: '0 10px 0 0',
        maxWidth: '140px', 
        width: '10%',
        minWidth: '30px',
        ...sx
    }} {...tableProps}>
        <Typography>{children}</Typography>
    </TableCell>)
}
function FirstCell(props: { children: any} & TableCellProps) {
    const {
        children,
        sx,
        ...tableProps
    } = props;
    return (<TableCell sx={{
        padding: '10px 0 10px 10px',
        maxWidth: '100%', 
        ...sx
    }} {...tableProps}>
        {children}
    </TableCell>)
}
function LastCell(props: { children: any} & TableCellProps) {
    const {
        children,
        sx,
        ...tableProps
    } = props;
    return (<TableCell sx={{
        padding: '0 10px 0 0',
        maxWidth: '150px', 
        width: '10%',
        minWidth: '40px',
        ...sx
    }} {...tableProps}>
        <Typography>{children}</Typography>
    </TableCell>)
}


interface IProps {
    mode: OutputMode;
    output: ICalculatorOutput;
    distributor: IBatch
}
export default function DistributorOutput(props: IProps){

    const { t } = useTranslation();
    
    const distributor = props.distributor;

    const [grass] = useState(props.mode === OutputMode.Grass);
    const [grain] = useState(props.mode === OutputMode.Grain);

    const [rowDataVersion, setRowDataVersion] = useState<RowDataVersion>(grass 
        ? RowDataVersion.Total
        : RowDataVersion.Fertilizer
    );

    const [output] = useState<ICalculatorOutput>(props.output);

    const RemarksAndRecomms = useCallback(({harvest}: {harvest: IRecommendation}) => {
        const listKey = harvest.listKey;
        const remarks = harvest.remarks;
        const open = remarks.length > 0;
        
        return open ? (<TableRow sx={(t) => ({backgroundColor: t.palette.warning.main})}>
            <TableCell colSpan={5} sx={{padding: '5px'}}>
            <ul>{remarks.map((item,i) => <li key={`${listKey}-${i}`}>{t(item)}</li>)}</ul>
            </TableCell>
        </TableRow>) : <></>;
    }, [t]);

    const RowData = useCallback(({fert}: {fert: IFertilizer}) => {
        switch (rowDataVersion){
            case RowDataVersion.Total:
                return <>
                    <Cell align="right">{fert.quantity}</Cell>
                    <Cell align="right">{decimalFormatUp(fert.totalNutrients.nitrogen)}</Cell>
                    <Cell align="right">{decimalFormat(fert.totalNutrients.phosphorous, 1)}</Cell>
                    <LastCell align="right">{decimalFormat(fert.totalNutrients.potassium)}</LastCell>
                </>
            case RowDataVersion.Manure:
                return <>
                    <Cell align="right"> </Cell>
                    <Cell align="right">{decimalFormatUp(fert.manureNutrients.nitrogen)}</Cell>
                    <Cell align="right">{decimalFormat(fert.manureNutrients.phosphorous, 1)}</Cell>
                    <LastCell align="right">{decimalFormat(fert.manureNutrients.potassium)}</LastCell>
                </>
            case RowDataVersion.Fertilizer:
                return <>
                    <Cell align="right">{fert.fertilizer.code && fert.quantity}</Cell>
                    <Cell align="right">{decimalFormatUp(fert.fertilizerNutrients.nitrogen)}</Cell>
                    <Cell align="right">{decimalFormat(fert.fertilizerNutrients.phosphorous, 1)}</Cell>
                    <LastCell align="right">{decimalFormat(fert.fertilizerNutrients.potassium)}</LastCell>
                </>
        }
    }, [rowDataVersion]);

    const RowDataVersionButton = useCallback(({ title, version}: {title: string, version: RowDataVersion}) => {
        return <Button color={rowDataVersion === version ? 'primary' : 'secondary' }
            onClick={() => setRowDataVersion(version)}
            sx={(t) => ({
                borderRadius: 0
            })}
        >
            {t(`calculator.output.${title}`)}
        </Button>
    }, [t, rowDataVersion]);
    
    return <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandModeIcon/>}
                        sx={(t) => ({
                            backgroundColor: t.palette.Blue.light
                        })}>
            <Title text={t(`retailer.${distributor.name}`)}/>
        </AccordionSummary>
        <AccordionDetails sx={(t) => ({
            padding: 0,
            boxShadow: 'none'
            })}>
            {grass && output.usedInput.batchInputs.find(bi => bi.input > 0) && 
                <GridRow sx={{ display: 'inline-flex', width: '100%' }}>
                    <RowDataVersionButton title="manure" version={RowDataVersion.Manure}/>
                    <RowDataVersionButton title="fertilizer" version={RowDataVersion.Fertilizer}/>
                    <RowDataVersionButton title="total" version={RowDataVersion.Total}/>
                </GridRow>
            }
            {distributor.recommendations.map(harvest => <TableContainer key={`${distributor.name}-${harvest.number}`} sx={t => ({
                    marginBottom: '10px',
                    borderTop: '2px solid',
                    borderTopColor: t.palette.primary.light
                })}>
                <Table key={`${distributor.name}-${harvest.number}`}>
                    <TableHead>
                        <TableRow sx={(t) => ({
                            backgroundColor: t.palette.info.light
                        })}>
                            <FirstCell>
                                <b>{harvest.number}. { grass ? t('calculator.harvest') : t('calculator.solution')}</b>
                            </FirstCell>
                            <Cell align="right"><b>kg/ha</b></Cell>
                            <Cell align="right"><b>N</b></Cell>
                            <Cell align="right"><b>P</b></Cell>
                            <LastCell align="right"><b>K</b></LastCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {grass && <>
                            {rowDataVersion === RowDataVersion.Manure && <TableRow>
                                <FirstCell>
                                    <Typography>{t('calculator.manure')}</Typography>
                                </FirstCell>
                                {<RowData fert={harvest.fertilizers[0]}/>}
                            </TableRow>
                            }
                            {rowDataVersion !== RowDataVersion.Manure && <>
                                {grass && harvest.fertilizers.map((fert, i) => 
                                    <TableRow key={`${harvest.listKey}-${fert.fertilizer.code}`}>
                                        <FirstCell>
                                            <Typography>{fert.fertilizer.name}</Typography>
                                        </FirstCell>
                                        {<RowData fert={fert}/>}
                                    </TableRow>
                                )}
                            </>}
                        </>}
                        {grain && harvest.fertilizers.map((fert, i) => 
                                <TableRow key={`${harvest.listKey}-${fert.fertilizer.code}`} sx={{
                                    position: 'relative'
                                }}>
                                    <FirstCell sx={{
                                        position: 'relative'
                                    }}>
                                        <Box sx={{
                                            float: 'right'
                                        }}>
                                            {fert.type !== 'extra' && <InitialFertilization width={35}/>}
                                            {fert.type === 'extra' && <ExtraFertilization  width={35}/>}
                                        </Box>
                                        <Typography>{fert.fertilizer.name}</Typography>
                                    </FirstCell>
                                    <Cell align="right">{fert.fertilizer.code && fert.quantity}</Cell>
                                    <Cell align="right">{decimalFormatUp(fert.fertilizerNutrients.nitrogen)}</Cell>
                                    <Cell align="right">{decimalFormat(fert.fertilizerNutrients.phosphorous, 1)}</Cell>
                                    <LastCell align="right">{decimalFormat(fert.fertilizerNutrients.potassium)}</LastCell>
                                </TableRow>
                            )}
                        {grain && harvest.summaries.map((sum, i) => 
                            <TableRow key={`${harvest.listKey}-sum-${sum.ordinal}`} sx={{
                                paddingTop: 0, 
                                paddingBottom: 0, 
                                'td': {
                                    borderTop: i === 0 ? '2px lightgray solid' : '',
                                    paddingTop: 0, 
                                    paddingBottom: 0,
                                    '.MuiTypography-root': {
                                        fontWeight: i === 0 ? 'bold' : 'normal'
                                    }
                                }
                            }}>
                                <FirstCell>
                                    <Typography>{t(sum.name)}</Typography>
                                </FirstCell>
                                <Cell align="right"> </Cell>
                                <Cell align="right">{decimalFormat(sum.nitrogen)}</Cell>
                                <Cell align="right">{decimalFormat(sum.phosphorous, 1)}</Cell>
                                <LastCell align="right">{decimalFormat(sum.potassium)}</LastCell>
                            </TableRow>
                        )}
                        {<RemarksAndRecomms harvest={harvest} />}
                    </TableBody>
                </Table>
            </TableContainer>)}
        </AccordionDetails>
    </Accordion>
}