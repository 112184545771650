import { UserManagerSettings } from "oidc-client";

let acr_values: string|undefined = undefined;
let prompt: string|undefined = undefined;

if(window.location.href.indexOf('first-time-login-') >= 0) {
    acr_values = `login_url:${window.location.href}`;
    prompt = 'login';
}
else
    acr_values = `login_url:${process.env.REACT_APP_REDIRECT_URI}`;

export const oidcSettings =  {
    authority: `${process.env.REACT_APP_AUTHORITY}`,
    client_id: 'yara.app.ui',
    client_secret: `${process.env.REACT_APP_SECRET}`,
    response_type : 'code',
    redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
    silent_redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
    post_logout_redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
    loadUserInfo: true,
    scope : `${process.env.REACT_APP_SCOPES}`,
    monitorSession: false,
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 10,
    acr_values: acr_values,
    prompt: prompt
} as UserManagerSettings;