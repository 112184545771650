import { Box, Button } from "@mui/material";
import { IDelivery } from "../../interfaces/IDelivery";
import Delivery from "./Delivery";
import { localStore } from "../../services/LocalStore";
import { useNavigate } from "react-router-dom";
import { disabled } from "../../consts/features";
import { routes } from "../../consts/routes";
import { useTranslation } from "react-i18next";

interface IProps {
    deliveries: IDelivery[];
}
export default function DeliveryList({ deliveries }: IProps){
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (<Box>
        {deliveries.map((delivery, i) => (
            <Delivery id={`delivery-${delivery.id}`} key={`delivery-${delivery.id}`} isLast={i === deliveries.length-1} data={delivery} sync>
                {!delivery.deliveredDate && !disabled('DeliveryTimeRequest') && (
                    <Button onClick={() => {
                        localStore.setTemporary(delivery);
                        navigate(routes.Delivery.TimeRequest);
                    }}>
                        {t('delivery.request-delivery-time')}
                    </Button>
                )}
                {delivery.deliveredDate && (
                    <Button onClick={() => {
                        localStore.setTemporary(delivery);
                        navigate(routes.Delivery.IssueReport);
                    }}>
                        {t('delivery.report-product-issue')}
                    </Button>
                )}
                {delivery.openNpsId && !disabled('Survey') && (
                    <Button onClick={() => {
                        navigate(routes.Survey + `?id=${delivery.openNpsId}&internal`)
                    }}>
                        {t('Vastaa kyselyyn')}
                    </Button>
                )}
            </Delivery>
        ))}
    </Box>);
}