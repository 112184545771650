import { Grid, styled } from "@mui/material";

const GridSection = styled(Grid)(({theme}) => ({
    marginBottom: theme.spacing(2)
}));
GridSection.defaultProps = {
    item: true,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12
}
export default GridSection;