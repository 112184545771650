import { CommonProps } from "@mui/material/OverridableComponent";
import { CSSProperties } from "react";

export interface INavItem {
    key: string;
    hasAccess: boolean;
    icon: React.FC<CommonProps & React.SVGProps<SVGSVGElement>>,
    title: string | JSX.Element;
    action?: () => void;
    href?: string;
    target?: string;
    iconSizeMod?: number;
}

export function IconStyle(x: INavItem): CSSProperties{
    let size = 32;
    if(x.iconSizeMod){
        size = 32 + x.iconSizeMod;
    }
    return {
        width: `${size}px`,
        height: `${size}px`
    };
}