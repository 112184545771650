import { SvgIcon, SxProps, Theme } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";

/**
 * SVG content converted from YARA_rgb.png in styles.
 */
interface IProps {
    sx?: SxProps<Theme>;
    fillColor?: string
}
export default function YaraIcon(props: IProps & CommonProps){
    return (<SvgIcon
        {...props}
        version="1.0" 
        xmlns="http://www.w3.org/2000/svg"
        width="1239.000000pt" 
        height="1239.000000pt" 
        viewBox="0 0 1239.000000 1239.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g transform="translate(0.000000,1239.000000) scale(0.100000,-0.100000)"
        fill="#003e7e" stroke="none">
        <path d="M723 12085 c-200 -43 -376 -221 -418 -422 -22 -102 -22 -10834 0
        -10936 43 -203 219 -379 422 -422 102 -22 10834 -22 10936 0 203 43 379 219
        422 422 22 102 22 10834 0 10936 -43 203 -219 379 -422 422 -99 21 -10844 21
        -10940 0z m4307 -942 c-285 -308 -525 -896 -626 -1533 -25 -162 -30 -512 -9
        -683 48 -401 176 -840 342 -1171 104 -208 189 -336 310 -466 l55 -60 -506 0
        -505 0 -36 32 c-20 17 -75 79 -121 137 -576 717 -791 1616 -591 2461 113 472
        369 960 681 1298 l58 62 510 0 510 0 -72 -77z m1926 -4 c-313 -333 -580 -1035
        -646 -1698 -69 -697 250 -1725 668 -2153 l56 -58 -509 0 -509 0 -61 63 c-75
        77 -222 271 -300 397 -224 362 -370 758 -432 1173 -22 143 -24 556 -5 697 48
        339 150 661 306 966 116 227 276 464 417 617 l71 77 510 0 510 0 -76 -81z
        m1929 -1 c-373 -397 -674 -1311 -652 -1983 15 -450 151 -978 358 -1394 94
        -191 192 -339 294 -448 l77 -83 -512 0 -512 0 -55 60 c-296 319 -556 805 -668
        1245 -63 247 -85 424 -85 690 0 290 36 536 116 805 125 412 373 852 646 1143
        l44 47 513 0 513 0 -77 -82z m-6211 -3638 c93 -32 154 -70 216 -137 32 -34 72
        -90 88 -125 55 -115 61 -174 72 -643 7 -289 15 -455 24 -508 19 -101 53 -159
        108 -185 60 -29 112 -37 124 -20 6 7 16 63 22 124 23 226 101 395 256 550 148
        148 315 229 525 254 234 29 502 -59 674 -220 36 -33 72 -60 82 -60 9 0 46 26
        81 58 164 148 364 224 589 225 223 0 410 -73 592 -230 33 -29 66 -53 73 -53 7
        0 40 24 73 53 182 157 369 230 592 230 225 -1 425 -77 589 -225 35 -32 72 -58
        81 -58 10 0 46 27 82 60 172 161 440 249 674 220 210 -25 377 -106 525 -254
        155 -155 234 -324 256 -550 6 -61 16 -117 23 -124 10 -11 21 -11 56 -2 65 18
        107 47 130 93 39 75 49 173 59 592 11 471 13 503 44 601 52 164 165 277 335
        335 49 17 88 19 397 19 298 0 345 -2 358 -16 26 -26 22 -236 -5 -310 -41 -110
        -135 -181 -270 -204 -97 -17 -177 -64 -216 -129 -89 -148 -52 -270 302 -996
        199 -408 239 -538 238 -770 -2 -256 -104 -491 -302 -698 -130 -135 -278 -209
        -511 -254 -91 -17 -247 -18 -3435 -21 -2368 -2 -3375 0 -3462 8 -170 15 -307
        51 -432 111 -89 43 -117 63 -191 137 -56 56 -113 126 -159 197 -118 181 -163
        330 -163 535 0 193 47 363 163 600 347 707 413 867 413 1005 1 70 -3 87 -29
        133 -17 32 -46 66 -70 82 -41 28 -122 60 -153 60 -10 0 -47 10 -82 22 -85 28
        -158 98 -188 181 -29 76 -32 279 -5 309 15 17 39 18 358 18 314 0 347 -2 399
        -20z m-533 -4046 c83 -24 162 -71 221 -129 41 -42 95 -127 248 -393 107 -187
        197 -339 201 -337 3 2 80 200 170 439 l164 436 277 0 277 0 -13 -37 c-129
        -355 -684 -1798 -704 -1826 -38 -56 -98 -105 -172 -141 -64 -31 -67 -31 -247
        -34 -113 -2 -183 0 -183 6 0 5 47 134 105 286 100 262 105 278 91 304 -8 15
        -195 344 -415 731 -221 388 -401 706 -401 708 0 2 73 3 163 3 120 0 178 -4
        218 -16z m2935 -1 c104 -35 207 -122 244 -207 10 -22 167 -432 348 -911 182
        -478 333 -878 337 -888 7 -16 -8 -17 -255 -15 l-263 3 -101 267 -101 268 -477
        0 -476 0 -28 -72 c-101 -269 -105 -277 -156 -333 -35 -38 -73 -66 -126 -92
        l-76 -38 -168 -3 c-92 -2 -168 -1 -168 3 0 8 759 2015 767 2028 2 4 149 7 327
        7 274 0 330 -3 372 -17z m2405 1 c215 -40 382 -153 469 -315 95 -179 95 -470
        -1 -648 -53 -100 -189 -219 -285 -251 -19 -6 -34 -16 -34 -22 0 -5 88 -182
        195 -393 107 -210 195 -385 195 -389 0 -3 -123 -6 -273 -6 l-273 0 -185 363
        -184 362 -173 3 -172 2 0 -365 0 -365 -245 0 -245 0 0 1020 0 1020 564 0 c471
        0 577 -3 647 -16z m2715 -999 c211 -555 384 -1013 384 -1017 0 -16 -294 -9
        -351 8 -71 21 -139 65 -193 126 -44 48 -52 66 -150 326 l-28 72 -476 0 -477 0
        -101 -268 -101 -267 -263 -3 c-247 -2 -262 -1 -255 15 4 10 155 410 337 888
        181 479 338 889 348 911 36 83 134 169 233 204 47 17 83 19 381 17 l329 -2
        383 -1010z"/>
        <path d="M4073 6446 c-90 -22 -164 -65 -239 -140 -106 -106 -154 -220 -154
        -366 0 -148 56 -276 165 -379 107 -99 213 -141 360 -141 232 0 432 152 499
        379 33 113 15 278 -42 385 -38 72 -155 187 -223 220 -114 55 -248 70 -366 42z"/>
        <path d="M5403 6445 c-274 -74 -442 -351 -378 -623 44 -188 203 -349 385 -391
        64 -15 196 -13 256 3 121 32 235 115 304 219 58 89 82 171 82 287 1 116 -23
        194 -89 293 -119 179 -353 267 -560 212z"/>
        <path d="M6733 6445 c-168 -46 -306 -176 -363 -345 -30 -88 -31 -228 -1 -320
        41 -128 131 -235 252 -300 82 -44 148 -60 244 -60 153 1 262 45 368 151 331
        330 96 892 -373 888 -41 0 -99 -7 -127 -14z"/>
        <path d="M8067 6445 c-91 -25 -160 -66 -232 -139 -112 -113 -155 -216 -155
        -371 0 -232 152 -432 379 -499 113 -33 279 -15 385 42 69 37 164 128 205 196
        66 110 89 272 56 395 -49 187 -210 342 -393 380 -69 14 -185 13 -245 -4z"/>
        <path d="M4777 3123 c-12 -15 -287 -722 -305 -785 -4 -17 17 -18 338 -18 322
        0 342 1 335 18 -3 9 -69 184 -146 387 -77 204 -147 380 -155 393 -17 26 -45
        29 -67 5z"/>
        <path d="M6760 2811 l0 -301 248 0 c349 1 415 19 478 133 27 49 29 58 29 172
        0 134 -14 177 -76 229 -69 58 -80 60 -391 64 l-288 3 0 -300z"/>
        <path d="M9346 3118 c-12 -19 -94 -232 -303 -785 -4 -10 67 -13 337 -13 321 0
        342 1 337 18 -17 64 -292 770 -305 785 -21 24 -49 21 -66 -5z"/>
        </g>
    </SvgIcon>);
}