
import { renderToString } from 'react-dom/server';
import { content } from './logoBase64';

export function HeaderHtml(){
    return `
    ${renderToString(<CalculatorPdfHeader/>)}
    `;
}

export default function CalculatorPdfHeader(){

    return (<div style={{
        width: '991px',
        marginLeft: '-8px',
        height: '255px',
        fontSize: '24px',
        backgroundColor: '#57b5e8',
        padding: '60px',
        fontFamily: 'Noto Sans'
    }}>
    <table width="100%">
        <thead>
            <tr>
                <th style={{width: '50%'}}></th>
                <th style={{width: '50%'}}></th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>
                    <div style={{
                        width: '100%',
                        marginTop: '20px',
                    }}>
                        <img src={content} alt='logo' style={{
                            maxWidth: '100%',
                            margin: '5px',
                            marginTop: '55px',
                            maxHeight: '130px',
                        }}/>
                    </div>
                </td>
                <td></td>
            </tr>
            <tr>
                <td style={{
                    verticalAlign: 'top',
                    paddingTop: '10px',
                    color: '#ffffff',
                    fontSize: '32px',
                    fontWeight: 'bold',
                }}>
                    Knowledge grows
                </td>
                <td>
                </td>
            </tr>
        </tbody>
    </table>
</div>)
}