import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from "./components/Loading";
import { routes } from './consts/routes';
import Survey from './pages/Survey';

const basePath = process.env.REACT_APP_LOCATION;
  
interface IProps {
    reload: () => void;
}
export default function AppAnonymous({ reload }: IProps) {

    const RouteToAuthorized = () => {
        useEffect(() => {
            reload();
        }, []);
        return (<Loading show={true} />);
    };

    return (
        <BrowserRouter basename={basePath} >
            <Routes>
                <Route path="*" element={<RouteToAuthorized />}/>
                <Route path={routes.Survey} element={<Survey />}/>
            </Routes>
        </BrowserRouter>
    );
}