import { Grid, SxProps, Theme } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";

interface IProps {
    variant?: 'top' | 'bottom';
    children?: React.ReactNode;
}
export default function CardListItem(props: IProps & CommonProps){
    const {
        children,
        ...commonProps
    } = props;

    const borderXs: SxProps<Theme> = props.variant === 'top'
        ? (theme) => ({
            borderTopColor: theme.palette.Gray.light,
            borderTopStyle: 'solid',
            borderTopWidth: '1px'
        })
        : (theme) => ({
            borderBottomColor: theme.palette.Gray.light,
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px'
        });

    return (<Grid container {...commonProps} sx={(theme) => ({
        ...{
            backgroundColor: '#ffffff',
            marginBottom: '3px',
            marginTop: 0,
            padding: '15px',
            width: '100%'
        },
        ...borderXs(theme)
    })}>
        {children}
    </Grid>);
}