import { Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CardListItem from "../../components/CardListItem";
import Loading from "../../components/Loading";
import { IUserInfo } from "../../interfaces/IUserInfo";
import GridRow from "../GridRow";
import GridSection from "../GridSection";
import LanguageSelect from "../LanguageSelect";

interface IProps {
    user: IUserInfo
}
export default function LanguageSelectCard(props: IProps){
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);

    return (<CardListItem>
        <Loading show={loading}/>
        <GridSection>
            <Typography variant="h6" fontWeight="bold">{t('settings.culture')}</Typography> 
        </GridSection>
        <GridRow sx={{display: 'inline-flex'}}>
            <LanguageSelect user={props.user} setLoading={setLoading}/>
        </GridRow>
    </CardListItem>);
}