import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContainer from "../../components/appContainers/AppContainer";
import HeaderCloseButton from "../../components/buttons/HeaderCloseButton";
import Delivery from "../../components/delivery/Delivery";
import DeliveryIssueReportComponent from "../../components/delivery/DeliveryIssueReport";
import DeliverySelectList from "../../components/delivery/DeliverySelectList";
import { IDelivery } from "../../interfaces/IDelivery";
import { deliveryStore, filters } from "../../services/DeliveryStore";
import { localStore } from "../../services/LocalStore";

export default function DeliveryIssueReport(){
    const theme = useTheme();
    const navigate = useNavigate();

    const [showDeliverySelect, setShowDeliverySelect] = useState<boolean>(false);
    const [selectedDelivery, setSelectedDelivery] = useState<IDelivery|null>(
        localStore.getTemporary<IDelivery>() || deliveryStore.getLatest(filters.delivered)
    );

    useEffect(() => {
        return function cleanup() {
            localStore.clearTemporary();
        };
    }, [])

    useEffect(() => {
        if(!selectedDelivery) 
            setShowDeliverySelect(true);
    }, [selectedDelivery, navigate])

    const openDeliverySelect = () => {
        setShowDeliverySelect(true);
    }

    const closeDeliverySelect = () => {
        setShowDeliverySelect(false);
        if (!selectedDelivery)
            navigate(-1);
    }

    const selectDelivery = (o: IDelivery) => {
        setSelectedDelivery(o);
        setShowDeliverySelect(false);
    }

    return (<>
        {selectedDelivery && !showDeliverySelect && (
            <AppContainer title='delivery.report-product-issue' 
                actionButton={HeaderCloseButton}
                style={{
                    backgroundColor: theme.palette.Gray.light,
                    paddingTop: 0
                }}
            >
                <Delivery data={selectedDelivery} onDeliverySelect={openDeliverySelect} noContent>
                    <DeliveryIssueReportComponent data={selectedDelivery} afterSave={() => navigate(-1)} style={{paddingTop: '20px'}} />
                </Delivery>
            </AppContainer>
        )}
        {showDeliverySelect && (
            <AppContainer title='delivery.select-delivery' 
                actionButton={() => (<HeaderCloseButton onClick={closeDeliverySelect}/>)}
            >
                <DeliverySelectList filter={filters.delivered} onClick={(x) => selectDelivery(x)}/>
            </AppContainer>
        )}
    </>);
}