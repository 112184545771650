import { Grid, IconButton, MenuItem, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOrderItem } from "../../../interfaces/IDelivery";
import { IProduct } from "../../../interfaces/IProduct";
import { paramService } from "../../../services/ParamService";
import GridSection from "../../GridSection";
import Subtitle2 from "../../labels/Subtitle2";
import RegexField from "../../inputs/RegexField";
import VSelect from "../../validatable/VSelect";
import { IIssueReport } from "../DeliveryIssueReport";
import ProductBatchHelp from "./ProductBatchHelp";
import { Close } from "@mui/icons-material";
import VTextField from "../../validatable/VTextField";

const batchNrRegex = /^[a-zA-Z0-9-. ]*$/;

interface IBatchItem {
    id?: string;
    name?: string;
    batchNr: string;
}

interface IProps {
    report: IIssueReport;
    items: IOrderItem[];
    required?: boolean;
    onChange: () => void;
}
export default function ProductBatchSelect({ report, items, required, onChange }: IProps){
    const { t } = useTranslation();

    const [selectOpen, setSelectOpen] = useState<boolean>(false);
    const [products, setProducts] = useState<IProduct[]>([]);

    const [customIdCounter, setCustomIdCounter] = useState<number>(0);
    const [batches, setBatches] = useState<IBatchItem[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    
    const selectProducts = (selected: string[]) => {
        const productsIds = selected.filter(x => x !== 'other');
        
        setSelectedProducts(productsIds);
        setBatches(prev => {
            return productsIds
                .map(id => prev.find(b => b.id === id) || { id: id, batchNr: '' })
                .concat(prev.filter(x => x.name !== undefined));
        });

        let customId = customIdCounter;
        
        // eslint-disable-next-line
        for (let custom of selected.filter(x => x === 'other')){
            setBatches(prev => [...prev, { 
                id: `other-${customId+1}`, 
                name: '', 
                batchNr: ''
            }]);
            setCustomIdCounter(customId+1);
        }
        setSelectOpen(false);
    }

    useEffect(() => {
        setProducts(
            paramService.products.filter(p => items.find(i => i.product.id === p.id))
        );
    }, [items]);

    useEffect(() => {
        report.products = batches.map(b => b.name || b.id || '');
        report.batchNrs = required ? batches.map(b => b.batchNr) : [];
        onChange();
    }, [
        batches, 
        required, 
        report, 
        onChange
    ]);

    const editableBatches = useCallback(() => {
        return batches
            .filter(b => required || b.name !== undefined)
            .map(b => ({b, gridSize: required ? 4 : 11}));
    }, [required, batches]);

    return (<>
        <GridSection>
            <Subtitle2>
                {t('common.product')}
            </Subtitle2>
            <VSelect
                id="product"
                value={selectedProducts.length > 0 ? selectedProducts : ['none']}
                multiple
                open={selectOpen}
                onOpen={() => setSelectOpen(true)}
                onClose={() => setSelectOpen(false)}
                required={required}
                onChange={(e) => selectProducts((e.target.value as string[]).filter(x => x !== 'none'))}
                vState={report.state}
                vRules={[
                    (x) => {
                        return !required 
                        || x.filter(p => p !== 'none').length > 0 
                        || batches.filter(x => x.name !== undefined).length > 0;
                    }
                ]}
            >
                <MenuItem value={'none'} disabled>{t('common.select')}</MenuItem>
                {products.map(product => (<MenuItem key={`product-${product.id}`} value={product.id}>{product.name}</MenuItem>))}
                <MenuItem key={'product-other'} value={'other'}>
                    <Typography color={'primary'}>
                    + {t('products.other')}
                    </Typography>
                </MenuItem>
            </VSelect>
        </GridSection>
        {batches.length > 0 && (
            <GridSection>
                {required && (<>
                    <Subtitle2>
                        {t('delivery.product-or-batch-nr')}
                    </Subtitle2>
                    <ProductBatchHelp />
                </>)}
                {editableBatches().map(({b, gridSize}, i) => (
                    <Grid key={`product-batch-${b.id}`} container sx={t => ({
                        marginTop: t.spacing(1),
                        marginBottom: t.spacing(0.5)
                    })}>
                        <Grid item xs={gridSize} sm={gridSize} md={gridSize} lg={gridSize} sx={{ display: 'flex' }}>
                            {b.name === undefined && (
                                <Typography variant="body1" sx={{ marginTop: 'auto', marginBottom: 'auto' }}>
                                    {products.find(p => p.id === b.id)?.name}
                                </Typography>
                            )}
                            {b.name !== undefined && (
                                <VTextField 
                                    id={b.id}
                                    value={b.name} 
                                    required={true}
                                    onChange={e => {
                                        b.name = e.target.value;
                                        setBatches([...batches]);
                                    }}
                                    placeholder={t('products.other')} 
                                    sx={{ 
                                        marginTop: 'auto', 
                                        marginBottom: 'auto',
                                        marginRight: '2px'
                                    }} 
                                    vState={report.state}
                                    vRules={[
                                        (x) => {
                                            return !required || x.length >= 1;
                                        }
                                    ]}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12-gridSize} sm={12-gridSize} md={12-gridSize} lg={12-gridSize} sx={{ display: 'inline-flex' }}>   
                            {required && <RegexField
                                regex={batchNrRegex}
                                max={50}
                                value={b.batchNr}
                                autoComplete="off"
                                placeholder={t('products.batch-nr')}
                                onChange={(e) => {
                                    b.batchNr = e.target.value;
                                    setBatches([...batches]);
                                }}
                            />}
                            {b.name !== undefined && (
                                <IconButton onClick={() => {
                                    setBatches(prev => prev.filter(x => x.id !== b.id));
                                }}>
                                    <Close />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>
                ))}
            </GridSection>
        )}
    </>);
}