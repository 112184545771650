import { InputAdornment, TextField, TextFieldProps, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";

interface IProps {
    regex: RegExp;
    cornerInfo?: string;
    max?: number;
}
export type RegexFieldProps = IProps & TextFieldProps;
export default function RegexField({ regex, max, cornerInfo, ...props}: RegexFieldProps){
    

    const [lastValue, setLastValue] = useState<string>('');

    const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setLastValue(e.target.value);
        if(props.onChange) props.onChange(e);
    }

    const onInput = (e: any) => {
        if(e.target.value === ''){
            if(props.onInput) props.onInput(e);
            return;
        }
        const valid = regex.test(e.target.value) &&
                    (!max || e.target.value.length <= max);
                   
        if(!valid)
            e.target.value = lastValue;
        else if(props.onInput) 
            props.onInput(e);
    }

    const fieldProps = {
        ...props,
        onInput: onInput,
        onChange: onChange
    }

    if (cornerInfo)
        fieldProps.InputProps = {
            ...fieldProps.InputProps,
            endAdornment: (
                <InputAdornment position="end">
                    <Typography variant="caption" color={"gray"}>{cornerInfo}</Typography>
                </InputAdornment>
            )
        }
        
    if (fieldProps["aria-readonly"]){
        fieldProps.className = `${props.className ? props.className : ''} readonly`;
    }

    return (<TextField 
            type='text'
            autoComplete="off"
            {...fieldProps}
        />)
}