import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { routes } from "../consts/routes";
import { yaraApi } from "../services/YaraApi";
import { LoginValidationResult } from "../enums/LoginValidationResult";
import AppContainer from "../components/appContainers/AppContainer";
import { Button, Grid, Typography, styled } from "@mui/material";
import GridRow from "../components/GridRow";
import { useTranslation } from "react-i18next";
import Pin from "../components/inputs/Pin";
import { useMessager } from "../providers/MessagerProvider";
import HeaderCloseButton from "../components/buttons/HeaderCloseButton";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const HeaderLabel = styled(Typography)(t => ({
    marginTop: 'auto', 
    width: '100%'
}));
HeaderLabel.defaultProps = {
    variant: 'h6',
    color: 'primary'
};

const HeaderRow = styled(GridRow)(t => ({
    textAlign: 'center', 
    height: '33%', 
    display: 'flex'
}));
const ContentRow = styled(GridRow)(t => ({
    textAlign: 'center', 
    height: '67%'
}));

export default function FirstTimeLogin(){
    const { t } = useTranslation();
    const navigate = useNavigate();
    const messager = useMessager();
    const { id } = useParams<{ id: string | undefined }>();

    const [cancelling, setCancelling] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [attemptsLeft, setAttempts] = useState<number|null>(null);
    const [validationResult, setValidationResult] = useState<LoginValidationResult>();

    const continueToApp = useCallback(() => {
        navigate(routes.Home);
    }, [navigate]);

    const complete = useCallback(() => {
        setValidationResult(undefined);
        messager.success('login.complete', 2500);
        continueToApp();
    }, [continueToApp, messager]);

    const onPinInput = (pin: number[]) => {
        if (!id) return;

        setLoading(true);
        yaraApi.postFirstTimeLogin(id, pin).then(result => {
            if (result.attemptsLeft !== null) setAttempts(result.attemptsLeft);
            if (result.result === LoginValidationResult.Success) complete();
            else if (result.result === LoginValidationResult.TooManyAttempts) {
                messager.error('login.too-many-attempts', 2500);
                setValidationResult(result.result);
            }
            else { 
                messager.error('login.invalid-code', 2500);
            };
        }).finally(() => setLoading(false));
    }

    const reSendPin = useCallback(() => {
        if (!id) return;

        setLoading(true);
        yaraApi.reSendFirstTimeLoginPin(id)
        .then(ok => {
            if (ok)
                messager.success('login.pin-sent');
            else
                messager.error('login.pin-send-failed', 5000);
        })
        .finally(() => setLoading(false));
    }, [id, messager]);

    const onClose = useCallback(() => {
        if (validationResult === LoginValidationResult.NotFound) {
            navigate(-1);
            return;
        }
        setCancelling(prev => !prev);
    }, [navigate, validationResult]);

    useEffect(() => {
        if(id) {
            yaraApi.getFirstTimeLogin(id).then(result => {
                if (result.result === LoginValidationResult.Consumed) navigate(routes.Home);
                if (result.result === LoginValidationResult.Completed) return complete();
                if (result.attemptsLeft !== null) setAttempts(result.attemptsLeft);
                setValidationResult(result.result);
            }).finally(() => setLoading(false));
        }
        else {
            navigate(routes.Home);
        }
    }, [id, navigate, complete]);

    return (
            <AppContainer
                title='pages.first-time-login'
                actionButton={() => (<HeaderCloseButton onClick={onClose}/>)}
            >
                <Loading show={loading} />
                <Grid container sx={t => ({
                    maxWidth: '400px',
                    height: '100%',
                    padding: t.spacing(2),
                    margin: 'auto'
                })}>
                {!loading && cancelling && (<>
                    <HeaderRow>
                        <HeaderLabel>
                            {t('login.fail-state-continue')}
                        </HeaderLabel>
                    </HeaderRow>
                    <ContentRow>
                        <Button 
                            color="primary"
                            onClick={onClose}
                            sx={(t) => ({ marginTop: t.spacing(4)})}
                        >
                            <KeyboardBackspaceIcon />
                        </Button>
                        <Button 
                            color="secondary"
                            onClick={continueToApp}
                            sx={(t) => ({ marginTop: t.spacing(2)})}
                        >
                            {t('survey.continue-to-app')}
                        </Button>
                    </ContentRow>
                </>)}
                {!loading && !cancelling && (<>
                    {validationResult === LoginValidationResult.Success && (<>
                        <HeaderRow>
                            <HeaderLabel>
                                {t('login.insert-pin')}
                                {attemptsLeft && (
                                    <Typography fontWeight={'bold'} color={'error'} sx={t => ({margin: t.spacing(1)})}>
                                        {attemptsLeft} {t('login.attempts-left')}
                                    </Typography>
                                )}
                            </HeaderLabel>
                        </HeaderRow>
                        <ContentRow>
                            <Pin onChange={onPinInput}/>
                            <Button
                                onClick={reSendPin}
                                sx={(t) => ({ marginTop: t.spacing(4) })}
                            >
                                {t('login.re-send-pin')}
                            </Button>
                        </ContentRow>
                    </>)}
                    {validationResult === LoginValidationResult.NotFound && (<>
                        <HeaderRow>
                            <HeaderLabel>
                                {t('login.not-found')}
                            </HeaderLabel>
                        </HeaderRow>
                        <ContentRow>
                            <Typography>
                                {t('login.contact-support')}
                            </Typography>
                        </ContentRow>
                    </>)}
                    {validationResult === LoginValidationResult.Failed && (<>
                        <HeaderRow>
                            <HeaderLabel>
                                {t('login.failed')}
                            </HeaderLabel>
                        </HeaderRow>
                    </>)}
                    {validationResult === LoginValidationResult.TooManyAttempts && (<>
                        <HeaderRow>
                            <HeaderLabel>
                                {t('login.too-many-attempts')}
                                <p/>
                                {t('login.failed')}
                            </HeaderLabel>
                        </HeaderRow>
                    </>)}
                    {(validationResult === LoginValidationResult.Failed || 
                    validationResult === LoginValidationResult.TooManyAttempts) && (<>
                        <ContentRow>
                            <p/>
                            <Typography>
                                {t('login.contact-support')}
                            </Typography>
                            <Button 
                                onClick={continueToApp}
                                sx={(t) => ({ marginTop: t.spacing(4)})}
                            >
                            {t('login.fail-state-continue')}
                            </Button>
                        </ContentRow>
                    </>)}
                </>)}
                </Grid>
            </AppContainer>
        );
}