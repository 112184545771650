import { Box, Grid, IconButton, SxProps, Theme, Typography } from "@mui/material";
import { SystemStyleObject } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { INotification } from "../../interfaces/INotification";
import { NotificationType } from "../../enums/NotificationType";

import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';

const iconSx: SxProps<Theme> = (theme: Theme): SystemStyleObject<Theme> => ({
    width: 30,
    height: 30,
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: 2
});

const headerSx: SxProps<Theme> = (theme: Theme): SystemStyleObject<Theme> => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.Gray.light,
    width: '100%',
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    border: 'none',
    cursor: 'pointer',
    marginBottom: 0,
    marginTop: '2px',
    borderRadius: 0,

    '&:hover': {
        backgroundColor: theme.palette.background.default,
    },

    '&.MuiButtonBase-root': {
        marginLeft: 0,
        marginRight: 0
    }
});

interface IProps {
    notification: INotification;
    onClick: () => void;
    onRemove?: () => void;
    readonly?: true;
}
export default function NotificationHeader(props: IProps) {
    const { t } = useTranslation();
    
    const [description, setDescription] = useState<string>('');
    const [icon, setIcon] = useState<React.ReactNode>(<></>);

    useEffect(() => {
        switch(props.notification.notificationType){

            case NotificationType.InDelivery:
                setDescription('notification.in-delivery');
                setIcon(<NotificationsIcon color={props.readonly ? 'disabled' : 'primary'} sx={iconSx} />);
                break;

            case NotificationType.Modified:
                setDescription('notification.delivery-changed');
                setIcon(<EditNotificationsIcon color={props.readonly ? 'disabled' : 'primary'} sx={iconSx} />);
                break;
        
            case NotificationType.OrderRejected:
                setDescription('common.cancelled');
                setIcon(<NotificationsIcon color={props.readonly ? 'disabled' : 'error'} sx={iconSx} />);
                break;

            case NotificationType.DeliveryTimeConfirmation:
                setDescription('notification.delivery-time-confirmation');
                setIcon(<NotificationsIcon color={props.readonly ? 'disabled' : 'warning'} sx={iconSx} />);
                break;
                
        }
    }, [props.notification, props.readonly]);

    return (
        <Box sx={headerSx}>
            <Grid container direction='column'>
                <Grid sx={{
                    display: 'flex', 
                    height: '30px'
                }}
                >
                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{display: 'flex'}} onClick={props.onClick}>
                        {icon}
                        <Typography variant="button" sx={{
                            marginTop: 'auto',
                            marginBottom: 'auto'
                        }}>
                            {t(description)}
                        </Typography>
                        {!props.readonly && props.onRemove && (
                            <IconButton 
                                onClick={props.onRemove}
                                sx={t => ({
                                    marginLeft: 'auto',
                                    marginRight: t.spacing(2)
                                })}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}