import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContainer from "../components/appContainers/AppContainer";
import CardListItem from "../components/CardListItem";
import Loading from "../components/Loading";
import { UIDateFormat } from "../consts/uiDateFormat";
import { yaraApi } from "../services/YaraApi";
import { wip } from "../consts/wip";
import { IStorage } from "../interfaces/IStorage";
import { IUserInfo } from "../interfaces/IUserInfo";
import GridRow from "../components/GridRow";
import { Link } from "react-router-dom";
import { routes } from "../consts/routes";

interface IProps {
    user: IUserInfo;
}
export default wip("FarmYaraProducts") || function Storage({ user }: IProps){
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);
    const [storage, setStorage] = useState<IStorage|null>();

    useEffect(() => {
        if (!user.sbkAccess) return;
        setLoading(true);
        yaraApi.getStorage().then(res => {
            setStorage(res);
        })
        .finally(() => setLoading(false));
    }, [user.sbkAccess, user.settings.autoInventory]);

    const PlaceholderText = useCallback(({text, children}: {text?: string, children?: any}) => (
        <GridRow sx={{marginTop: '40%'}}>
            <Typography sx={(t) => ({ textAlign: 'center', padding: t.spacing(2)})}>
                {text ? t(text) : ''} {children}
            </Typography>
        </GridRow>
    ), [t]);

    return user.sbkAccess ? (<AppContainer
        title='pages.storage'
        style={{
            paddingTop: 0
        }}
    >
        <Loading show={loading}/>
        {!loading && !storage && !user.settings.autoInventory && (
            <PlaceholderText text="storage.not-initialized">
                <Link to={routes.Settings}>{t('storage.not-initialized-link')}</Link>
            </PlaceholderText>
        )}
        {!loading && !storage && user.settings.autoInventory && (
            <PlaceholderText text="storage.init-in-progress"/>
        )}
        {storage && storage.items.length <= 0 && (<>
            <PlaceholderText text="storage.no-items"/>
        </>)}
        {storage && !user.settings.autoInventory && (<>
            <Typography color={'error'} sx={{ textAlign: 'center' }}>
                {t('storage.sync-disabled')}
            </Typography>
        </>)}
        {storage && storage.items && storage.items.map((item, i) => (
            <CardListItem 
                key={`user-product-${i}`}
            >
                <Grid item xs={7} sm={7} md={7} lg={7}>
                    <Typography variant="subtitle1">
                        {item.name}
                    </Typography>
                    <Typography variant="subtitle2">
                        {t('storage.updated')} {moment(item.updated).format(UIDateFormat)}
                    </Typography>
                </Grid>
                <Grid item xs={5} sm={5} md={5} lg={5} sx={{display: 'flex'}}>
                    <Typography variant="h6" sx={{
                        fontWeight: 'bold',
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        marginLeft: 'auto'
                    }}>
                        {item.quantity} {item.unit}
                    </Typography>
                </Grid>
            </CardListItem>
        ))}
    </AppContainer>) : (<></>);
}