import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function InfoBlock(props: any){

    return <Typography sx={(t) => ({
        margin: t.spacing(1)
    })}>{props.children}</Typography>
}

export default function CalculatorFooter()  {
    const { t } = useTranslation();

    return (<Box sx={(t) => ({
        marginTop: t.spacing(2)
    })}>
        <InfoBlock>{t('calculator.info.footer.1')}</InfoBlock>
        <InfoBlock>{t('calculator.info.footer.2')}</InfoBlock>
        <InfoBlock>{t('calculator.info.footer.3')}</InfoBlock>
        <InfoBlock>{t('calculator.info.footer.4')}</InfoBlock>
    </Box>);
}