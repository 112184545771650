import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GridSection from "../../GridSection";
import Subtitle2 from "../../labels/Subtitle2";
import { IIssueReport } from "../DeliveryIssueReport";

interface IProps {
    report: IIssueReport
}
export default function AddInfo({ report }: IProps){
    const { t } = useTranslation();

    const [addInfo, setAddInfo] = useState<string>('');

    useEffect(() => {
        report.addInfo = addInfo;
    }, [addInfo, report]);

    return (
        <GridSection>
            <Subtitle2>
                {t('delivery.add-info-for-report')}
            </Subtitle2>
            <TextField
                multiline
                rows={5}
                value={addInfo}
                onChange={(e) => setAddInfo(e.target.value)}
            />
        </GridSection>
    );
}