import { TextField, TextFieldProps } from "@mui/material";
import { useEffect, useState } from "react";
import { IVStateProps } from "../../classes/ValidationState";

export default function VTextField(props: TextFieldProps & IVStateProps<string>){
    const {
        vState,
        vRules,
        onValidate,
        ...componentProps
    } = props;

    const [error, setError] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [validatedValue, setValidatedValue] = useState<"" | string | undefined | unknown>();

    componentProps.onChange = (e) => {
        if(vState)
            vState.autoInnerValidate(
                componentProps,
                vRules,
                e.target.value as string,
                setError
            );
        if(props.onChange) props.onChange(e);
    }

    useEffect(() => {
        return () => {
            if(vState)
                vState.dismount(componentProps);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!initialized && vState){
            vState.autoInit(
                componentProps,
                vRules,
                setError
            );
            setInitialized(true);
        }
    }, [
        componentProps,
        initialized,
        vState,
        vRules
    ]);

    useEffect(() => {
        if (componentProps.value !== validatedValue && vState){
            vState.autoOuterValidate(
                componentProps,
                vRules,
                onValidate,
                setError
            );
            setValidatedValue(componentProps.value);
        }
    }, [
        componentProps,
        componentProps.value,
        validatedValue,
        vState,
        vRules,
        onValidate
    ])

    if(componentProps.error === undefined)
        componentProps.error = error;

    return (<TextField {...componentProps}/>);
}