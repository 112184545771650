import { Grid, IconButton, styled } from "@mui/material";
import { createRef, useCallback, useEffect, useRef, useState } from "react";
import RegexField from "./RegexField";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useMessager } from "../../providers/MessagerProvider";
import Numpad from "./Numpad";

const newPin = (length?: number) => Array.from({ length: length || 4}, () => null);

const numberOnly = /^[0-9]*$/;

const PinContainer = styled('div')(t => ({
    width: '100%',
    display: 'inline-flex'
}));

const PinInput = styled(RegexField)(t => ({
    '.MuiInputBase-root': {
        borderRadius: 0,
    },
    'input': {
        borderRadius: 0,
        textAlign: 'center',
        fontWeight: 'bold'
    }
}));
PinInput.defaultProps = {
    type: 'text',
    max: 1,
    inputProps: {
        maxLength: 1
    }
};

interface IProps {
    length?: number;
    onChange?: (pin: number[]) => void;
}
export default function Pin(props: IProps){

    const { t } = useTranslation();
    const messager = useMessager();
    const refs = useRef<any>([]);
    const [pin, setPin] = useState<(number|null)[]>(newPin(props.length));
    const [pinRefs] = useState(() =>
        Array.from({ length: pin.length }, () => createRef<any>())
    );

    const isComplete = pin.filter(x => x !== null).length === pin.length;

    useEffect(() => {
        refs.current = refs.current.slice(0, pin.length);
    }, [pin]);

    const onComplete = useCallback(() => {
        if(pin.find(x => x === null)) return;
        if(props.onChange)
            props.onChange(pin.map(x => x || 0));
    }, [pin, props]);

    const onInput = (i: number, e: any) => {
        const value = parseInt(e.target.value);
        if (isNaN(value)) return;
        setPin(prev => {
            prev[i] = value;
            return [...prev];
        });
        if (i < pin.length-1)
            pinRefs[i+1].current.focus();
    }

    const onPaste = async (e?: any) => {
        const value: string = e && e.clipboardData
            ? e.clipboardData.getData('Text')
            : await navigator.clipboard.readText();
        if (value && value.length === pin.length) {
            const newPin = [];
            for(let i = 0; i < pin.length; i++){
                newPin.push(parseInt(value[i]));
            }
            if (newPin.find(x => isNaN(x))) return;
            setPin(newPin);
        }
        else {
            messager.error(t('login.invalid-paste'), 5000);
        }
    }

    const clear = () => {
        setPin(newPin(props.length));
    }

    useEffect(() => {
        if (isComplete)
            onComplete();
    }, [pin, isComplete, onComplete]);

    return (<Grid>
        <PinContainer>
            {pinRefs.map((ref, i) => 
                (<PinInput 
                    key={`pin-${i}`} 
                    autoComplete="off"
                    inputProps={{ ref: ref }} 
                    value={pin[i]?.toString() || ''} 
                    regex={numberOnly} 
                    onPaste={onPaste} 
                    onInput={e => onInput(i,e)}
                />)
            )}
            {isComplete &&  
                <IconButton sx={{ marginLeft: 'auto'}} onClick={clear}>
                    <Close />
                </IconButton>
            }
        </PinContainer>
        <Numpad 
            onPaste={onPaste}
            onClear={clear}
            onInput={value => {
                const i = pin.findIndex(input => input === null);
                if (i>-1)
                    setPin(prev => {
                        prev[i] = value; 
                        return [...prev];
                    })
            }}
            sx={t => ({ 
                marginTop: t.spacing(2)
            })}
        />
    </Grid>
    );
}