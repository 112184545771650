import { Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AttachImageButton from "../../buttons/AttachImageButton";
import GridSection from "../../GridSection";
import Subtitle2 from "../../labels/Subtitle2";
import CloseIcon from '@mui/icons-material/Close';
import { IIssueReport } from "../DeliveryIssueReport";

interface IProps {
    report: IIssueReport
}
export default function ImageSelect({ report }: IProps){
    const { t } = useTranslation();

    const [loadedFiles, setLoadedFiles] = useState<File[]>([]);
    const [loadedFilesData, setLoadedFilesData] = useState<string[]>([]);

    const addLoadedFile = (file: File) => {
        setLoadedFiles((previousState) => [...previousState, file]);
        var fr = new FileReader();
        fr.onload = () => {
            if(fr.result != null)
                setLoadedFilesData(previousState => [...previousState, fr.result as string]);
        }
        fr.readAsDataURL(file);
    }

    const clearImg = (i: number) => {
        loadedFiles.splice(i, 1);
        loadedFilesData.splice(i, 1);
        setLoadedFiles([...loadedFiles]);
        setLoadedFilesData([...loadedFilesData]);
    }

    useEffect(() => {
        report.files = loadedFiles;
    }, [loadedFiles, report])

    return (
        <GridSection>
            <Subtitle2 style={{marginBottom: 0}}>
                {t('delivery.attach-image')}
            </Subtitle2>
            <Typography variant="caption">{t('delivery.attach-image-desc')}</Typography>
            <Grid container style={{ display: 'inline-flex'}}>
                <AttachImageButton id={`delivery-issue`} onLoad={addLoadedFile} sx={(t) => ({
                    width: '70px',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                })}/>
                {loadedFiles.map((loadedFile, i) => (
                <GridSection key={`loaded-file-${i}`} sx={{ display: 'flex', 'img': { maxWidth: 'calc(100% - 65px)'} }}>
                    {loadedFilesData[i] && (<>
                        <img alt="Uploaded file" src={loadedFilesData[i]}></img>
                        <IconButton onClick={() => clearImg(i)} sx={(t) => ({
                            marginLeft: 'auto',
                            marginTop: 'auto',
                            marginBottom: 'auto'
                        })}>
                            <CloseIcon 
                                sx={(t) => ({
                                    width: '45px',
                                    height: '45px',
                                })}
                            />
                        </IconButton>
                    </>)}
                </GridSection>
                ))}
            </Grid>
        </GridSection>
    );
}