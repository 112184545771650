export interface IUserInfo {
    userName: string;
    userType: UserType;
    termsAccepted: boolean;
    marketingAccepted: boolean;
    sbkAccess: boolean;
    settings: IUserSettings;
}

export enum UserType {
    Normal = 0,
    Seller = 1
}

export interface IUserSettings {
    culture: string;
    autoInventory: boolean;
}