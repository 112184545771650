interface IEnabledComponents {
    [key: string]: boolean;
}

const disabledComponents: IEnabledComponents = {};
const newFeatures: IEnabledComponents = {};

for (let disabledComponent of (process.env.REACT_APP_DISABLED_COMPONENTS || '').split(';')){
    if (!disabledComponents[disabledComponent]){
        disabledComponents[disabledComponent] = true;
    }
}
for (let newFeature of (process.env.REACT_APP_NEW_FEATURES || '').split(';')){
    if (!newFeatures[newFeature]){
        newFeatures[newFeature] = true;
    }
}

export const disabled = (key: string) => disabledComponents[key] || false;
export const isNew = (key: string) => newFeatures[key] || false;

export const features = {
    /** If the feature key is disabled, an empty element is returned */
    empty: (key: string) => disabled(key) ? () => (<></>) : null
}