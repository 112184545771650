import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { useState, useCallback, useEffect } from "react";
import { deliveryStore, deliverySetSize } from "../../services/DeliveryStore";

import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';

interface IProps {
    onFetch: () => void;
}
export default function DeliveryStoreFetchButton(props: IProps){
    const [total, setTotal] = useState<number>(0);
    const [current, setCurrent] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const init = useCallback(async () => {
        setTotal(await deliveryStore.count());
        setCurrent(deliveryStore.get().length);
        setLoading(false);
    }, []);
    
    const loadNextSet = useCallback(async () => {
        setLoading(true);
        await deliveryStore.loadNext();
        setCurrent(deliveryStore.get().length);
        setLoading(false);
        props.onFetch();
    }, [props]);

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    return total <= 0 ? (<></>) : (<Box sx={{width: '100%'}}>
        {loading && <Box sx={{width:'100%', display:'flex'}}>
            <CircularProgress size={40} sx={{margin: 'auto'}}/>
        </Box>}
        {total && total > deliverySetSize && (<>
            <Grid container>
                <IconButton color="primary" onClick={loadNextSet} disabled={loading || current >= total} sx={t =>({ 
                    marginTop: t.spacing(5),
                    marginLeft: 'auto',
                    marginRight: 'auto'
                })}>
                    <YoutubeSearchedForIcon fontSize="large"/>
                </IconButton>
            </Grid>
            <Grid container>
                <Typography variant="subtitle2" color={'primary'} sx={t =>({ 
                    textAlign: 'center',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                })}>
                    ({current}/{total})
                </Typography>
            </Grid>
        </>)}
    </Box>);
}