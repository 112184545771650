import { Button, Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOptionGroup } from "../../../interfaces/IOptionGroup";
import { paramService } from "../../../services/ParamService";
import GridRow from "../../GridRow";
import GridSection from "../../GridSection";
import Subtitle2 from "../../labels/Subtitle2";
import VSelect from "../../validatable/VSelect";
import { IIssueReport } from "../DeliveryIssueReport";

interface IProps {
    report: IIssueReport;
    onChange: () => void;
}
export default function ReclamationTypeSelect({ report, onChange }: IProps){
    const { t } = useTranslation();

    const [types, setTypes] = useState<IOptionGroup[]>([]);
    const [selectedType, setSelectedType] = useState<IOptionGroup | null>(null);
    const [selectedReason, setSelectedReason] = useState<string | null>(null);

    const selectType = (id: number) => {
        setSelectedType(types.find(t => t.id === id) || null);
    }

    useEffect(() => {
        setTypes(paramService.reclamationTypes);
        setSelectedType(paramService.reclamationTypes[0]);
    }, []);
    
    useEffect(() => {
        report.type = selectedType?.id;
        if(report.type){
            report.typeName = types.find(x => x.id === report.type)?.name;
            if(report.typeName)
                report.typeName = t(report.typeName, { lng: 'fi-FI' });
        }
        if(report.reason == null)
            onChange();
        else
            setSelectedReason(null);
    }, [selectedType, types, report, onChange, t]);

    useEffect(() => {
        report.reason = selectedReason != null ? Number(selectedReason) : null;
        if(report.type){
            report.reasonName = types.find(x => x.id === report.type)?.options.find(o => o.value === selectedReason)?.text;
            if(report.reasonName)
                report.reasonName = t(report.reasonName, { lng: 'fi-FI' });
        }
        onChange();
    }, [selectedReason, types, report, onChange, t]);

    return (<>
        <GridRow>
            <Subtitle2>
                {t('delivery.report-type-and-reason')}
            </Subtitle2>
            <Grid sx={(t) => ({ 
                display: 'flex',
                justifyContent: 'space-evenly',
                marginBottom: t.spacing(2)
            })}>
            {types.map(x => 
                <Button 
                    key={`reclamation-type-${x.id}`} 
                    color={x.id === selectedType?.id ? 'primary' : 'secondary'}
                    onClick={() => selectType(x.id)}
                >
                    {t(x.name)}
                </Button>
            )}
            </Grid>
        </GridRow>
        <GridSection>
            <VSelect
                id="reason-type"
                value={selectedReason || 'none'}
                onChange={(e) => setSelectedReason(String(e.target.value))}
                required
                vState={report.state}
                vRules={[
                    (x) => x !== 'none'
                ]}
            >
                <MenuItem value={'none'} disabled>{t('delivery.select-reason-type')}</MenuItem>
                {selectedType?.options.map(x => (<MenuItem key={`reason-type-${x.value}`} value={x.value}>{t(x.text)}</MenuItem>))}
            </VSelect>
        </GridSection>
    </>);
}