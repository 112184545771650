import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IUserInfo } from "../../interfaces/IUserInfo";
import TermsOption from "./TermsOption";


interface IProps {
    user: IUserInfo,
    onAcceptTerms: (terms: boolean, marketing: boolean) => void
}
export default function AcceptTerms(props: IProps){
    const { t } = useTranslation();

    const [canSave, setCanSave] = useState<boolean>(!props.user.termsAccepted);
    const [termsAccepted, setTermsAccepted] = useState<boolean>(props.user.termsAccepted);
    const [marketingAccepted, setMarketingAccepted] = useState<boolean>(props.user.marketingAccepted);

    useEffect(() => {
        if (termsAccepted !== props.user.termsAccepted
            || 
            marketingAccepted !== props.user.marketingAccepted
            ||
            !props.user.termsAccepted
        ){
            setCanSave(true);
        }
        else {
            setCanSave(false);
        }
    }, [
        setCanSave, 
        props.user.marketingAccepted,
        props.user.termsAccepted,
        marketingAccepted, 
        termsAccepted
    ]);

    return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
        <TermsOption 
            title="terms.marketing-title"
            defaultChecked={props.user.marketingAccepted}
            onCheck={checked => setMarketingAccepted(checked)}
            disabled={props.user.termsAccepted && props.user.marketingAccepted}
            content={"terms.marketing-description"}
        />
        <TermsOption 
            title="terms.terms-title"
            defaultChecked={props.user.termsAccepted}
            onCheck={checked => setTermsAccepted(checked)}
            disabled={props.user.termsAccepted}
            content={"terms.terms-description"}
        />
        <Button disabled={!canSave || !termsAccepted} onClick={() => props.onAcceptTerms(termsAccepted, marketingAccepted)}>
            {t('terms.save-options')}
        </Button>
    </Grid>
    );
}