import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next"
import BellIcon from "../icons/BellIcon";
import { isNew } from "../../consts/features";



export default function NewFeature(props: { featureKey: string }){
    const { t } = useTranslation();
    const theme = useTheme();

    const show = isNew(props.featureKey);
    if (!show) return <></>;

    return <Typography color="error" sx={(t) => ({
        position: 'absolute',
        top: 0,
        right: 0,
        margin: t.spacing(1)
    })}>
        <BellIcon fill={theme.palette.error.main} style={{
            margin: 'auto'
        }}/>
        {t('common.newFeature')}
    </Typography>
}