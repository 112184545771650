export const routes = {
    Home: '/',
    Notifications: '/notifications',
    Logout: '/logout',
    Delivery: {
        List: '/delivery-list',
        TimeRequest: '/delivery-time-request',
        IssueReport: '/delivery-issue-report',
        Sync: '/delivery-sync'
    },
    Storage: '/storage',
    Settings: '/settings',
    TermsAndConditions: '/terms',
    FirstTimeLogin: '/first-time-login-:id',

    Survey: '/survey',

    Calculator: '/calculator',
    EBook: '/e-book',

    allowAnonymous: [] as string[]
};

routes.allowAnonymous.push(routes.Survey);