import { useAuth } from "oidc-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AppContainer from "../components/appContainers/AppContainer";
import Loading from "../components/Loading";


export default function Logout(){
    const { t } = useTranslation();
    const auth = useAuth();

    const logout = () => {
        auth.userManager.signoutRedirect();
    }

    useEffect(() => {
        if(auth) logout();
    });

    return (<AppContainer title={t('common.logging-out')} actionButton={React.Fragment}>
        <Loading show/>
    </AppContainer>);
}