export enum Quality
{
    Unknown = 0,
    Poor = 1,
    Low = 2,
    Passing = 3,
    Average = 4,
    Good = 5,
    High = 6,
    Excellent = 7,
};