import { useTranslation } from "react-i18next";
import { ICalculatorConfig } from "../../../../interfaces/ICalculatorConfig";
import { IOption } from "../../../../interfaces/IOption";
import { IUIGrainInput, IUIGrassInput, UIInput } from "../../../../interfaces/payloads/ICalculatorInput";
import { useState } from "react";
import { OutputMode } from "../CalculatorOutput";
import { CultivatedPlant } from "../../../../enums/calculator/CultivatedPlant";

function resolve<T>(value: T, options: IOption<T>[]) {
    return options.find(x => x.value === value)?.text ?? '';
}
function InputItem<T>({ title, value, options }: { title: string, value: T, options?: IOption<T>[]}){
    const { t } = useTranslation();

    return (<tr>
        <td>
            <b>{t(title)}</b>
        </td>
        <td>
            {options ? t(resolve(value, options)) : String(value)}
        </td>
    </tr>);
}

interface IProps {
    input: UIInput;
    config: ICalculatorConfig;
}
export default function PdfInputSummary(props: IProps){
    const { t } = useTranslation();

    const config = props.config;
    const input: IUIGrassInput&IUIGrainInput = props.input as any;

    const [mode] = useState<OutputMode>(input.cultivatedPlant === CultivatedPlant.Silage 
        ? OutputMode.Grass
        : OutputMode.Grain
    );
    const [grass] = useState(mode === OutputMode.Grass);
    const [grain] = useState(mode === OutputMode.Grain);
    
    return (<table>
        <thead>
            <tr>
                <td style={{width: '150px'}}></td>
                <td></td>
            </tr>
        </thead>
        <tbody>
            <InputItem title="calculator.cultivated-plant" 
                value={input.cultivatedPlant} options={config.cultivatedPlants}
            />
            {input.cropLevel && <InputItem title="calculator.crop-level" 
                value={input.cropLevel}
            />}
            <InputItem title="calculator.soil-type" 
                value={input.soil} options={config.soilTypes}
            />
            <InputItem title="calculator.phosphorous-class" 
                value={input.phosphorousClass} options={config.qualityClasses}
            />
            {input.potassiumClass && <InputItem title="calculator.potassium-class" 
                value={input.potassiumClass} options={config.qualityClasses}
            />}
            {grass && (<>
                {input.cuts && <InputItem title="calculator.harvest-count" 
                    value={input.cuts}
                />}
            </>)}
            {grain && <>
                {input.phosphorousFix ? <InputItem title={t('calculator.p-fix')}
                    value={`${input.phosphorousFix} kg P/${t('unit.hect')}`}
                /> : <InputItem title={t('calculator.p-fix')}
                    value={`${t('common.no')}`}
                />}
                {input.phosphorousTarget && <InputItem title="calculator.p-target"
                    value={`${input.phosphorousTarget}`}
                />}
                <InputItem title="calculator.planned-n"
                    value={input.batchInputs.map(bi => bi.input).reduce((a,b) => a + b, 0)}
                />
                {input.batchInputs.length > 1 && (<>
                    <InputItem title="calculator.grain-n-1"
                        value={input.batchInputs[0].input}
                    />
                </>)}
                {input.batchInputs.length > 1 && (<>
                    <InputItem title="calculator.grain-n-2"
                        value={input.batchInputs[1].input}
                    />
                </>)}
            </>}
            {grass && input.useManure && (<>
                <tr>
                    <td><b>{t('calculator.used-manure')}</b></td>
                        <td><b>{input.batchInputs[0].number}</b>: {input.batchInputs[0].input}</td> 
                </tr>
                {[...input.batchInputs.slice(1)].filter(cut => cut.number <= input.cuts).map(cut => (<tr key={`cut-manure-usage-input-key-${cut.number}`}>
                        <td></td>
                        <td><b>{cut.number}</b>: {cut.input}</td> 
                </tr>))}
                <tr>
                    <td><b>{t(resolve(input.manure.type, config.manureTypes))}</b></td>
                    <td>
                        <b>N:</b> {input.manure.n} &nbsp;
                        <b>P:</b> {input.manure.p} &nbsp;
                        <b>K:</b> {input.manure.k} &nbsp;
                    </td>
                </tr>
            </>)}
            {input.grassMix && <InputItem title="calculator.grass-mix" 
                value={input.grassMix} options={config.grassMixes}
            />}
        </tbody>
    </table>);
}