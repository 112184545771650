import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import Backend from 'i18next-http-backend';

const appLocation = process.env.REACT_APP_LOCATION === ''
    ? ''
    : `${process.env.REACT_APP_LOCATION}/`

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        //backend :{ loadPath: `${process.env.REACT_APP_WORK_API_URI}Resources/{{lng}}`},
        lng: 'fi-FI',
        initImmediate: false,
        ns: ['Yara'],
        defaultNS: 'Yara',
        backend: {
            loadPath: () => `${window.location.origin}/${appLocation}locales/{{lng}}/{{ns}}.json`,
        },
        interpolation : { 
            escapeValue :false, 
        }
    });

export default i18n;