import { useEffect, useState } from "react";
import AppContainer from "../../components/appContainers/AppContainer";
import { IDelivery } from "../../interfaces/IDelivery";
import { deliveryStore, filters } from "../../services/DeliveryStore";
import DeliveryHeader from "../../components/delivery/DeliveryHeader";
import { useNavigate } from "react-router-dom";
import CardListActionItem from "../../components/CardListActionItem";
import SbkSyncButton from "../../components/buttons/SbkSyncButton";
import HeaderCloseButton from "../../components/buttons/HeaderCloseButton";
import { Typography } from "@mui/material";

import { useTranslation } from "react-i18next";
import { routes } from "../../consts/routes";

export default function DeliverySyncList(){
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [options] = useState<IDelivery[]>(deliveryStore.get().filter(filters.unsynced));

    useEffect(() => {
        if(options.length === 0)
            navigate(routes.Delivery.List);
        // eslint-disable-next-line
    }, [options]);

    return (
        <AppContainer 
            title='pages.storage' 
            actionButton={() => <HeaderCloseButton onClick={() => navigate(routes.Delivery.List)}/>}
            style={{
                paddingTop: 0
            }}
        > 
            <Typography variant="h6" color={'primary'} sx={t => ({textAlign: 'center', marginTop: t.spacing(2) })}>
                {t('delivery.sync-orders-1')}
            </Typography>
            <Typography variant="subtitle1" sx={t => ({textAlign: 'center', marginBottom: t.spacing(2) })}>
                {t('delivery.sync-orders-2')}
            </Typography>
            {options.map(delivery => (
                <CardListActionItem key={`delivery-select-item-${delivery.id}`} onClick={() => {}}>
                    <DeliveryHeader {...delivery}/>
                    <SbkSyncButton id={delivery.id} title={t('storage.transfer')} status={delivery.sbk} noAddInfo sx={{margin: 'auto'}}/>
                </CardListActionItem>
            ))}
        </AppContainer>
    );
}