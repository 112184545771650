import { CommonProps } from "@mui/material/OverridableComponent";
import MenuButton from "../buttons/MenuButton";
import { AppContainerBase } from "./AppContainerBase";

interface IProps {
    title: string;
    actionButton?: React.FC;
    children?: React.ReactNode;
}
export default function AppContainer(props: IProps & CommonProps){
    const {
        actionButton,
        ...commonProps
    } = props;
    return (<AppContainerBase
        {...commonProps}
        config={{
            top: 15,
            sides: 15,
            borderRadius: 10,
            headerHeight: 60,
            headerMarginBottom: 10,
            spacing: 1,
            titleVariant: 'h6'
        }}
        actionButtons={[props.actionButton || MenuButton]}
    >
        {props.children}
    </AppContainerBase>);
}