import { Box, Collapse, Slide } from "@mui/material";
import { useEffect, useState } from "react";
import { INotification } from "../../interfaces/INotification";

import NotificationDetails from "./NotificationDetails";
import NotificationHeader from "./NotificationHeader";
import { yaraApi } from "../../services/YaraApi";
import { notificationStore } from "../../services/NotificationStore";

interface IProps {
    notification: INotification;
    open?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    onRemove?: () => void;
    readonly?: true;
}
export default function Notification(props: IProps){

    const [hasContent] = useState<boolean>(!!props.notification.delivery);

    const [fadeIn, setFadeIn] = useState<boolean>(true);
    const [showDetails, setShowDetails] = useState<boolean>(props.open || !hasContent);

    const toggle = () => {
        showDetails ? close() : open();
    }

    const open = () => {
        if(props.onOpen)
            props.onOpen();
        else
            setShowDetails(true);
    }

    const close = () => {
        if(props.onClose)
            props.onClose();
        else
            setShowDetails(false);
    }

    const remove = () => {
        if (props.readonly) return;
        setFadeIn(false);
        yaraApi.hideNotification(props.notification.id).then(() => {
            notificationStore.remove(props.notification.id);
            if(props.onRemove)
                props.onRemove();
        });
    }

    useEffect(() => {
        if (props.open !== undefined)
            setShowDetails(props.open);
    }, [props.open])

    return (
    <Slide direction="left" key={props.notification.id} in={fadeIn} mountOnEnter unmountOnExit onExited={remove}>
        <Box>
            <NotificationHeader 
                notification={props.notification} 
                onClick={toggle} 
                onRemove={showDetails && hasContent ? () => setFadeIn(false) : undefined}
                readonly={props.readonly}
            />
            {hasContent && 
                <Collapse in={showDetails}>
                    <NotificationDetails {...props.notification}/>
                </Collapse>
            }
        </Box>
    </Slide>
    );
}