import HeaderActionButton from './HeaderActionButton';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../consts/routes';
import { CommonProps } from '@mui/material/OverridableComponent';
import { HeaderIconSize } from '../../consts/headerIconSize';

import SettingsIcon from '../icons/SettingsIcon';

export default function SettingsButton(props: CommonProps) {
    const navigate = useNavigate();

    const onClick = () => {
        navigate(routes.Settings);
    }

    return (<>
        <HeaderActionButton {...props} onClick={onClick}>
            <SettingsIcon style={{
                height: HeaderIconSize, 
                width: HeaderIconSize,
            }}/>
        </HeaderActionButton>
    </>);
}