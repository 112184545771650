import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContainer from "../components/appContainers/AppContainer";
import HeaderCloseButton from "../components/buttons/HeaderCloseButton";
import Loading from "../components/Loading";
import AcceptTerms from "../components/terms/AcceptTerms";
import TermsContent from "../components/terms/TermsContent";
import TermsUnacceptedHeader from "../components/terms/TermsUnacceptedHeader";
import { routes } from "../consts/routes";
import { ITerms } from "../interfaces/ITerms";
import { IUserInfo } from "../interfaces/IUserInfo";
import { yaraApi } from "../services/YaraApi";

interface IProps {
    user: IUserInfo;
    onTermsAccepted?: (accepted: boolean) => void;
}
export default function TermsAndConditions(props: IProps){
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [culture, setCulture] = useState<string>(props.user.settings.culture);
    const [terms, setTerms] = useState<ITerms>();
    
    const [containerHeader] = useState<string>(props.user.termsAccepted 
        ? 'pages.terms-and-conditions' 
        : 'pages.accept-terms-and-conditions'
    );

    useEffect(() => {
        if(culture){
            setLoading(true);
            yaraApi
                .getTerms(culture)
                .then(terms => {
                    setTerms(terms);
                })
                .finally(() => setLoading(false));
        }
    }, [culture])

    const onClose = () => {
        if(!props.user.termsAccepted)
            navigate(routes.Logout);
        else
            navigate(routes.Settings);
    }

    const acceptTerms = (termsAccepted: boolean, marketingAccepted: boolean) => {
        if(termsAccepted && terms?.id){
            setLoading(true);
            yaraApi
                .acceptTerms(terms?.id, marketingAccepted)
                .then(ok => {
                    if(ok && props.onTermsAccepted)
                        props.onTermsAccepted(true);
                })
                .finally(() => setLoading(false));
        }
    }

    return (<AppContainer 
        title={containerHeader}
        actionButton={() => (<HeaderCloseButton onClick={onClose}/>)}
    >
        {!loading && (
            <Grid container sx={(theme) => ({
                padding: theme.spacing(2)
            })}>
                {!props.user.termsAccepted && (<TermsUnacceptedHeader 
                    user={props.user}
                    setCulture={setCulture}
                    setLoading={setLoading}
                />)}
                <TermsContent paragraphs={terms?.paragraphs}/>
                <AcceptTerms 
                    user={props.user}
                    onAcceptTerms={acceptTerms}
                />
            </Grid>
        )}
        {loading && (<Loading show/>)}
    </AppContainer>);
}