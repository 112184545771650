import { Select, SelectProps } from "@mui/material";
import { useEffect, useState } from "react";
import { IVStateProps } from "../../classes/ValidationState";

export default function VSelect<T>(props: SelectProps<T> & IVStateProps<T>){
    const {
        vState,
        vRules,
        onValidate,
        ...componentProps
    } = props;

    const [error, setError] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [validatedValue, setValidatedValue] = useState<"" | T | undefined>(undefined);

    componentProps.onChange = (e, c) => {
        if(vState)
            vState.autoInnerValidate(
                componentProps,
                vRules,
                e.target.value as T,
                setError
            );
        if(props.onChange) props.onChange(e, c);
    }

    useEffect(() => {
        return () => {
            if(vState)
                vState.dismount(componentProps);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!initialized && vState){
            vState.autoInit(
                componentProps,
                vRules,
                setError
            );
            setInitialized(true);
        }
    }, [
        componentProps,
        initialized,
        vState,
        vRules
    ]);

    useEffect(() => {
        if (componentProps.value !== validatedValue && vState){
            vState.autoOuterValidate(
                componentProps,
                vRules,
                onValidate,
                setError
            );
            setValidatedValue(componentProps.value);
        }
    }, [
        componentProps,
        componentProps.value,
        validatedValue,
        vState,
        vRules,
        onValidate
    ]);

    if(componentProps.error === undefined)
        componentProps.error = error;
    return (<Select {...componentProps}/>);
}