import { LinearProgress, Typography } from "@mui/material";
import { IUserInfo } from "../../interfaces/IUserInfo";
import CardListItem from "../CardListItem";

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PersonIcon from '@mui/icons-material/Person';
import GridRow from "../GridRow";
import { useEffect, useState } from "react";
import { IUserCustomer } from "../../interfaces/IUserCustomer";
import { userService } from "../../services/UserService";

interface IProps {
    user: IUserInfo
}
export default function UserCard(props: IProps){
    const [loading, setLoading] = useState<boolean>(false);
    const [customers, setCustomers] = useState<IUserCustomer[]>([]);

    useEffect(() => {
        setLoading(true);
        userService.getUserCustomers().then(res => {
            setCustomers(res);
        }).finally(() => setLoading(false));
        // eslint-disable-next-line
    }, []);

    return (<CardListItem>
        <GridRow sx={{ display: 'inline-flex' }}>
            <VerifiedUserIcon color={'primary'} sx={{marginTop: 'auto', marginBottom: 'auto'}}/>
            <Typography color={'primary'} variant="h6" fontWeight="bold">{props.user.userName}</Typography>
        </GridRow>
        {loading && <LinearProgress sx={{ width: '100%'}}/>}
        {customers.map(c => (
            <GridRow key={`customer-${c.nr}`} sx={{ display: 'inline-flex' }}>
                <PersonIcon sx={{marginTop: 'auto', marginBottom: 'auto'}}/>
                <Typography variant="h6" fontWeight="bold" sx={{marginTop: 'auto'}}>
                    {c.name}
                </Typography>
            </GridRow>
        ))}
    </CardListItem>);
}