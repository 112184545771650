import { IAutofillConfig, IGrainConfig, IGrassConfig } from "../interfaces/ICalculatorConfig";
import { ICalculatorOutputResult } from "../interfaces/ICalculatorOutput";
import { ICalculatorPayload } from "../interfaces/payloads/ICalculatorInput";
import ApiServiceBase from "./ApiServiceBase";
import { yaraApi } from "./YaraApi";

export class FertilizerApi extends ApiServiceBase {

    grassConfig = (): Promise<IGrassConfig> => {
        return this.get<IGrassConfig>('calculator/grass');
    }

    grainConfig = (): Promise<IGrainConfig> => {
        return this.get<IGrainConfig>('calculator/grain');
    }

    calculate = (input: ICalculatorPayload): Promise<ICalculatorOutputResult> => {
        yaraApi.action(`calculate>>plant>>${input.cultivatedPlant}`);
        const endpoint = input.cultivatedPlant === 1
            ? 'calculator/grass'
            : 'calculator/grain';
        return this.post<ICalculatorOutputResult, ICalculatorPayload>(endpoint, input);
    }

    autofillP = (pclass: number, pfix?: number): Promise<number> => {
        return this.get<number>(`calculator/grain/autofill/p/${pclass}/${pfix || 0}`);
    }

    autofillNConfig = (plant: number, soil: number): Promise<IAutofillConfig> => {
        return this.get<IAutofillConfig>(`calculator/grain/autofill/n/${plant}/${soil}`);
    }
}

export const fertilizerApi = new FertilizerApi(process.env.REACT_APP_FERT_API_URI as string);