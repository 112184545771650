import { useSnackbar, SnackbarKey, OptionsObject, VariantType } from 'notistack';
import { useTranslation } from 'react-i18next';
import ApiServiceBase from '../services/ApiServiceBase';

type SnackbarKeys = {
    success: SnackbarKey | undefined,
    error: SnackbarKey | undefined,
    warning: SnackbarKey | undefined,
    info: SnackbarKey | undefined,
};
const keys: SnackbarKeys = {
    success: undefined,
    error: undefined,
    warning: undefined,
    info: undefined,
}

type Messager = (text?: string | null | string[], autoHideDuration?: number | null) => void;
interface IMessager {
    success: Messager,
    error:  Messager,
    warning:  Messager,
    info:  Messager
}

const msgProps = (variant: VariantType, autoHideDuration: number | null): OptionsObject => ({
    variant: variant,
    autoHideDuration: autoHideDuration || 1000
});

let messager: IMessager;

interface IProps {
    apis: ApiServiceBase[];
    children: any;
}
export function MessagerProvider(props: IProps){
    const { t } = useTranslation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const translate = (input: string) => {
        const parts = input.split('|p');
        if(parts.length === 1)
            return parts[0];
        return t(parts[0]).replace('{0}', parts[1]);
    }

    if (messager === undefined){
        const msg = (
            key: keyof SnackbarKeys & VariantType, 
            msg: string | null | string[], 
            autoHideDuration: number | null = null
        ) => {
            if(keys[key]) closeSnackbar(keys[key]);
    
            const msgs = msg instanceof Array<string> 
                ? msg.map(x => x.split(' ')) 
                : [[msg || `defaultmsg.${key}`]];
    
            for (let message of msgs){
                const translated = message.reduce((output, input) => output += translate(input) + ' ', '');
                const msgKey = enqueueSnackbar(
                    translated, 
                    {
                        ...msgProps(key, autoHideDuration), 
                        onClick: () => closeSnackbar(msgKey)
                    }
                );
                keys[key] = msgKey;
            }
        }
    
        // Creates the messager function.
        const getMessager = (key: keyof SnackbarKeys & VariantType) => {
            return (text: string | null | string[] = null, autoHideDuration: number | null = null) => msg(key, text, autoHideDuration);
        }

        messager = {
            success: getMessager("success"),
            error:  getMessager("error"),
            warning:  getMessager("warning"),
            info:  getMessager("info")
        };

        if (props.apis)
            for (let apiService of props.apis){
                apiService.errorMessager = messager.error;
            }
    }

    return props.children;
}

export function useMessager(){
    return messager;
}