import { Box, Typography } from "@mui/material";
import GridRow from "../GridRow";
import React from "react";

interface IProps{
    id: string;
    items: ({name?: string, width?: string, node: React.ReactNode})[];
}
export default function SpacedInlineItems(props: IProps){

    return (<GridRow sx={(t) => ({ 
        display: 'inline-flex', 
        justifyContent: 'space-between',
        width: '100%', 
        marginTop: t.spacing(2)
    })}>
        {props.items.map((item, index) => (<Box key={`${props.id}-${index}`} sx={{display: 'flex', width: item.width || 'inherit', marginLeft: '5px'}}>
            {item.name && (<Typography sx={{marginTop: 'auto', marginBottom: 'auto', marginRight: '5px'}}><b>{item.name}</b></Typography>)}
            {item.node}
        </Box>))}
    </GridRow>);
}