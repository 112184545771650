import { ListItemButton } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";

interface IProps {
    onClick?: () => void;
    children?: React.ReactNode;
}
export default function HeaderDropMenuButton(props: IProps & CommonProps){
    return (
        <ListItemButton 
            sx={{
                color: '#ffffff', 
                fontWeight: 'normal', 
                justifyContent: 'left',
                border: 'none'
            }}
            {...props}
        >
            {props.children}
        </ListItemButton>
    );
}