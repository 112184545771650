import { ListItemButton, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import CardListItem from "../../components/CardListItem";
import Loading from "../../components/Loading";
import { features } from "../../consts/features";
import { IOrganization } from "../../interfaces/IOrganization";
import { IUserInfo } from "../../interfaces/IUserInfo";
import { access } from "../../services/AccessService";
import { yaraApi } from "../../services/YaraApi";
import GridRow from "../GridRow";
import GridSection from "../GridSection";
import { LargeSwitch } from "../LargeSwitch";
import { deliveryStore, filters } from "../../services/DeliveryStore";
import { useNavigate } from "react-router-dom";
import { routes } from "../../consts/routes";

interface IProps {
    user: IUserInfo
}
export default features.empty("AutoInventoryCard") || function AutoInventoryCard(props: IProps){
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [autoInventory, setAutoInventory] = useState<boolean>(props.user.settings.autoInventory);
    const [organizationOptions, setOrganizationOptions] = useState<IOrganization[]>();

    const changeSettings = useCallback(async (on: boolean) => {
        setLoading(true);
        if (on) {
            const orgs = await yaraApi.getOrganizations();
            if(orgs.length <= 1) {
                const org = orgs.length === 1
                    ? orgs[0]
                    : {
                        existing: false,
                        organizationId: null,
                        name: null
                    };
                    
                if (await yaraApi.setAutoInventoryOn(org)) {
                    setAutoInventory(true);
                    props.user.settings.autoInventory = true;

                    await deliveryStore.refresh();
                    if (deliveryStore.getLatest(filters.unsynced))
                        navigate(routes.Delivery.Sync);
                }
            }
            else {
                setLoading(false);
                setOrganizationOptions(orgs);
            }
        }
        else if (await yaraApi.setAutoInventoryOff()) {
            setAutoInventory(false);
            props.user.settings.autoInventory = false;
        }
        setLoading(false);
         // eslint-disable-next-line
    }, []);

    const selectOrganization = (id: string|null, name: string|null) => {
        setLoading(true);
        yaraApi.setAutoInventoryOn({ 
                existing: id !== null,
                organizationId: id,
                name: name
            }).then(ok => {
                if(ok) {
                    setAutoInventory(true);
                    props.user.settings.autoInventory = true;
                }
            })
            .finally(() => setLoading(false))
    }

    return (<CardListItem>
        <Loading show={loading}/>
        <GridSection>
            <Typography variant="h6" fontWeight="bold">{t('settings.inventory-accounting')}</Typography> 
        </GridSection>
        {!organizationOptions && (<>
            <GridRow sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography>
                    {t('settings.inventory-accounting-desc')}
                </Typography>
                <LargeSwitch
                    sx={{
                        marginTop: 'auto',
                        marginBottom: 'auto'
                    }} 
                    disabled={!access.sbkAccess}
                    checked={autoInventory}
                    onChange={v => changeSettings(v.target.checked)}
                />
            </GridRow>
            {!access.sbkAccess && (
                <GridRow>
                    <Typography variant="caption" sx={(theme) => ({
                        color: theme.palette.Orange.main
                    })}>
                        {t('settings.inventory-accounting-ad')}
                    </Typography>
                </GridRow>
            )}
        </>)}
        {organizationOptions && (<>
            {organizationOptions.map(oo => (<GridRow>
                <ListItemButton onClick={() => selectOrganization(oo.organizationId, oo.name)}>{oo.name}</ListItemButton>
            </GridRow>))}
        </>)}
    </CardListItem>);
};