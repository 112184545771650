export interface IVStateProps<T> {
    vState?: ValidationState; 
    onValidate?: (x:boolean) => void;
    vRules?: ((x:T) => boolean)[];
}

export class ValidationState {
    
    private state: any;
    private controlled: any;
    private touched: any;

    public valid: boolean;

    public afterValidation: (valid: boolean) => void;

    constructor(afterValidation?: (valid: boolean) => void, initial?: boolean) {
        this.state = {};
        this.controlled = {};
        this.touched = {};
        this.valid = initial || false;
        this.afterValidation = afterValidation || (() => {});
    }

    public reset = (initial?: boolean) => {
        this.state = {};
        this.touched = {};
        this.valid = initial || false;
    }

    public register = (prop: string, initial?: boolean, controlled?: boolean) => {
        this.state[prop] = initial !== undefined ? initial : false;
        if(controlled) this.controlled[prop] = true;
    }

    public unregister = (prop: string) => {
        delete this.state[prop];
        if(this.controlled[prop])
            delete this.controlled[prop];
        this.update();
    }

    public set = (prop: string, isValid: boolean) => {
        this.state[prop] = isValid;
        this.update();
    }

    public get = (prop: string): boolean => {
        return !!this.state[prop];
    }

    public has = (prop: string): boolean => {
        return this.state[prop] !== undefined;
    }

    public update = () => {
        const previous = this.valid;
        this.valid = this.isValid();
        if (previous !== this.valid)
            this.afterValidation(this.valid);
    }

    public isValid = (): boolean => {
        for(let prop in this.state){
            if(!this.state[prop]) {
                return false;
            }
        }
        return true;
    }

    /**
     * auto-initialization for component
     */
    public autoInit = <T>(
        componentProps: { 
            id?: string,
            required?: boolean, 
            value?: any
        },
        vRules: ((x:T) => boolean)[] | undefined,
        setError: (isValid: boolean) => void
    ) => {
        if(componentProps.id) {
            // Initialize
            if(componentProps.required){
                this.register(
                    componentProps.id, 
                    false,
                    componentProps.value !== undefined
                );
                this.autoValidate(
                    componentProps,
                    vRules,
                    componentProps.value as T,
                    setError
                );
            }
            else {
                this.register(
                    componentProps.id, 
                    true,
                    componentProps.value !== undefined
                );
            }
        }
    }

    public dismount = (
        componentProps: { 
            id?: string
        },
    ) => {
        if (componentProps.id) {
            this.unregister(componentProps.id);
        }
    }

    /**
     * auto-validation for changes from outside of component
     */
    public autoOuterValidate = <T>(
        componentProps: { 
            id?: string,
            required?: boolean, 
            error?: boolean,
            value?: any
        },
        vRules: ((x:T) => boolean)[] | undefined,
        onValidate?: (x:boolean) => void,
        setError?: (isValid: boolean) => void
    ) => {
        if(componentProps.id) {
            if (!this.controlled[componentProps.id]) return;
            if (this.touched[componentProps.id]){
                this.touched[componentProps.id] = false;
            }
            // Update initial state
            this.autoValidate(
                componentProps,
                vRules,
                componentProps.value as T,
                setError
            );
            if(onValidate) onValidate(this.valid);
        }
    }
    
    /**
     * auto-validation for changes from inside of component
     */
    public autoInnerValidate = <T>(
        componentProps: { 
            id?: string,
            required?: boolean, 
            error?: boolean 
        } | {
            id?: string,
            value?: any
        },
        vRules: ((x:T) => boolean)[] | undefined,
        value: T | null | undefined,
        setError?: (isValid: boolean) => void
    ) => {
        //if('value' in componentProps) return; // Disable inner validation when value is externally controlled.
        if(componentProps.id){
            if (!this.controlled[componentProps.id])
                this.touched[componentProps.id] = true;
        }

        this.autoValidate(componentProps, vRules, value, setError);
    }

    /**
     * auto-validation for changes for component
     */
    public autoValidate = <T>(
        componentProps: { 
            id?: string,
            required?: boolean, 
            error?: boolean 
        },
        vRules: ((x:T) => boolean)[] | undefined,
        value: T | null | undefined,
        setError?: (isValid: boolean) => void
    ) => {
        if(componentProps.id) {
            let isValid = true;
            
            if (value === null || value === undefined || value === ''){
                isValid = !componentProps.required;
            }
            else if(vRules){
                for(let vRule of vRules){
                    if(!vRule(value))
                        isValid = false;
                }
            }
            this.set(componentProps.id, isValid);
            
            if(setError) setError(!isValid);
        }
    }
}