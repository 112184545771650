import { Button, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AppContainer from "../components/appContainers/AppContainer";
import HeaderCloseButton from "../components/buttons/HeaderCloseButton";
import GridSection from "../components/GridSection";
import Loading from "../components/Loading";
import SurveyFrameLoader from "../components/survey/SurveyFrameLoader";
import { routes } from "../consts/routes";
import { INps } from "../interfaces/INps";
import { useMessager } from "../providers/MessagerProvider";
import { access } from "../services/AccessService";
import { yaraApi } from "../services/YaraApi";
import { features } from "../consts/features";

const sourceContext = 2; // Survey opened from OmaYara.

type SurveyState = 'closed' | 'open' | 'finished' | 'not-found';

export default features.empty('Survey') || function Survey() {
    const { t } = useTranslation();
    const messager = useMessager();
    const navigate = useNavigate();

    const { search } = useLocation();
    const id = new URLSearchParams(search).get('id') || '00000000-0000-0000-0000-000000000000';
    const internal = new URLSearchParams(search).get('internal') !== null;
    
    const [state, setState] = useState<SurveyState>(internal ? 'open' : 'closed');

    const [loading, setLoading] = useState<boolean>(false);
    const [nps, setNps] = useState<INps>();

    const openSurvey = useCallback(() => {
        setLoading(true);
        yaraApi.getNps(id).then(res => {
            if(res !== null) 
                setNps({
                    ...res,
                    source: res.source + `&ctx=${sourceContext}`
                });
            else
                setState('not-found');
        }).finally(() => setLoading(false));
    }, [id]);

    useEffect(() => {
        openSurvey();
    }, [openSurvey]);

    const onSurveyClose = useCallback(() => {
        if(access.loggedIn && state === 'finished')
            navigate(routes.Home);
        else if (internal)
            navigate(-1);
        else
            setState('closed');
    }, [navigate, state, internal]);

    const onComplete = useCallback(() => {
        if (state === 'finished') return;
        setState('finished');

        setLoading(true);
        yaraApi.closeNps(id).then(ok => {
            if (ok) setNps({
                open: false
            });
            messager.success('OK', 3000);
        }).finally(() => setLoading(false));

    }, [id, state, messager]);

    const onError = useCallback(() => {
        if (nps?.source == null) return;
        messager.error(t('survey.frame-error'), 5000);
        if (internal) {
            yaraApi.closeNps(id).then(() => navigate(-1));
        }
        else
            setState('closed');
    }, [nps, internal, id, messager, navigate, t]);

    return state === 'open' ? (
        <AppContainer 
            title={t('survey.title')}
            actionButton={() => (<HeaderCloseButton onClick={onSurveyClose}/>)}
        >
            {nps && nps.source ? (
                <SurveyFrameLoader source={nps.source} onComplete={onComplete} onError={onError}/>
            ): (
                <Loading show/>
            )}
        </AppContainer>
    ) : (
        <AppContainer title={t('survey.title')}>
            <Grid container sx={(t) => ({
                padding: t.spacing(2), 
                display: 'flex',
                height: '100%'
            })}>
                <Loading show={loading}/>
                <GridSection sx={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    textAlign: 'center'
                }}>
                {state === 'not-found' && 
                    <Typography variant="h5">
                        {t('survey.not-found')}
                    </Typography>
                }
                {nps && nps.open && state === 'closed' && 
                    <Button onClick={() => setState('open')}>
                        {t('survey.open-survey')}
                    </Button>
                }
                {nps && !nps.open && (<>
                    <Typography variant="h5">
                        {t('survey.thank-you')}
                    </Typography>
                </>)}
                </GridSection>
            </Grid>
        </AppContainer>
    );
}