import { Grid } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import { useCallback, useState } from "react";
import { IDelivery } from "../../interfaces/IDelivery";
import Loading from "../Loading";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ValidationState } from "../../classes/ValidationState";
import { useMessager } from "../../providers/MessagerProvider";
import { yaraApi } from "../../services/YaraApi";
import ProductBatchSelect from "./issue-report/ProductBatchSelect";
import ImageSelect from "./issue-report/ImageSelect";
import ContactMethod from "./issue-report/ContactMethod";
import ReclamationTypeSelect from "./issue-report/ReclamationTypeSelect";
import AddInfo from "./issue-report/AddInfo";

export interface IIssueReport {
    state: ValidationState;
    orderId: string;
    type: number | undefined | null;
    reason: number | undefined | null;
    typeName: string | undefined | null;
    reasonName: string | undefined | null;
    products: string[],
    batchNrs: string[]
    addInfo: string,
    files: File[],
    contactMethod: number;
    contactInfo: string;
}

const toFormData = (report: IIssueReport): FormData => {
    const formData = new FormData();

    formData.append('orderId', report.orderId);
    formData.append('reasonGroupName', report.typeName || '');
    formData.append('reasonName', report.reasonName || '');
    formData.append('addInfo', report.addInfo);

    for (let selectedProduct of report.products) {
        formData.append('products[]', selectedProduct);
    }
    for (let batchNr of report.batchNrs) {
        formData.append('batchNrs[]', batchNr);
    }
    formData.append('contactMethod', String(report.contactMethod));
    formData.append('contactInfo', report.contactInfo);
    for (let file of report.files) {
        formData.append('files', file, file.name);
    }

    return formData;
}

interface IProps {
    data: IDelivery;
    afterSave: () => void;
}
export default function DeliveryIssueReport({ data, afterSave, ...commonProps }: IProps & CommonProps) {
    const { t } = useTranslation();
    const messager = useMessager();

    const [loading, setLoading] = useState<boolean>(false);
    const [canSave, setCanSave] = useState<boolean>(false);
    const [requiresProduct, setRequiresProduct] = useState<boolean>(false);

    const [report] = useState<IIssueReport>({
        state: new ValidationState(),
        orderId: data.id,
        type: null,
        reason: null,
        typeName: null,
        reasonName: null,
        products: [],
        batchNrs: [],
        addInfo: '',
        files: [],
        contactMethod: 1,
        contactInfo: ''
    });

    const save = () => {
        if (!report.state.valid) return;
        
        setLoading(true);
        yaraApi
            .sendIssueReport(toFormData(report))
            .then((ok) => {
                if (ok) {
                    messager.success("success.issue-report", 2000);
                    afterSave();
                }
            })
            .finally(() => setLoading(false));
    }

    const onChange = useCallback(() => {
        if (report.type) {
            switch (report.type) {
                case 1:
                case 2: setRequiresProduct(true); break;
                default: setRequiresProduct(false); break;
            }
        }
        setCanSave(report.state.valid);
    }, [report]);

    return (
    <Grid container {...commonProps}>
        <Loading show={loading} />
        <ReclamationTypeSelect
            report={report}
            onChange={onChange}
        />
        <ProductBatchSelect
            report={report}
            items={data.items}
            required={requiresProduct}
            onChange={onChange}
        />
        <AddInfo report={report} />
        <ImageSelect report={report} />
        <ContactMethod report={report} />
        <Button onClick={save} disabled={!canSave}>
            {t('delivery.send-issue-report')}
        </Button>
    </Grid>
    );
}