import { Box, Button, LinearProgress, SxProps, Theme } from "@mui/material";
import { useMessager } from "../../providers/MessagerProvider";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { OrderSbkStatus } from "../../enums/OrderSbkStatus";
import { yaraApi } from "../../services/YaraApi";

import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { deliveryStore } from "../../services/DeliveryStore";

function SbkProgressBar({ total }: { total: number }){
    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        const timer = setInterval(() => {
            const progressInterval = 100 * (0.5/total);
            setProgress(prev => {
                if(prev === 100) return 0;
                return prev + progressInterval;
            });
        }, 500);

        return () => {
          clearInterval(timer);
        }; 
        // eslint-disable-next-line
    }, []);

    return (<LinearProgress variant="determinate" value={progress} sx={{width: '50px'}}/>)
}

interface IProps {
    id: string;
    title?: string;
    status: OrderSbkStatus|null;
    sx?: SxProps<Theme>;

    noAddInfo?: true;
}
export default function SbkSyncButton(props: IProps){
    const { t } = useTranslation();
    const messager = useMessager();

    const [status, setStatus] = useState<OrderSbkStatus|null>(props.status);
    const [secondsLeft, setSecondsLeft] = useState<number|null>(null);

    const sbkSyncRequest = useCallback(() => {
        setStatus(OrderSbkStatus.Pending);
        yaraApi.deliverySbkSync(props.id)
            .then(() => {
                if(!props.noAddInfo)
                    messager.success(
                        'delivery.sync-orders-2',
                        5000
                    );
            })
            .catch(error => {
                messager.error(error);
                setStatus(OrderSbkStatus.Unsynced);
            });
    }, [props.id, props.noAddInfo, messager]);

    const sbkSyncStatus = useCallback((wait: number|null = null) => {
        setTimeout(() => {
            yaraApi.getDeliverySbkStatus(props.id).then(res => {
                if (res.status === OrderSbkStatus.Offline){
                    setStatus(res.status);
                }
                if (res.status === OrderSbkStatus.Unsynced){
                    messager.error('500');
                    setStatus(res.status);
                }
                if (res.status === OrderSbkStatus.Synced)
                    setStatus(res.status);
                if (res.status === OrderSbkStatus.Pending){
                    const secondsLeft = res.secondsLeft||1;
                    sbkSyncStatus((secondsLeft + 1) * 1000);
                    setSecondsLeft(secondsLeft);
                }
            });
        }, wait || 0);
    }, [props.id, messager]);

    useEffect(() => {
        if(status === OrderSbkStatus.Pending) sbkSyncStatus();
        const delivery = deliveryStore.get().find(x => x.id === props.id);
        if (delivery) delivery.sbk = status;
    }, [props.id, status, sbkSyncStatus]);

    return (
    <Box sx={props.sx}>
        {status === OrderSbkStatus.Offline && (
            <CloudOffIcon fontSize="large" color="error" titleAccess={t('storage.app-name')}/>
        )}
        {status === OrderSbkStatus.Unsynced && (
            <Button onClick={sbkSyncRequest}>
                {props.title || t('storage.app-name')}
                &nbsp;
                <CloudUploadIcon  titleAccess={t('storage.app-name')}/>
            </Button>
        )}
        {status === OrderSbkStatus.Synced && (
            <CloudDoneIcon fontSize="large" color="primary" titleAccess={t('storage.app-name')}/>
        )}
        {status === OrderSbkStatus.Pending && (<Box sx={{display: 'grid'}}>
            <CloudSyncIcon color="primary" sx={{margin: 'auto'}} titleAccess={t('storage.app-name')}/>
            {secondsLeft !== null && <SbkProgressBar total={secondsLeft}/>}
        </Box>)}
    </Box>
    )
}