import { Card, styled } from "@mui/material";

const CardListActionItem = styled(Card)(({theme}) => ({
    width: '100%',
    textAlign: 'inherit',
    padding: theme.spacing(2),
    display: 'flex',
    borderRadius: 0,
    boxShadow: 'none',
    ".MuiGrid-container": {
        borderBottom: 'none',
        marginBottom: 0
    },
    "&:hover": { 
        cursor: 'pointer',
        backgroundColor: theme.palette.Gray.light,
        ".MuiGrid-container": {
            backgroundColor: theme.palette.Gray.light
        },
    },
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    borderBottomColor: theme.palette.Gray.light
}));

export default CardListActionItem;