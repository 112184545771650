import { Box, Grid, Typography, styled } from "@mui/material";
import { IOrderItem } from "../../interfaces/IDelivery";
import { useTranslation } from "react-i18next";

const GridLeft = styled(Grid)(({ theme }) => ({
    display: 'flex',
    paddingLeft: theme.spacing(2)
}));
GridLeft.defaultProps = {
    item: true,xs: 7,sm: 7,md: 7,lg: 7,
};
const GridRight = styled(Grid)(({ theme }) => ({
    display: 'flex'
}));
GridRight.defaultProps = {
    item: true,xs: 5,sm: 5,md: 5,lg: 5,
};

const Item = ({text}: {text:string}) => (
    <Typography variant="body1" sx={{ marginTop: 'auto', marginBottom: 'auto'}}>
        {text}
    </Typography>
);
const RejectedItem = ({text, cancelled}: {text:string, cancelled:string}) => (
<Box sx={{display: 'inline-flex'}}>
    <Typography variant="body1" color='error' sx={{textDecoration: 'line-through'}}>
        {text}
    </Typography>
    &nbsp;
    <Typography variant="body1" color='error'>
        ({cancelled})
    </Typography>
</Box>
)

interface IProps {
    item: IOrderItem
}
export default function DeliveryItem({item}: IProps){
    const { t } = useTranslation();

    const name = item.product.name;
    const quantity = `${item.quantity} ${item.product.measurementUnit}`;

    return (
        <Grid container sx={(t) => ({ paddingTop: t.spacing(2), paddingBottom: t.spacing(1) })}>
            <GridLeft>
                {item.rejected 
                    ? <RejectedItem text={name} cancelled={t('common.cancelled')}/> 
                    : <Item text={name}/>
                }
            </GridLeft>
            <GridRight>
                {!item.rejected && <Item text={quantity}/>}
            </GridRight>
        </Grid>
    );
}