import { CircularProgress } from "@mui/material";
import { CSSProperties, useEffect, useRef } from "react";
import { useState } from "react";

var showLoading: () => void = () => {};
var hideLoading: () => void = () => {};

type Props = {
  show: boolean,
  style?: CSSProperties
}
export default function Loading(props: Props) {
  const isMounted = useRef(false);

  const [show, setShow] = useState<boolean>(false);
  
  useEffect(() => {
    setShow(props.show);
  }, [props.show])
  
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  showLoading = () => isMounted ? setShow(true) : {};
  hideLoading = () => isMounted ? setShow(false) : {};

  return (<> {
    show ?
      <div style={{
        ...{
          position: "absolute",
          top: 64,
          left: 0,
          zIndex: 999,
          width: "100%",
          height: "calc(100% - 64px)",
          display: "flex",
          justifyContent: "center"
        },
        ...props.style
      }}>
        <CircularProgress size={100} style={{position: "relative", top: "40%"}}/>
      </div>
    : <></>
  }</>)
}

export function useLoading() {
  return {
    show: () => showLoading(),
    hide: () => hideLoading()
  };
}