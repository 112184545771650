
import { Theme } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import HeaderActionButton from './HeaderActionButton';
import { CommonProps } from '@mui/material/OverridableComponent';
import { HeaderIconSize } from '../../consts/headerIconSize';
import { useNavigate } from 'react-router-dom';

interface IProps {
    onClick?: () => void;
}
export default function HeaderCloseButton(props: IProps & CommonProps) {
    const navigate = useNavigate();

    const {
        onClick,
        ...commonProps
    } = props;
    
    const defaultOnClick = () => {
        navigate(-1);
    };

    return (<>
        <HeaderActionButton {...commonProps} onClick={onClick || defaultOnClick}>
            <CloseIcon sx={(theme: Theme) => ({
                height: HeaderIconSize, 
                width: HeaderIconSize,
                color: theme.palette.Gray.main
            })}/>
        </HeaderActionButton>
    </>);
}