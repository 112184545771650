import { Badge } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import { useEffect, useState } from "react";
import { HeaderIconSize } from "../../consts/headerIconSize";
import BellIcon from "../icons/BellIcon";
import HeaderActionButton from "./HeaderActionButton";
import {  notificationStore } from "../../services/NotificationStore";
import { useNavigate } from "react-router-dom";
import { routes } from "../../consts/routes";

interface IProps {
}
export default function NotificationsButton(props: IProps & CommonProps) {
    const navigate = useNavigate();

    const {
        ...commonProps
    } = props;

    const [notificationCount, setNotificationCount] = useState<number>(0);

    useEffect(() => {
        setNotificationCount(notificationStore.get().length);
        notificationStore.subscribe('header-icon', () => {
            setNotificationCount(notificationStore.get().length);
        });

        return () => {
            notificationStore.unsubscribe('header-icon');
        };
    }, []);

    const onClick = () => {
        navigate(routes.Notifications);
    }

    return (
        <HeaderActionButton {...commonProps} onClick={onClick}>
            <Badge badgeContent={notificationCount} max={99} color="primary">
                <BellIcon style={{
                    height: HeaderIconSize, 
                    width: HeaderIconSize,
                }} />
            </Badge>
        </HeaderActionButton>
    );
}