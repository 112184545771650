import { UIInput } from "./payloads/ICalculatorInput";

export interface ICalculatorOutputResult {
    usedInput: UIInput;
    calculationDate: string;
    maxNutrients: INutrients;
    recommendations: string[];
    batches: {
        name: string;
        recommendations: {
            number: number;
            manureAmount: number;
            manureNutrients: INutrients;
            fertilizers: IFertilizer[];
            summaries: INutrientResult[];
            remarks: string[]
        }[];
        remarks: string[];
    }[];
}

export function mapResultToOutput(result: ICalculatorOutputResult): ICalculatorOutput{
    return {
        ...result,
        batches: result.batches
            .sort((a,b) => a.name.localeCompare(b.name))
            .map(distributor => ({
                name: distributor.name,
                recommendations: distributor.recommendations.map(harvest => ({
                    ...harvest,
                    listKey: `${distributor.name}-${harvest.number}`,
                    remarks: [...harvest.remarks, ...distributor.remarks]
                }))
            }))
    } as ICalculatorOutput;
}

export interface ICalculatorOutput {
    usedInput: UIInput;
    calculationDate: string;
    maxNutrients: INutrients;
    recommendations: string[];
    batches: IBatch[];
}

export interface INutrients {
    nitrogen: number;
    phosphorous: number;
    potassium: number;
}

export interface IBatch {
    name: string;
    recommendations: IRecommendation[];
}

export interface IRecommendation {
    listKey: string;

    number: number;
    manureAmount: number;
    manureNutrients: INutrients;
    fertilizers: IFertilizer[];
    summaries: INutrientResult[];
    remarks: string[];
}

export interface IFertilizer {
    ordinal: number;
    quantity: number;
    type: string;
    fertilizer: IFertilizerProduct;
    fertilizerNutrients: INutrients;
    manureNutrients: INutrients;
    totalNutrients: INutrients;
}

export interface INutrientResult extends INutrients {
    ordinal: number;
    name: string;
}

export interface IFertilizerProduct {
    name: string;
    code: string;
}