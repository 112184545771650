import { IBatchInput } from "../../../interfaces/payloads/ICalculatorInput";
import { IOption } from "../../../interfaces/IOption";
import { useCallback, useEffect, useState } from "react";
import OptionSelect from "../../inputs/OptionSelect";
import { ValidationState } from "../../../classes/ValidationState";
import { useMessager } from "../../../providers/MessagerProvider";
import SpacedInlineItems from "../../inputs/SpacedInlineItems";
import { useTranslation } from "react-i18next";

const resolveOptions = (props: IProps) => {
    let options = [...props.options];
    for(let value of props.cuts.map(c => c.input)){
        if(options.find(o => o === value) === undefined){
            options = [...options, value];
        }
    }
    return options.sort((a,b) => a-b);
}

interface IProps {
    vState: ValidationState;
    disable?: boolean[];
    maxAllowed: number;
    options: number[];
    cuts: IBatchInput[]
}
export default function ManureUsageInput(props: IProps){

    const msg = useMessager();

    const { t } = useTranslation();
    const [cuts, setCuts] = useState<IBatchInput[]>([]);
    const [disabledIndexes, setDisabledIndexes] = useState<boolean[]>(props.disable || [false, false, false]);
    const [options, setOptions] = useState<IOption<number>[]>(resolveOptions(props).map(v => ({ value: v, text: String(v) })));

    useEffect(() => {
        setCuts(props.cuts);
    }, [props.cuts]);

    const updateOptions = useCallback((newVal: number) => {
        setOptions(prev => {
            if(!prev.find(x => x.value === newVal)){
                return [{ value: newVal, text: String(newVal)}, ...prev].sort((a,b) => a.value-b.value);
            }
            return prev;
        });
    }, []);

    useEffect(() => {
        if(props.disable && disabledIndexes !== props.disable){
            setDisabledIndexes(props.disable);
            setCuts(prev => {
                for (let index = 0; index < disabledIndexes.length; index++) {
                    const disabled = disabledIndexes[index];
                    if(disabled) prev[index].input = 0;
                }
                return [...prev];
            });
        }
    }, [props.disable, disabledIndexes])

    const tooMuchManureWarning = useCallback(() => {
        msg.warning(t("calculator.error.too-much-manure").replace('{0}', String(props.maxAllowed)), 7000);
    }, [msg, t, props.maxAllowed]);

    useEffect(() => {
        let sum = props.cuts.map(c => c.input).reduce((a,b) => a+b);
        if (sum > props.maxAllowed){
            tooMuchManureWarning();
            for (let cut of props.cuts){
                cut.input = 0;
            }
            setCuts([...props.cuts]);
        }
    }, [props.cuts, tooMuchManureWarning, props.maxAllowed]);

    const updateQty = useCallback((i: number, opt: IOption<number>) => {
        const propsCut = props.cuts[i];
        propsCut.input = opt.value;
        let sum = props.cuts.map(c => c.input).reduce((a,b) => a+b);
        if (sum > props.maxAllowed){
            const sumOfOthers = props.cuts.filter((c,ci) => ci !== i).map(c => c.input).reduce((a,b) => a+b);
            propsCut.input = props.maxAllowed-sumOfOthers;
            updateOptions(propsCut.input);
            tooMuchManureWarning();
        }
        setCuts([...props.cuts]);
    }, [ 
        props.cuts,
        props.maxAllowed,
        tooMuchManureWarning,
        updateOptions
    ]);
    
    return <SpacedInlineItems id="manure-usage" items={cuts.map((cut, i) => {
        return {
            name: `${cut.number}.`,
            node: <OptionSelect 
                key={'manure-usage-' + cut.number}
                value={cut.input}
                vState={props.vState}
                required
                autoComplete="off"
                disabled={disabledIndexes[i]}
                options={cut.number < cuts.length ? options : options.filter(o => o.value <= 20)} 
                onChange={opt => {
                    updateQty(cut.number-1, opt);
                }}
            />
        };
    })}/>
}