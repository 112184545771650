import { Link, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import CardListItem from "../../components/CardListItem";
import GridSection from "../GridSection";
import PhoneIcon from "../icons/PhoneIcon";

export default function ContactsCard(){
    const { t } = useTranslation();
    const theme = useTheme();

    return (<CardListItem>
        <GridSection>
            <Typography variant="h6" fontWeight="bold">{t('settings.app-contacts')}</Typography> 
        </GridSection>
        <GridSection>
            <Link underline="none" href="https://www.yara.fi/tietosuoja/" target="_blank">{t('settings.data-security-contact')}</Link>
        </GridSection>
        <GridSection>
            <Link underline="none" href="https://www.yara.fi/asiakkuuspaallikot/" target="_blank">{t('settings.product-services-contact')}</Link>
        </GridSection>
        <GridSection sx={t => ({ marginBottom: t.spacing(1), display: 'inline-flex'})}>
            <Typography variant="subtitle1">{t('settings.farmer-support-contact')}</Typography>
            <PhoneIcon fill={theme.palette.primary.main} style={{
                marginTop: 'auto',
                marginBottom: 'auto',
                marginLeft: '10px',
                marginRight: '5px'
            }}/>
            <Link variant="subtitle1" underline="none" href="tel: 010-215-2621" target="_blank">010 215 2621</Link>
        </GridSection>
        <GridSection sx={t => ({marginBottom: t.spacing(1), lineHeight: 1})}>
            <Typography variant="caption">{t('settings.farmer-support-contact-add-info-1')}</Typography> 
            <br/>
            <Typography variant="caption">{t('settings.farmer-support-contact-add-info-2')}</Typography> 
        </GridSection>
    </CardListItem>);
}