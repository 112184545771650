import AppContainer from "../components/appContainers/AppContainer";
import DeliveryStoreList from "../components/delivery/DeliveryStoreList";


export default function Deliveries(){
    return (
        <AppContainer title='pages.orders-and-deliveries' style={{
            paddingTop: 0
        }}> 
            <DeliveryStoreList />
        </AppContainer>
    );
}