import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { IDelivery } from "../../interfaces/IDelivery";
import { deliveryStore } from "../../services/DeliveryStore";
import CardListActionItem from "../CardListActionItem";
import DeliveryHeader from "./DeliveryHeader";
import NoDeliveries from "./NoDeliveries";
import DeliveryStoreFetchButton from "../buttons/DeliveryStoreFetchButton";

interface IProps {
    filter?: (x: IDelivery) => boolean;
    onClick: (x: IDelivery) => void;
}
export default function DeliverySelectList(props: IProps) {

    const [options, setOptions] = useState<IDelivery[]>([]);

    const update = useCallback(() => {
        setOptions(deliveryStore.get().filter(props.filter ?? (() => true)));
    }, [props.filter]);

    useEffect(() => {
        update();
    }, [update]);

    return (
    <Grid container>
        <NoDeliveries count={options.length} content="delivery.no-delivered-deliveries"/>
        {options.map(delivery => (
            <CardListActionItem key={`delivery-select-item-${delivery.id}`} onClick={() => props.onClick(delivery)}>
                <DeliveryHeader {...delivery}/>
            </CardListActionItem>
        ))}
        <DeliveryStoreFetchButton onFetch={update}/>
    </Grid>
    );
}