export var roundTo = function(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
};
export var roundUp = function(num: number, places: number) {
    const factor = 10 ** places;
    return Math.ceil(num * factor) / factor;
};

export var decimalFormat = (num: number, places?: number) => roundTo(num, places ?? 0);
export var decimalFormatUp = (num: number, places?: number) => roundUp(num, places ?? 0);