import { styled, Switch } from "@mui/material";


export const LargeSwitch = styled(Switch)(({ theme }) => ({
    width: 68,
    height: 30,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      margin: 0,
      padding: 0,
      color: "#5a5a5a",
      transform: "translateX(0px)",
      "&.Mui-checked": {
        color: theme.palette.primary.main,
        transform: "translateX(39px)",
        "&.Mui-disabled": {
          color: theme.palette.primary.light,
        }
      },
      "&.Mui-disabled": {
        color: theme.palette.Gray.light
      }
    },
    "& .MuiSwitch-thumb": {
      width: 29,
      height: 29,
    },
    "& .MuiSwitch-track": {
      borderRadius: 20
    },
  }));