import { useCallback } from "react";
import IFrameLoader from "../IFrameLoader";

const surveyCompleteMessage = 'INsurveyClose';
const surveyErrorMessage = 'INsurveyError';

interface IProps {
    source?: string;
    onComplete: () => void;
    onError: () => void;
}
export default function SurveyFrameLoader({ source, onComplete, onError }: IProps) {

    const onMessage = useCallback((e: MessageEvent) => {
        if(e.data === surveyErrorMessage) onError();
        if(e.data === surveyCompleteMessage) onComplete();
    }, [onComplete, onError]);

    return (
        <div style={{
            position: 'relative',
            overflow: 'hidden',
            height: '100%',
            width: '100%'
        }}>
            {source && <IFrameLoader
                src={source} 
                title="survey iframe"
                width="100%" 
                height="100%"
                onMessage={onMessage}
                style={{
                    border: 'none'
                }}
                sandbox={"allow-scripts allow-same-origin"}
            />}
        </div>
    );
}