import { IUserContactInfo } from "../interfaces/IUserContactInfo";
import { IUserCustomer } from "../interfaces/IUserCustomer";
import { yaraApi } from "./YaraApi";

class UserService {

    private contactInfo: IUserContactInfo | null = null;
    private customers: IUserCustomer[] | null = null;

    getUserContactInfo(): Promise<IUserContactInfo> {
        if(this.contactInfo !== null)
            return Promise.resolve(this.contactInfo);
        return yaraApi.getUserContactInfo()
            .then(ci => {
                this.contactInfo = ci;
                return ci;
            });
    }

    getUserCustomers(): Promise<IUserCustomer[]> {
        if(this.customers !== null)
            return Promise.resolve(this.customers);
        return yaraApi.getUserCustomers()
            .then(res => {
                this.customers = res;
                return res;
            });
    }
}

export const userService = new UserService();