import { Grid, Typography } from "@mui/material";
import CardListItem from "../../CardListItem";
import GridRow from "../../GridRow";
import ExtraFertilization from "../../icons/ExtraFertilization";
import { useTranslation } from "react-i18next";
import InitialFertilization from "../../icons/InitialFertilization";

const left = 2;
const right = 12 - left;

export default function GrainOutputLegend(){
    const { t } = useTranslation();

    return (<CardListItem>
        <GridRow sx={{width: '100%', display: 'inline-flex'}}>
            <Grid item xs={left} sm={left} md={left} lg={left}>
                <InitialFertilization height={35}/>
            </Grid>
            <Grid item xs={right} sm={right} md={right} lg={right}>
                <Typography sx={{lineHeight: 2}}>{t('calculator.legend.initial')}</Typography>
            </Grid>
        </GridRow>
        <GridRow sx={{width: '100%', display: 'inline-flex'}}>
            <Grid item xs={left} sm={left} md={left} lg={left}>
                <ExtraFertilization height={35}/>
            </Grid>
            <Grid item xs={right} sm={right} md={right} lg={right}>
                <Typography sx={{lineHeight: 2}}>{t('calculator.legend.extra')}</Typography>
            </Grid>
        </GridRow>
    </CardListItem>);
}