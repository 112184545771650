import AppContainer from "../components/appContainers/AppContainer";
import AutoInventoryCard from "../components/settings-cards/AutoInventoryCard";
import ContactsCard from "../components/settings-cards/ContactsCard";
import LanguageSelectCard from "../components/settings-cards/LanguageSelectCard";
import TermsCard from "../components/settings-cards/TermsCard";
import UserCard from "../components/settings-cards/UserCard";
import { IUserInfo } from "../interfaces/IUserInfo";

interface IProps {
    user: IUserInfo;
}
export default function Settings(props: IProps){
    return (
    <AppContainer title='pages.settings'>
        <UserCard user={props.user}/>
        <LanguageSelectCard user={props.user}/>
        <AutoInventoryCard user={props.user}/>
        <ContactsCard />
        <TermsCard />
    </AppContainer>
    );
}