import { Button } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IOption } from "../interfaces/IOption";
import { IUserInfo } from "../interfaces/IUserInfo";
import { paramService } from "../services/ParamService";
import { yaraApi } from "../services/YaraApi";


interface IProps {
    user: IUserInfo;
    onChange?: (lang: string) => void;
    setLoading?: (loading: boolean) => void;
}
export default function LanguageSelect(props: IProps & CommonProps){
    const { t, i18n } = useTranslation();
    const {
        user,
        setLoading,
        onChange,
        ...commonProps
    } = props;
    const langs = paramService.supportedCultures;
    const loading = setLoading || (() => {});

    const [selectedLang, setSelectedLang] = useState<IOption<string> | undefined>(langs.find(x => x.value === user.settings.culture));

    const changeLanguage = (lang: IOption<string>) => {
        loading(true);
        yaraApi.setCulture(lang.value)
            .then(ok => {
                if(ok) {
                    user.settings.culture = lang.value;
                    i18n.changeLanguage(lang.value);
                    setSelectedLang(lang);
                    if(onChange)
                        onChange(lang.value);
                }
            })
            .finally(() => loading(false));
    }

    return (<div {...commonProps}>
        {langs.map(lang => (
            <Button 
                key={`select-lang-${lang.value}`}
                sx={(theme) => ({
                    minWidth: '45px',
                    width: '45px',
                    borderRadius: '25px',
                    marginRight: '15px',
                    padding: '3px',
                })} 
                color={lang.value === selectedLang?.value ? 'primary' : 'secondary'}
                onClick={() => changeLanguage(lang)}
            >
                {t(lang.text)}
            </Button>
        ))}
    </div>);
}