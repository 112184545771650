import { MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOption } from "../../../interfaces/IOption";
import { userService } from "../../../services/UserService";
import GridSection from "../../GridSection";
import Subtitle2 from "../../labels/Subtitle2";
import { IIssueReport } from "../DeliveryIssueReport";

interface IProps {
    report: IIssueReport
}
export default function ContactMethod({ report }: IProps) {
    const { t } = useTranslation();

    const [contactMethods] = useState<IOption<string>[]>([
        { value: '1', text: t('contact-method.email') },
        { value: '2', text: t('contact-method.phone-nr') }
    ]);
    const [contactDefaults, setContactDefaults] = useState<IOption<string>[]>([]);

    const [selectedContactMethod, setSelectedContactMethod] = useState<string>(contactMethods[0].value);
    const [contactInfo, setContactInfo] = useState<string>('');

    useEffect(() => {
        userService.getUserContactInfo()
            .then((ci) => {
                const defaults = [
                    { value: contactMethods[0].value, text: ci.email },
                    { value: contactMethods[1].value, text: ci.phoneNr }
                ];
                setContactDefaults(defaults);
            });
    }, [t, contactMethods]);

    useEffect(() => {
        if (contactDefaults.length === 0) return;

        const defaultContactInfo = contactDefaults.find(cd => cd.value === selectedContactMethod)?.text;
        if (defaultContactInfo)
            setContactInfo(defaultContactInfo);

    }, [contactDefaults, selectedContactMethod]);

    useEffect(() => {
        report.contactMethod = Number(selectedContactMethod);
        report.contactInfo = contactInfo;
    }, [contactInfo, selectedContactMethod, report])

    return (
        <GridSection>
            <Subtitle2>
                {t('delivery.desired-contact-method')}
            </Subtitle2>
            <Select
                id="contact-method"
                value={selectedContactMethod || contactMethods[0].value}
                placeholder={t('common.select')}
                onChange={(e) => setSelectedContactMethod(e.target.value)}
                sx={(t) => ({ marginBottom: t.spacing(2) })}
            >
                {contactMethods.map(x => (<MenuItem key={`contact-method-${x.value}`} value={x.value}>{x.text}</MenuItem>))}
            </Select>
            <TextField 
                value={contactInfo}
                onChange={e => setContactInfo(e.target.value)}
            />
        </GridSection>
    );
}