import { useEffect, useState } from "react";
import { fertilizerApi } from "../../../services/FertilizerApi";
import { CultivatedPlant } from "../../../enums/calculator/CultivatedPlant";
import GridRow from "../../GridRow";
import { Typography } from "@mui/material";
import OptionSelect from "../../inputs/OptionSelect";
import { IOption } from "../../../interfaces/IOption";
import { ValidationState } from "../../../classes/ValidationState";
import { useTranslation } from "react-i18next";

function Title(props: { text: string }){
    return <Typography fontWeight={"bold"} sx={{ marginTop: 'auto', marginBottom: 'auto'}}>
        {props.text}
    </Typography>
}

function Row({ children, spacing: padding }: { children: any, spacing?: number}) {
    return <GridRow sx={(t) => ({ display: 'inline-flex', width: '100%', marginTop: t.spacing(padding || 1) })}>{children}</GridRow>
};

interface IProps {
    vState: ValidationState,
    value: CultivatedPlant;
    onChange: (plant: CultivatedPlant) => void;
}
export default function PlantSelect(props: IProps){

    const { t } = useTranslation();

    const [plants, setPlants] = useState<IOption<number>[]>();

    useEffect(() => {
        if(!plants){
            fertilizerApi.get<IOption<CultivatedPlant>[]>('calculator/plants').then(plants => {
                setPlants(
                    plants
                    .filter(x => x.value !== 0)
                    .sort((a,b) => t(a.text).localeCompare(t(b.text)))
                );
            });
        }
    }, [plants]);

    return plants ? (<>
        <Row><Title text={t('calculator.select-plant')}/></Row>
        <Row>
            <OptionSelect 
                id={'cultivatedPlant'}
                value={props.value}
                required
                vState={props.vState}
                vRules={[
                    x => x !== 0
                ]}
                options={plants} 
                onChange={opt => {
                    props.onChange(opt.value);
                }}
            />
        </Row>
    </>) : (<></>);
}