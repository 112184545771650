export class LocalStore {

    private _tempData: any;

    public setTemporary = <T>(data: T) => {
        this._tempData = data;
    }

    public getTemporary = <T>(clear?: boolean): T|undefined => {
        if(this._tempData !== undefined)
        {
            const returnData = this._tempData;
            if (clear) this.clearTemporary();
            return returnData;
        }
        return undefined;
    }

    public clearTemporary = () => {
        this._tempData = undefined;
    }
}

export const localStore = new LocalStore();