import axios from 'axios';
import { UserManagerInstance } from "../users/userManagerProvider";
import { useEffect } from 'react';

const defaultHeaders = {
    'Content-Type': 'application/json'
};

// Adds bearer token to requests.
axios.interceptors.request.use(config => {
    return UserManagerInstance.getUser().then(user => {
        if (!config.headers) config.headers = defaultHeaders;
        else config.headers = Object.assign({}, defaultHeaders, config.headers)
        if (user !== null)
            config.headers.Authorization = `Bearer ${user.access_token}`;
        return config;
    });
})

export default function AxiosInterceptors() {
    useEffect(() => {
        
    });
    return <></>;
}