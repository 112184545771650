import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LargeSwitch } from "../LargeSwitch";

interface IProps {
    title: string;
    content: string;
    disabled: boolean;
    defaultChecked: boolean;
    onCheck: (checked: boolean) => void;
}
export default function TermsOption(props: IProps) {
    const { t } = useTranslation();

    const [checked, setChecked] = useState<boolean>(props.defaultChecked);

    const onCheck = (checked: boolean) => {
        setChecked(checked);
        props.onCheck(checked);
    }

    return (<div style={{ marginTop: '20px', marginBottom: '20px'}}>
    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ 
        display: 'flex',
        justifyContent: 'space-between'
    }}>
        <Typography 
            variant='subtitle2' 
            sx={(theme) => ({
                fontWeight: 'bold'
            })}
        >
            {t(props.title)}
        </Typography>
        <LargeSwitch 
            sx={{
                marginTop: 'auto',
                marginBottom: 'auto'
            }} 
            disabled={props.disabled}
            checked={checked}
            onChange={v => onCheck(v.target.checked)}
        />
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="caption">{t(props.content)}</Typography>
    </Grid>
    </div>);
}