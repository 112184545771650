import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography, styled } from "@mui/material";
import SmallSack from '../../../content/productBatch/small_sack.png';
import BigSack from '../../../content/productBatch/big_sack.png';
import { useTranslation } from "react-i18next";

const BatchAccordion = styled(Accordion)(({ theme }) => ({
    width: '100%',
    minHeight: '20px',
    border: `none`,
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
    '&:before': {
      display: 'none',
    },
}));
BatchAccordion.defaultProps = {
    disableGutters: true,
    elevation: 0,
    square: true
};

const Ul = styled('ul')(({ theme }) => ({
    paddingLeft: theme.spacing(3)
}));

export default function ProductBatchHelp(){

    const { t } = useTranslation();

    return (
    <Grid container>
        <BatchAccordion>
            <AccordionSummary
                id="batch-nr-help"
                sx={{
                    padding: 0,
                    minHeight: '20px',
                    '.MuiAccordionSummary-content': {
                        margin: 0
                    }
                }}
            >
                <Typography color={'primary'}>{t('batch-help.title')}</Typography>
            </AccordionSummary>
            <AccordionDetails
                sx={{
                    paddingLeft: 0
                }}
            >
                <Typography fontWeight={'bold'}>
                    {t('batch-help.list-title')}
                </Typography>
                <Ul>
                    <li>
                        <Typography>
                            {t('batch-help.help-1-part-1')}
                            <b> {t('batch-help.help-1-part-2')}</b>
                        </Typography>
                        <Box
                            component={'img'}
                            src={BigSack}
                            sx={{
                                width: '100%'
                            }}
                        />
                    </li>
                    <li>
                        <Typography>
                            {t('batch-help.help-2')}
                        </Typography>
                        <Box
                            component={'img'}
                            src={SmallSack}
                            sx={{
                                width: '100%'
                            }}
                        />
                    </li>
                    <li>
                        <Typography>
                            {t('batch-help.help-3')}
                        </Typography>
                    </li>
                </Ul>
            </AccordionDetails>
        </BatchAccordion>
    </Grid>
    );
}