import { CSSProperties, DetailedHTMLProps, IframeHTMLAttributes, useEffect, useRef, useState } from "react";
import Loading from "./Loading";

interface IProps {
    onMessage: (e: MessageEvent<any>) => void
}
type Props = IProps & DetailedHTMLProps<IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement>;
export default function IFrameLoader({ onMessage, ...props }: Props) {
    
    const [origin] = useState<string|null>(props.src ? new URL(props.src).origin : null);
    const [loading, setLoading] = useState<boolean>(true);
    const contentRef = useRef<HTMLIFrameElement|null>(null);

    useEffect(() => {
        const handler = (e: MessageEvent<any>) => {
            if(origin !== null && e.origin === origin)
                onMessage(e);
        }
        window.addEventListener("message", handler);

        return () => window.removeEventListener("message", handler);
    }, [origin, onMessage]);

    const onLoad = (e: any) => {
        setLoading(false);
        if(props.onLoad) props.onLoad(e);
    }

    const defaultStyle: CSSProperties = {
        position: 'absolute',
        zIndex: 10
    }

    const mergedProps = {
        ...props,
        ...{ 
            style: {...defaultStyle, ...props.style} 
        },
    };

    return (<>
        <Loading show={loading} style={{
            zIndex: 0
        }}/>
        <iframe title="iframe" ref={contentRef} {...mergedProps} onLoad={onLoad} />
    </>);
}