import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IUserInfo } from "../../interfaces/IUserInfo";
import LanguageSelect from "../LanguageSelect";


interface IProps {
    user: IUserInfo;
    setLoading: (loading: boolean) => void;
    setCulture: (culture: string) => void;
}
export default function TermsUnacceptedHeader(props: IProps){
    const { t } = useTranslation();

    return (<>
    <Grid key="language-select" item xs={12} sm={12} md={12} lg={12}>
        <LanguageSelect 
            style={{
                float: 'right'
            }} 
            user={props.user} 
            setLoading={props.setLoading}
            onChange={props.setCulture}
        />
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant='subtitle2' sx={(theme) => ({
            color: theme.palette.primary.main,
            fontWeight: 'bold'
        })}>
            {t('pages.terms-and-conditions')}
        </Typography>
    </Grid>
    </>);
}