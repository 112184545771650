import { styled, Typography } from "@mui/material";
import { INavItem, IconStyle } from "../interfaces/INavItem";

const IconContainer = styled('div')(({theme}) => ({
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const NavContainer = styled('div')(({theme}) => ({
    height: '40px',
    display: 'inline-flex'
}));

interface IProps {
    variant?: 'h6' | 'subtitle2',
    color?: string;
    navItem: INavItem;
}
export default function NavItem({ navItem, color, variant }: IProps){

    const fillColor = color ? color : 'currentColor';

    return (
    <NavContainer>
        <IconContainer>
            <navItem.icon fill={fillColor} style={IconStyle(navItem)}/>
        </IconContainer>
        <Typography color={fillColor} variant={variant || 'h6'} sx={{
            marginTop: 'auto',
            marginBottom: 'auto'
        }}>
            {navItem.title}
        </Typography>
    </NavContainer>
    );
}