import { Typography } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";

interface IProps {
    children?: React.ReactNode;
}
export default function Subtitle2(props: IProps & CommonProps){
    return (
        <Typography variant="subtitle2" fontWeight="bold" {...props} sx={(t) => ({ marginBottom: t.spacing(1) })}>
            {props.children}
        </Typography>
    );
}