import { useEffect, useState } from "react";
import IFrameLoader from "../components/IFrameLoader";
import AppContainer from "../components/appContainers/AppContainer";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IUserInfo } from "../interfaces/IUserInfo";
import { yaraApi } from "../services/YaraApi";

interface IProps {
    user: IUserInfo
}
export default function FertilizerGuide({ user }: IProps){

    const { t } = useTranslation();

    const [url, setUrl] = useState<string|undefined>();

    useEffect(() => {
        yaraApi.getEBook('fertilizer-guide').then(setUrl);
    }, []);

    return (
    <AppContainer title={t('pages.fertilizer-guide')}>
        <Grid container sx={(t) => ({
            padding: 0, 
            display: 'flex',
            height: '100%'
        })}>
            <div style={{
                padding: 0, 
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
                width: '100%'
            }}>
                <IFrameLoader 
                    src={url} 
                    allowFullScreen
                    title="survey iframe"
                    width="100%" 
                    height="100%"
                    onMessage={() => {}}
                    style={{
                        border: 'none'
                    }}
                    sandbox={"allow-scripts allow-same-origin allow-popups"}
                />
            </div>
        </Grid>
    </AppContainer>
    );
}