import { CommonProps } from "@mui/material/OverridableComponent";
import { ReactComponent as Q1 } from '../../content/svgs/quality/1.svg';
import { ReactComponent as Q2 } from '../../content/svgs/quality/3.svg';
import { ReactComponent as Q3 } from '../../content/svgs/quality/2.svg';
import { ReactComponent as Q4 } from '../../content/svgs/quality/4.svg';
import { ReactComponent as Q5 } from '../../content/svgs/quality/5.svg';
import { ReactComponent as Q6 } from '../../content/svgs/quality/6.svg';
import { ReactComponent as Q7 } from '../../content/svgs/quality/7.svg';

type Props = CommonProps & React.SVGProps<SVGSVGElement>;
type Icon = (props: Props) => JSX.Element;

export function Quality1(props: Props) { return (<Q1{...props} />); }
export function Quality2(props: Props) { return (<Q2{...props} />); }
export function Quality3(props: Props) { return (<Q3{...props} />); }
export function Quality4(props: Props) { return (<Q4{...props} />); }
export function Quality5(props: Props) { return (<Q5{...props} />); }
export function Quality6(props: Props) { return (<Q6{...props} />); }
export function Quality7(props: Props) { return (<Q7{...props} />); }

export const QualityClassIcons: {[q: number]: Icon} = {
    0: () => <></>,
    1: Quality1,
    2: Quality2,
    3: Quality3,
    4: Quality4,
    5: Quality5,
    6: Quality6,
    7: Quality7,
};