import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContainer from "../../components/appContainers/AppContainer";
import AppContainerEmpty from "../../components/appContainers/AppContainerEmpty";
import HeaderCloseButton from "../../components/buttons/HeaderCloseButton";
import Delivery from "../../components/delivery/Delivery";
import DeliveryTimeRequestComponent from "../../components/delivery/DeliveryTimeRequest";
import { IDelivery } from "../../interfaces/IDelivery";
import { localStore } from "../../services/LocalStore";
import { features } from "../../consts/features";

interface IProps {
}
export default features.empty('DeliveryTimeRequest') || function DeliveryTimeRequest(props: IProps){
    const theme = useTheme();
    const navigate = useNavigate();

    const [selectedDelivery] = useState<IDelivery|undefined>(localStore.getTemporary<IDelivery>());

    useEffect(() => {
        if (!selectedDelivery){
            navigate(-1);
        }
    }, [selectedDelivery, navigate]);
    
    return selectedDelivery ? (
        <AppContainer title='delivery.delivery-time-request' 
            actionButton={HeaderCloseButton}
            style={{
                backgroundColor: theme.palette.Gray.light,
                paddingTop: 0
            }}
        >
            <Delivery data={selectedDelivery}>
                <DeliveryTimeRequestComponent data={selectedDelivery} afterSave={() => navigate(-1)} style={{paddingTop: '20px'}} />
            </Delivery>
        </AppContainer>
    ) : (<AppContainerEmpty />);
}