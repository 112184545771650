import { IconButton, SxProps, Theme } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import CameraIcon from "../icons/CameraIcon";

interface IProps {
    id: string;
    onLoad: (file: File) => void;
    sx?: SxProps<Theme>;
}
export default function AttachImageButton(props: IProps & CommonProps) {
    const inputId = `icon-button-photo-${props.id}`;

    const {
        id,
        onLoad,
        sx,
        ...commonProps
    } = props;

    return (<>
        <input
            accept="image/*"
            id={inputId}
            type="file"
            onChange={(e) => {
                if(!e.target.files || e.target.files.length === 0) return;
                onLoad(e.target.files[0]);
                (e as any).target.value = null;
            }}
            hidden
        />
        <label htmlFor={inputId}>
            <IconButton color="primary" component="span" style={commonProps.style} sx={sx}>
                <CameraIcon style={{
                    height: '100%',
                    width: '100%'
                }}/>
            </IconButton>
        </label>
    </>);
}