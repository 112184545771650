import { Close, ContentPaste } from "@mui/icons-material";
import { Button, Grid, SxProps, Theme, styled } from "@mui/material";

const NumpadRow = styled('div')(t => ({
    display: 'inline-flex',
    width: '100%'
}));
const NumpadBtn = styled(Button)(({ theme }) => ({
    margin: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
}));

interface IProps {
    onInput: (value: number) => void;
    onPaste?: (e: any) => void;
    onClear?: () => void;
    sx?: SxProps<Theme>;
}
export default function Numpad(props: IProps) {

    const onInput = (value: number) => {
        props.onInput(value);
    }

    const Num = (value: number) => (<NumpadBtn onClick={() => onInput(value)}>{value}</NumpadBtn>);

    return (<Grid container sx={props.sx}>
        <NumpadRow>
            {Num(1)}
            {Num(2)}
            {Num(3)}
        </NumpadRow>
        <NumpadRow>
            {Num(4)}
            {Num(5)}
            {Num(6)}
        </NumpadRow>
        <NumpadRow>
            {Num(7)}
            {Num(8)}
            {Num(9)}
        </NumpadRow>
        <NumpadRow>
            <NumpadBtn color="secondary" onClick={props.onPaste}>
                <ContentPaste/>
            </NumpadBtn>
            {Num(0)}
            <NumpadBtn color="secondary" onClick={props.onClear}>
                <Close/>
            </NumpadBtn>
        </NumpadRow>
    </Grid>);
}