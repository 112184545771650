import { Box, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UIDateFormat } from "../../consts/uiDateFormat";
import EditIcon from "../icons/EditIcon";
import DeliveryArrivalText from "./DeliveryArrivalText";
import DeliveryArrivalDate from "./DeliveryArrivalDate";
import GridRow from "../GridRow";
import { OrderSbkStatus } from "../../enums/OrderSbkStatus";

import { access } from "../../services/AccessService";
import SbkSyncButton from "../buttons/SbkSyncButton";

export interface IDeliveryHeader {
    id: string;
    nr: string;
    orderDate: string;
    estimatedDelivery?: string;
    deliveredDate?: string;
    completed: boolean;
    sbk: OrderSbkStatus|null;
    sync?: true;
    onDeliverySelect?: () => void;
}
export default function DeliveryHeader(props: IDeliveryHeader){
    const { t } = useTranslation();

    const [sbkAllowed] = useState<boolean>(access.sbkAccess);
    const [orderDate] = useState<moment.Moment>(moment(props.orderDate));

    const [gridSizeLeft] = useState<number>(props.onDeliverySelect ? 10 : 12);
    const [gridSizeRight] = useState<number>(12-gridSizeLeft);

    return (<Grid container>
        <Grid item xs={gridSizeLeft} sm={gridSizeLeft} md={gridSizeLeft} lg={gridSizeLeft}>
            <Box sx={{ display: 'inline-flex', width: '100%'}}>
                <Typography variant="h6" fontWeight="bold">
                    {t('delivery.order')} {orderDate && (orderDate.format(UIDateFormat))}
                </Typography>
                {sbkAllowed && props.sync && <SbkSyncButton id={props.id} status={props.sbk} sx={{ marginLeft: 'auto'}}/>}
            </Box>
            <GridRow>
                <DeliveryArrivalText {...props} fontWeight={"bold"}/>
                <DeliveryArrivalDate {...props}/>
            </GridRow>
            <Typography variant="body1">{`#${props.nr}`}</Typography>
        </Grid>
        {props.onDeliverySelect && (
        <Grid item xs={gridSizeRight} sm={gridSizeRight} md={gridSizeRight} lg={gridSizeRight} sx={{display: 'flex', justifyContent: 'end'}}>
            <IconButton onClick={props.onDeliverySelect} sx={{
                width: "42px", 
                height: "42px"
            }}>
                <EditIcon width='100%' height='100%' />
            </IconButton>
        </Grid>
        )}
    </Grid>);
}