import RegexField, { RegexFieldProps } from "./RegexField";
import { ChangeEvent, useEffect, useState } from "react";
import { IVStateProps } from "../../classes/ValidationState";

interface IProps {
    id?: string;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement|HTMLInputElement>) => void
}
type DecimalFieldProps = Omit<RegexFieldProps, 'regex'> & IProps & IVStateProps<number>;
export default function DecimalField(props: DecimalFieldProps){
    const {
        vState,
        vRules,
        onValidate,
        ...componentProps
    } = props;
    
    const [error, setError] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [validatedValue, setValidatedValue] = useState<"" | number | undefined>(undefined);

    componentProps.onChange = (e) => {
        if(vState){
            const val = Number(e.target.value);
            vState.autoInnerValidate(
                componentProps,
                vRules,
                val,
                setError
            );
        }
        if(props.onChange) props.onChange(e);
    }
    
    useEffect(() => {
        return () => {
            if(vState)
                vState.dismount(componentProps);
        }
        // eslint-disable-next-line
    }, []);
    
    useEffect(() => {
        if (!initialized && vState){
            vState.autoInit(
                componentProps,
                vRules,
                setError
            );
            setInitialized(true);
        }
    }, [
        componentProps,
        initialized,
        vState,
        vRules
    ]);

    useEffect(() => {
        if (componentProps.value !== validatedValue && vState){
            vState.autoOuterValidate(
                componentProps,
                vRules,
                onValidate,
                setError
            );
            setValidatedValue(Number(componentProps.value));
        }
    }, [
        componentProps,
        componentProps.value,
        validatedValue,
        vState,
        vRules,
        onValidate
    ]);

    if(componentProps.error === undefined)
        componentProps.error = error;

    const {
        onChange,
        ...inputProps
    } = componentProps;

    return <RegexField 
        type="decimal" 
        regex={/^[+-]?((\d+((\.|,)\d*)?)|((.|,)\d+))$/}
        onChange={(e) => {
            e.target.value = e.target.value.replace(',', '.');
            onChange && onChange(e);
        }}
        {...inputProps}
    />
}