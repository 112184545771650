import { DeliveryStatus } from "../enums/DeliveryStatus";
import { OrderSbkStatus } from "../enums/OrderSbkStatus";
import { IDelivery } from "../interfaces/IDelivery";
import { yaraApi } from "./YaraApi";

export const deliverySetSize = 10;

export type DeliveryFilter = (x: IDelivery) => boolean;

export interface IDeliveryFilters {
    default: DeliveryFilter;
    delivered: DeliveryFilter;
    unsynced: DeliveryFilter;
}

export const filters: IDeliveryFilters = {
    default: x => true,
    delivered: x => x.status === DeliveryStatus.Delivered,
    unsynced: x => x.sbk === OrderSbkStatus.Unsynced
};

export class DeliveryStore {

    private totalCount: number|null = null;
    private deliveries: IDelivery[] = [];

    get = (): IDelivery[] => {
        return this.deliveries;
    }

    getLatest = (filter?: DeliveryFilter): IDelivery | null => {
        const filtered = this.deliveries.filter(filter || filters.default);
        if(filtered.length === 0) return null;
        return filtered[0];
    }

    load = (): Promise<IDelivery[]> => {
        const skip = 0;
        const take = deliverySetSize;

        return yaraApi.getDeliveries(skip, take).then((res) => {
            this.deliveries = res;
            return this.deliveries;
        });
    }

    refresh = (): Promise<IDelivery[]> => {
        const skip = 0;
        const take = this.deliveries.length;

        return yaraApi.getDeliveries(skip, take).then((res) => {
            this.deliveries = res;
            return this.deliveries;
        });
    }

    loadNext = (): Promise<IDelivery[]> => {
        const skip = this.deliveries.length-1;
        const take = deliverySetSize+1;

        const set:IDelivery[] = [];

        return yaraApi.getDeliveries(skip, take).then((res) => {
            for (let delivery of res){
                if(this.deliveries.find(d => d.id === delivery.id)) 
                    continue;
                set.push(delivery);
                this.deliveries.push(delivery);
            }
            return set;
        });
    }

    count = (): Promise<number> => {
        if (this.totalCount == null)
            return yaraApi.getDeliveriesCount().then(count => {
                this.totalCount = count;
                return count;
            });
        return Promise.resolve(this.totalCount);
    }

    any = (filter?: DeliveryFilter): boolean => {
        return this.deliveries.filter(filter || filters.default).length > 0;
    }
}

export const deliveryStore = new DeliveryStore();