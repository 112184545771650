import { useEffect, useState } from "react";
import { IDelivery } from "../../interfaces/IDelivery";
import { deliveryStore } from "../../services/DeliveryStore";
import { Box, } from "@mui/material";
import DeliveryList from "./DeliveryList";
import NoDeliveries from "./NoDeliveries";

import DeliveryStoreFetchButton from "../buttons/DeliveryStoreFetchButton";

export default function DeliveryStoreList() {
    const [deliveries, setDeliveries] = useState<IDelivery[]>([]);

    const update = () => {
        setDeliveries([...deliveryStore.get()]);
    }

    useEffect(() => {
        update();
    }, []);

    return (<Box>
        <NoDeliveries />
        <DeliveryList deliveries={deliveries}/>
        <DeliveryStoreFetchButton onFetch={update}/>
    </Box>);
}