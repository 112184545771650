import { Grid } from "@mui/material";
import { useState } from "react";
import { IParagraph } from "../../interfaces/ITerms";
import Paragraph from "../Paragraph";

interface IProps {
    paragraphs?: IParagraph[];
}
export default function TermsContent(props: IProps){
    const [paragraphs] = useState<IParagraph[]>(props.paragraphs || []);

    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            {paragraphs.map((paragraph,i) => (<Paragraph key={`p-${i}`} title={paragraph.title} content={paragraph.content} />))}
        </Grid>
    );
}