import { Grid } from "@mui/material";
import { IDelivery } from "../../interfaces/IDelivery";
import DeliveryContent from "./DeliveryContent";
import DeliveryHeader from "./DeliveryHeader";


interface IProps {
    id?: string;
    data: IDelivery;
    children?: React.ReactNode;
    noContent?: boolean;
    isLast?: boolean;
    sync?: true;
    onDeliverySelect?: () => void;
}
export default function Delivery(props: IProps){
    return (<Grid container sx={(theme) => ({
        borderBottomStyle: props.isLast ? 'none' : 'solid',
        borderBottomWidth: '2px',
        borderBottomColor: theme.palette.Gray.light,
        marginBottom: '3px',
        marginTop: 0,
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        width: '100%'
    })}>
        <Grid item xs={0} sm={0} md={3} lg={3}>
            <span id={props.id}></span>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
            <DeliveryHeader {...props.data} onDeliverySelect={props.onDeliverySelect} sync={props.sync}/>
            {!props.noContent && (<DeliveryContent {...props.data}/>)}
            {props.children}
        </Grid>
        <Grid item xs={0} sm={0} md={3} lg={3}>
        </Grid>
    </Grid>);
}