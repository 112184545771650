import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, TypographyProps } from "@mui/material";

interface IProps  {
    completed: boolean;
    deliveredDate?: string;
    estimatedDelivery?: string;
}
export default function DeliveryArrivalText(props: IProps & TypographyProps){
    const { t } = useTranslation();

    const {
        completed,
        deliveredDate,
        estimatedDelivery,

        fontWeight
    } = props;

    const [text] = useState<string>((
        completed ? t('delivery.delivered') :
        deliveredDate ? t('delivery.departed-from-factory') :
        estimatedDelivery ? t('delivery.estimated-factory-departure') :
        ''
    ) || '');
    
    return text !== '' 
    ? (
        <Typography variant="subtitle1" fontWeight={fontWeight}>
            {text}
        </Typography>
    ) 
    : (<></>);
}