import { IconButton } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";


interface IProps {
    onClick: () => void;
    children: React.ReactNode;
}
export default function HeaderActionButton(props: IProps & CommonProps){
    return (
        <IconButton sx={{
            padding: 0, 
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: '20px'
        }} {...props}>
            {props.children}
        </IconButton>
    );
}