import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { deliveryStore } from "../../services/DeliveryStore";

interface IProps {
    content?: string;
    count?: number;
}
export default function NoDeliveries(props: IProps){
    const { t } = useTranslation();

    const [total, setTotal] = useState<number>();

    useEffect(() => {
        if (props.count === undefined)
            deliveryStore.count().then(count => {
                setTotal(count);
            });
        else
            setTotal(props.count);
    }, [props.count]);

    return total === undefined || total <= 0 
    ? (<Grid sx={(theme) => ({
        width: '100%',
        height: '100%',
        backgroundColor: '#ffffff',
        display: 'flex',
        padding: theme.spacing(2)
    })}>
        <Typography variant="h6" sx={{
            marginLeft: 'auto',
            marginRight: 'auto'
        }}>
            {t(props.content || 'delivery.no-deliveries')}
        </Typography>
    </Grid>)
    : (<></>);
}