import { useEffect, useState } from "react";
import AppContainer from "../components/appContainers/AppContainer";
import HeaderCloseButton from "../components/buttons/HeaderCloseButton";
import NotificationList from "../components/notifications/NotificationList";
import { notificationStore } from "../services/NotificationStore";
import Loading from "../components/Loading";

export default function Notifications() {

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        notificationStore.needReload().then(async (needReload) => {
            if (needReload)
                await notificationStore.reload();
            await notificationStore.loadHistory();
        }).finally(() => setLoading(false));
        notificationStore.preventReload();

        return () => {
            notificationStore.enableReload();
            notificationStore.clearHistory();
        }
    }, []);

    return (
    <AppContainer 
        title='pages.notifications' 
        actionButton={HeaderCloseButton}
        style={{
            paddingTop: 0
        }}
    > 
        <Loading show={loading}/>
        {!loading && <NotificationList history id="new"/>}
    </AppContainer>
    );
}