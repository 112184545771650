import { AppContainerBase } from "./AppContainerBase";
import SettingsButton from "../buttons/SettingsButton";
import { CommonProps } from "@mui/material/OverridableComponent";
import React from "react";
import NotificationsButton from "../buttons/NotificationsButton";

interface IProps {
    title: string;
    children?: React.ReactNode;
}
export default function AppContainerHome(props: IProps & CommonProps){
    const {
        ...commonProps
    } = props;
    return (<AppContainerBase
        {...commonProps}
        config={{
            top: 40,
            sides: 15,
            borderRadius: 10,
            headerHeight: 110,
            headerMarginBottom: 10,
            spacing: 1,
            titleVariant: 'h6'
        }}
        actionButtons={[
            () => (<NotificationsButton />), 
            SettingsButton
        ]}
    >
        {props.children}
    </AppContainerBase>);
}