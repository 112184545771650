import { Fragment, useState } from "react";
import { ICalculatorOutput } from "../../../../interfaces/ICalculatorOutput";
import { renderToString } from 'react-dom/server';
import { decimalFormat, decimalFormatUp } from "../../../../consts/decimalFormat";
import { useTranslation } from "react-i18next";
import { ICalculatorConfig } from "../../../../interfaces/ICalculatorConfig";
import PdfInputSummary from "./PdfInputSummary";
import CalculatorPdfHeader from "./PdfHeader";
import CalculatorFooter from "../CalculatorFooter";
import CalculatorHeader from "../CalculatorHeader";
import { IUIGrainInput, IUIGrassInput } from "../../../../interfaces/payloads/ICalculatorInput";
import { CultivatedPlant } from "../../../../enums/calculator/CultivatedPlant";
import { OutputMode } from "../CalculatorOutput";
import { Typography } from "@mui/material";

const fontSize = '16px';
const Distributor = ({children}: { children: any} ) => (<div style={{
    backgroundColor: '#ABDAF3',
    fontWeight: 'bold',
    padding: '5px',
    paddingLeft: '5%',
    fontSize: fontSize
}}>
    {children}
</div>)
const Header = ({children}: { children: any} ) => (<tr style={{
    backgroundColor: '#F0F6F9',
    fontWeight: 'normal',
    padding: '5px',
    paddingLeft: '5%',
    fontSize: fontSize
}}>
    {children}
</tr>)
const HeaderGroup = ({children}: { children: any} ) => (<th colSpan={3} style={{
    backgroundColor: '#F0F6F9',
    fontWeight: 'normal', 
    padding: '5px',
    fontSize: fontSize
}}>
    {children}
</th>)
const Cell = ({children, border}: { children: any, border?: boolean} ) => (<td style={{
    fontWeight: 'normal', 
    padding: '5px',
    minWidth: '150px',
    maxWidth: '150px',
    fontSize: fontSize,
    borderTop: border ? '2px solid black' : ''
}}>
    {children}
</td>)
const Cell2 = ({children, border}: { children: any, border?: boolean} ) => (<td style={{
    fontWeight: 'normal', 
    padding: '5px',
    width: `10%`,
    minWidth: '50px',
    maxWidth: '50px',
    fontSize: fontSize,
    borderTop: border ? '2px solid black' : ''
}}>
    {children}
</td>)

export function ContentHtml(config: ICalculatorConfig, output: ICalculatorOutput){
    return `
    ${renderToString(<>
        <CalculatorPdfHeader/>
        <CalculatorPdfContent config={config} output={output}/>
    </>)}
    `;
}

interface IProps {
    config: ICalculatorConfig;
    output: ICalculatorOutput;
}
export default function CalculatorPdfContent({ config, output }: IProps){
    const { t } = useTranslation();

    const input: IUIGrassInput&IUIGrainInput = output.usedInput as any;

    const [mode] = useState<OutputMode>(input.cultivatedPlant === CultivatedPlant.Silage 
        ? OutputMode.Grass
        : OutputMode.Grain
    );
    const [grass] = useState(mode === OutputMode.Grass);
    const [grain] = useState(mode === OutputMode.Grain);

    return <div style={{
        fontSize: fontSize,
        marginTop: '20px',
        paddingTop: '20px',
        fontFamily: 'Noto Sans'
    }}>
        <PdfInputSummary input={output.usedInput} config={config}/>
        {grass && <CalculatorHeader />}
        {grass && <Typography sx={{width:'100%'}}>{t('calculator.info.header.2')}</Typography>}
        {grass && <Typography sx={{width:'100%'}}>{t('calculator.info.nutrient-max')}</Typography>}
        {grain && <Typography sx={{width:'100%'}}>{t('calculator.output.balance-desc')}</Typography>}
        N max: {output.maxNutrients.nitrogen}
        |
        P max: {output.maxNutrients.phosphorous}
        {output.batches.map(distributor => (<Fragment key={`${distributor.name}`}>
        <Distributor key={distributor.name}>
            {t(`retailer.${distributor.name}`)}
        </Distributor>
        <table style={{ width: '100%'}}>
            {grass && distributor.recommendations.map((harvest, i) => (<Fragment key={`${distributor.name}-${harvest.number}`}>
                <tbody>
                {i === 0 && <tr>
                    <th style={{width: '150px'}}></th>
                    <th></th>
                    <HeaderGroup>{t('calculator.fertilizer-nutrients')}</HeaderGroup>
                    <HeaderGroup>{t('calculator.fertilizer-and-manure-nutrients')}</HeaderGroup>
                </tr>}
                <Header>
                    <Cell>{harvest.number}. {t('calculator.harvest')}</Cell>
                    <Cell>{t('calculator.fertilizer-quantity-grass')}</Cell>
                    <Cell2>N {t('calculator.fertilizer-unit')}</Cell2>
                    <Cell2>P {t('calculator.fertilizer-unit')}</Cell2>
                    <Cell2>K {t('calculator.fertilizer-unit')}</Cell2>
                    <Cell2>N {t('calculator.fertilizer-unit')}</Cell2>
                    <Cell2>P {t('calculator.fertilizer-unit')}</Cell2>
                    <Cell2>K {t('calculator.fertilizer-unit')}</Cell2>
                </Header>
                {harvest.fertilizers.map(f => (<Fragment key={`${distributor.name}-${harvest.number}-${f.fertilizer.code}`}>
                    <tr>
                        <Cell>{f.fertilizer.name}</Cell>
                        <Cell>{f.quantity}</Cell>
                        <Cell2>{decimalFormatUp(f.fertilizerNutrients.nitrogen)}</Cell2>
                        <Cell2>{decimalFormat(f.fertilizerNutrients.phosphorous, 1)}</Cell2>
                        <Cell2>{decimalFormat(f.fertilizerNutrients.potassium)}</Cell2>
                        <Cell2>{decimalFormat(f.totalNutrients.nitrogen)}</Cell2>
                        <Cell2>{decimalFormat(f.totalNutrients.phosphorous, 1)}</Cell2>
                        <Cell2>{decimalFormat(f.totalNutrients.potassium)}</Cell2>
                    </tr>
                </Fragment>))}
                </tbody>
            </Fragment>))}
            {grain && distributor.recommendations.map((harvest, i) => (<Fragment key={`${distributor.name}-${harvest.number}`}>
                <tbody>
                {i === 0 && <tr>
                    <th></th>
                    <th></th>
                    <HeaderGroup>{t('calculator.fertilizer-nutrients')}</HeaderGroup>
                </tr>}
                <Header>
                    <Cell>{harvest.number}. {grass ? t('calculator.harvest') : t('calculator.solution')}</Cell>
                    <Cell>{grass ? t('calculator.fertilizer-quantity-grass') : t('calculator.fertilizer-quantity-grain')}</Cell>
                    <Cell2>N {t('calculator.fertilizer-unit')}</Cell2>
                    <Cell2>P {t('calculator.fertilizer-unit')}</Cell2>
                    <Cell2>K {t('calculator.fertilizer-unit')}</Cell2>
                </Header>
                {harvest.fertilizers.map(f => (<Fragment key={`${distributor.name}-${harvest.number}-${f.fertilizer.code}`}>
                    <tr>
                        <Cell>{f.fertilizer.name}</Cell>
                        <Cell>{f.quantity}</Cell>
                        <Cell2>{decimalFormatUp(f.fertilizerNutrients.nitrogen)}</Cell2>
                        <Cell2>{decimalFormat(f.fertilizerNutrients.phosphorous, 1)}</Cell2>
                        <Cell2>{decimalFormat(f.fertilizerNutrients.potassium)}</Cell2>
                    </tr>
                </Fragment>))}
                {harvest.summaries.map((sum,i) => (<Fragment key={`${distributor.name}-${harvest.number}-sum-${i}`}>
                    <tr>
                        <Cell border={i === 0}>{t(sum.name)}</Cell>
                        <Cell border={i === 0}> </Cell>
                        <Cell2 border={i === 0}>{decimalFormat(sum.nitrogen)}</Cell2>
                        <Cell2 border={i === 0}>{decimalFormat(sum.phosphorous, 1)}</Cell2>
                        <Cell2 border={i === 0}>{decimalFormat(sum.potassium)}</Cell2>
                    </tr>
                </Fragment>))}
                </tbody>
            </Fragment>))}
        </table></Fragment>))}
        {grass && <CalculatorFooter />}
    </div>;
}