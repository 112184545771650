import { INotification } from "../interfaces/INotification";
import { deliveryStore } from "./DeliveryStore";
import { yaraApi } from "./YaraApi";

export class NotificationStore {

    private subscribers: {
        [key: string]: () => void;
    } = {};
    private lock: boolean = false;
    private notifications: INotification[] = [];
    private history: INotification[] = [];

    load = async () => {
        this.notifications = await yaraApi.getNotifications();
    }
    loadHistory = async () => {
        this.history = await yaraApi.getNotificationHistory();
    }

    get = () => this.notifications;
    getHistory = () => this.history;

    clearHistory = () => this.history = [];

    preventReload = () => {
        this.lock = true;
    }
    enableReload = () => {
        this.lock = false;
    }

    needReload = async (): Promise<boolean> => {
        if (this.lock) return false;
        const ids = await yaraApi.getNotificationsIds();
        if (ids.length !== this.notifications.length) 
            return true;
        for (let id of ids){
            if (!this.notifications.find(x => x.id === id)){
                return true;
            }
        }
        return false;
    }

    reload = async (): Promise<INotification[]> => {
        if (this.lock) return this.notifications;

        const fetch = async () => {
            await deliveryStore.load();
            this.notifications = await yaraApi.getNotifications();
            this.onUpdated();
            return this.notifications;
        }

        const ids = await yaraApi.getNotificationsIds();
        if (ids.length !== this.notifications.length) 
            return await fetch();
        for (let id of ids){
            if (!this.notifications.find(x => x.id === id)){
                return await fetch();
            }
        }

        return this.notifications;
    }

    remove = (id: string): INotification[] => {
        const notification = this.notifications.find(x => x.id === id);
        if (!notification) return this.notifications;

        this.history.splice(0, 0, notification);
        this.notifications = this.notifications.filter(x => x.id !== id);
        this.onUpdated();
        return this.notifications;
    }

    private onUpdated = () => {
        for(let key in this.subscribers){
            this.subscribers[key]();
        }
    }

    subscribe = (key: string, onChange: () => void) => {
        this.subscribers[key] = onChange;
    }
    unsubscribe = (key: string) => {
        delete this.subscribers[key];
    }
}

export const notificationStore = new NotificationStore();