import ApiServiceBase from './ApiServiceBase';
import { INotification } from '../interfaces/INotification';
import { IDelivery, ISbkSyncStatus } from '../interfaces/IDelivery';
import { IUserInfo } from '../interfaces/IUserInfo';
import { IDeliveryTimeRequestPayload } from '../interfaces/payloads/deliveryTimeRequestPayload';
import { ITerms } from '../interfaces/ITerms';
import { IOrganization } from '../interfaces/IOrganization';
import { IUserContactInfo } from '../interfaces/IUserContactInfo';
import { INps } from '../interfaces/INps';
import { ILoginFirstTimeResponse } from '../interfaces/IFirstTimeLoginResponse';
import { IStorage } from '../interfaces/IStorage';
import { IUserCustomer } from '../interfaces/IUserCustomer';
import { IPdfData } from '../interfaces/payloads/IPdfData';

export class YaraApi extends ApiServiceBase {

    action = (action: string) => {
        this.post<boolean, { action: string}>('user/action', {
            action: action
        });
    }

    getUser = (): Promise<IUserInfo> => {
        return this.get<IUserInfo>('user');
    }

    getUserContactInfo = (): Promise<IUserContactInfo> => {
        return this.get<IUserContactInfo>('user/contact');
    }

    getUserCustomers = (): Promise<IUserCustomer[]> => {
        return this.get<IUserCustomer[]>('user/customers');
    }

    getNotifications = (): Promise<INotification[]> => {
        return this.get<INotification[]>('notifications');
    }

    getNotificationHistory = (): Promise<INotification[]> => {
        return this.get<INotification[]>('notifications/history');
    }

    getNotificationsIds = (): Promise<string[]> => {
        return this.get<string[]>('notifications/ids');
    }

    getDeliveries = (skip: number, take: number): Promise<IDelivery[]> => {
        return this.get<IDelivery[]>(`deliveries?skip=${skip}&take=${take}`);
    }

    getDeliveriesCount = (): Promise<number> => {
        return this.get<number>('deliveries/count');
    }

    deliverySbkSync = (id: string): Promise<boolean> => {
        return this.post<boolean, any>(`deliveries/${id}/sbk-sync`, null);
    }

    getDeliverySbkStatus = (id:string): Promise<ISbkSyncStatus> => {
        return this.get<ISbkSyncStatus>(`deliveries/${id}/sbk-status`);
    }

    getTerms = (culture: string): Promise<ITerms> => {
        return this.get<ITerms>(`terms/${culture}`);
    }

    getIssueReportPdf = (fileId: string): Promise<void> => {
        return this.getDownload(`deliveries/issue-report/${fileId}`);
    }

    acceptTerms = (id: string, marketing: boolean): Promise<boolean> => {
        return this.post<boolean, any>('terms/accept', { id: id, marketing: marketing });
    }

    setCulture = (culture: string): Promise<boolean> => {
        return this.patch<boolean>(`user/culture/${culture}`);
    }

    getEBook = (id:string): Promise<string> => {
        return this.get<string>(`ebook/${id}`);
    } 

    getOrganizations = (): Promise<IOrganization[]> => {
        return this.get<IOrganization[]>('user/organizations');
    }

    getStorage = (): Promise<IStorage|null> => {
        return this.get<IStorage|null>('storage');
    }

    setAutoInventoryOn = (org: IOrganization): Promise<boolean> => {
        return this.post<boolean, IOrganization>('user/auto-inventory/on', org);
    }

    setAutoInventoryOff = (): Promise<boolean> => {
        return this.patch<boolean>(`user/auto-inventory/off`);
    }

    sendIssueReport = (formData: FormData): Promise<boolean> => {
        return this.upload('deliveries/issue-report', formData);
    }

    sendDeliveryTimeRequest = (data: IDeliveryTimeRequestPayload): Promise<boolean> => {
        return this.post<boolean, IDeliveryTimeRequestPayload>('deliveries/delivery-time-request', data);
    }

    sendFile = (formData: FormData): Promise<boolean> => {
        return this.upload('document/send', formData);
    }

    getNps = (id: string): Promise<INps|null> => {
        return this.get<INps>(`survey/${id}`);
    }

    closeNps = (id: string): Promise<boolean> => {
        return this.post<boolean, { id: string }>(`survey/close`, { id: id });
    }

    hideNotification = (id: string): Promise<boolean> => {
        return this.patch<boolean>(`notifications/${id}/hide`);
    }

    getFirstTimeLogin = (id: string): Promise<ILoginFirstTimeResponse> => {
        return this.get<ILoginFirstTimeResponse>(`user/first-time-login/${id}`)
    }

    postFirstTimeLogin = (id: string, pinCode: number[]): Promise<ILoginFirstTimeResponse> => {
        return this.post<ILoginFirstTimeResponse, { code: number[] }>(`user/first-time-login/${id}`, { code: pinCode });
    }

    reSendFirstTimeLoginPin = (id: string): Promise<boolean> => {
        return this.post<boolean, null>(`user/first-time-login/${id}/re-send`, null);
    }

    convertToPdf = (data: IPdfData) => {
        const charset = '<meta charset="utf-8" />';
        const font = '<link href="https://fonts.googleapis.com/css2?family=Noto Sans&display=swap" rel="stylesheet">';
        data.html.content = charset+font+data.html.content;
        data.html.header = charset+font+data.html.header;
        data.html.footer = charset+font+data.html.footer;
        return this.postDownload('document/pdf', data);
    }

    getPdfBlob = (data: IPdfData) => {
        const charset = '<meta charset="utf-8" />';
        const font = '<link href="https://fonts.googleapis.com/css2?family=Noto Sans&display=swap" rel="stylesheet">';
        data.html.content = charset+font+data.html.content;
        data.html.header = charset+font+data.html.header;
        data.html.footer = charset+font+data.html.footer;
        return this.blobRequest('POST', 'document/pdf', data);
    }
}

export const yaraApi = new YaraApi(process.env.REACT_APP_YARA_API_URI as string);