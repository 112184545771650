import { Grid, styled } from "@mui/material";

const GridRow = styled(Grid)(({theme}) => ({
    
}));
GridRow.defaultProps = {
    item: true,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12
}
export default GridRow;