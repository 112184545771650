import { useEffect, useState } from "react";
import { IOption } from "../../interfaces/IOption";
import VSelect from "../validatable/VSelect";
import { IVStateProps } from "../../classes/ValidationState";
import { MenuItem, SelectProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type IProps = {
    value: number;
    options: IOption<number>[];
    onChange: (option: IOption<number>) => void;
} 
& Omit<SelectProps<number>, "onChange"> 
& IVStateProps<number>;
export default function OptionSelect(props: IProps){
    
    const { t } = useTranslation();

    const {
        value,
        options,
        onChange,
        ...vProps
    } = props;

    const [selected, setSelected] = useState<number>(props.value);
    useEffect(() => {
        if(value !== selected) setSelected(value);
    }, [value, selected]);

    return (<VSelect
        {...vProps}
        defaultValue={selected}
        value={selected}
        onChange={(e) => {
            const value = Number(e.target.value);
            const option = options.find(opt => opt.value === value);
            if (option)
            {
                setSelected(value);
                onChange(option);    
            }
        }}
    >
        {options.map(option => (
            <MenuItem key={option.text} value={option.value} sx={{
                position: 'relative'
                // display: 'inline-flex',
                // width: '100%'
            }}>
                <Typography>{t(option.text)}</Typography>{option.node}
            </MenuItem>
        ))}
    </VSelect>);
}