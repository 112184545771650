import { useNavigate } from 'react-router-dom';
import { Grid, ListItemButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import AppContainerHome from '../components/appContainers/AppContainerHome';
import { IUserInfo } from '../interfaces/IUserInfo';
import { createNavItems } from '../components/NavItems';
import { INavItem } from '../interfaces/INavItem';
import NavItem from '../components/NavItem';
import NotificationList from '../components/notifications/NotificationList';

const navKeys = [
    'ftl',
    'orders-and-deliveries',
    'survey',
    'issue-report',
    'storage',
    'calculator',
    'topical',
    'test-results',
    'customer-benefits',
    'fertilizer-guide'
];

interface IProps {
    user: IUserInfo;
}
export default function Home(props: IProps){
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [navItems] = useState<INavItem[]>(createNavItems(t, navigate, x => !!navKeys.find(k => k === x.key)));

    return (
    <AppContainerHome 
        title='pages.home'
    >
        <NotificationList id="home" top={3} reloadAlways/>
        <Grid item sx={{
            padding: 0
        }}>
            {navItems.map(navItem => (
                <ListItemButton
                    key={navItem.key}
                    {...{
                        href: navItem.href, 
                        target: navItem.target,
                        onClick: navItem.action
                    }}
                >
                    <NavItem navItem={navItem} />
                </ListItemButton>)
            )}
        </Grid>
    </AppContainerHome>
    );
}