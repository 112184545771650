import { useTranslation } from "react-i18next";
import { IBatchInput, IGrassPayload, IUIGrassInput } from "../../../interfaces/payloads/ICalculatorInput";
import { useMessager } from "../../../providers/MessagerProvider";
import { useCallback, useEffect, useState } from "react";
import { ValidationState } from "../../../classes/ValidationState";
import { IGrassConfig } from "../../../interfaces/ICalculatorConfig";
import { ICalculatorOutputResult } from "../../../interfaces/ICalculatorOutput";
import { Box, Button, Typography } from "@mui/material";
import { Quality } from "../../../enums/calculator/Quality";
import { IOption } from "../../../interfaces/IOption";
import { fertilizerApi } from "../../../services/FertilizerApi";
import { NumberKeyOf } from "../../../types/NumberKeyOf";
import GridRow from "../../GridRow";
import OptionSelect from "../../inputs/OptionSelect";
import GridSection from "../../GridSection";
import { LargeSwitch } from "../../LargeSwitch";
import ManureUsageInput from "./ManureUsageInput";
import Loading from "../../Loading";
import PlantSelect from "./PlantSelect";
import { OutputMode } from "../output/CalculatorOutput";
import { CultivatedPlant } from "../../../enums/calculator/CultivatedPlant";

function GetDisabledManureUsageInputs(input: IUIGrassInput){
    let disabled = [false, false, false];
    if (!input.useManure || input.phosphorousClass >= Quality.High) {
        disabled = disabled.map(x => !x); 
        for(let item of input.batchInputs){
            item.input = 0;
        }
    }
    if (input.potassiumClass >= Quality.High){
        input.batchInputs[0].input = 0;
        disabled[0] = true;
    }
    return disabled;
}

function Title(props: { text: string }){
    return <Typography fontWeight={"bold"} sx={{ marginTop: 'auto', marginBottom: 'auto'}}>
        {props.text}
    </Typography>
}

function Row({ children, spacing: padding }: { children: any, spacing?: number}) {
    return <GridRow sx={(t) => ({ display: 'inline-flex', width: '100%', marginTop: t.spacing(padding || 1) })}>{children}</GridRow>
};

interface IProps {
    config: IGrassConfig;
    vState: ValidationState;
    output: (output: ICalculatorOutputResult)=>void;
    modeChanged: (mode: OutputMode) => void;
}
export default function GrassInputForm(props: IProps){
    const { t } = useTranslation();
    const msg = useMessager();

    const vState = props.vState;

    const [loading, setLoading] = useState<boolean>(false);
    
    const [disabledManureInputs, setDisabledManureInputs] = useState<boolean[]>(() => GetDisabledManureUsageInputs(props.config.input));
    const [input, setInput] = useState<IUIGrassInput>({...props.config.input});
    const [cuts, setCuts] = useState<number>(input.batchInputs.length);
    const [manureMax, setManureMax] = useState<number>(props.config.maxTotalManureUsage[0]);

    useEffect(() => {
        if (input.batchInputs.length !== cuts){
            const defaultInputs: IBatchInput[] = [
                { input: 0, number: 1},
                { input: 0, number: 2},
                { input: 0, number: 3}
            ];
            setInput(prev => {
                prev.batchInputs = defaultInputs.filter(x => x.number <= cuts).map((batch, i) => {
                    return prev.batchInputs[i] || batch;
                });
                return {...prev};
            });
        }

    }, [cuts, input.batchInputs]);

    const createPayload = useCallback(() => {
        const createCopy = () => {
            const copy = JSON.parse(JSON.stringify(input));
            delete copy.cuts;
            return copy as IGrassPayload;
        };
        const payload = createCopy();
        if (payload.useManure){
            const manureType = payload.manure.type || props.config.manureTypes[0].value;
            const defaultNutrition = props.config.manureNutritions[manureType];
            if (defaultNutrition){
                payload.manure = {
                    type: manureType,
                    n: defaultNutrition.n,
                    p: defaultNutrition.p,
                    k: defaultNutrition.k
                };
            }
        }
        else {
            payload.batchInputs = [
                { number: 1, input: 0},
                { number: 2, input: 0},
                { number: 3, input: 0}
            ].filter(x => x.number <= cuts);
            payload.manure = {
                type: 0,
                n: null,
                p: null,
                k: null
            };
        }
        return payload;
    }, [input, cuts, props.config.manureNutritions, props.config.manureTypes]);

    const Send = useCallback(() => {
        if(!vState.valid) return;
        const payload = createPayload();
        
        setLoading(true);
        fertilizerApi.calculate(payload).then(result => {
            if (result) {
                input.cuts = cuts;
                input.manure = payload.manure;
                props.config.input = input;
                result.usedInput = JSON.parse(JSON.stringify(input));
                props.output(result);
            }
        }).finally(() => setLoading(false));
    }, [
        input,
        cuts,
        props,
        vState.valid,
        createPayload
    ]);

    const ResolveDisabledManureInputs = useCallback(() => {
        if (!input) return;
        const max = props.config.maxTotalManureUsage[input.phosphorousClass];
        if (max === 0 && input.useManure)
            msg.warning(t('calculator.error.high-p'), 5000);

        if (max !== manureMax)
            setManureMax(max);

        let disabled = GetDisabledManureUsageInputs(input);
        if (disabledManureInputs.map((input, i) => input !== disabled[i]).find(changed => changed)){
            setDisabledManureInputs(disabled);
        }
    }, [
        t,
        input,
        manureMax,
        props.config.maxTotalManureUsage,
        disabledManureInputs, 
        msg
    ]);

    const Options = useCallback(({ propKey, options, onChange }: {propKey: NumberKeyOf<IUIGrassInput>, options: IOption<number>[], onChange?: () => void}) => {
        if (!input) return (<></>);
        return <OptionSelect 
            id={propKey}
            value={input[propKey]}
            vState={vState}
            vRules={[
                x => x !== 0
            ]}
            required
            options={options} 
            onChange={opt => {
                setInput(prev => {
                    prev[propKey] = opt.value;
                    return {...prev};
                });
                if (onChange) onChange();
            }}
        />
    }, [input, vState]);
    
    return (<GridSection>
        <Loading show={loading}/>
        <GridSection>
            <PlantSelect value={input.cultivatedPlant} vState={vState} onChange={(v) => {
                setInput(prev => {
                    if (v === CultivatedPlant.Unknown){
                        props.modeChanged(OutputMode.None);
                        return prev;
                    }
                    if(v !== CultivatedPlant.Silage) {
                        props.config.input = {...input};
                        props.config.input.cultivatedPlant = v;
                        props.modeChanged(OutputMode.Grain);
                        return prev;
                    }
                    prev.cultivatedPlant = v;
                    return {...prev};
                });
            }}/>
            <Row><Title text={t('calculator.soil-type')}/></Row>
            <Row><Options propKey={'soil'} options={props.config.soilTypes}/></Row>
            <Row><Title text={t('calculator.phosphorous-class')}/></Row>
            <Row><Options propKey={'phosphorousClass'} options={props.config.qualityClasses} onChange={ResolveDisabledManureInputs}/></Row>
            <Row><Title text={t('calculator.potassium-class')}/></Row>
            <Row><Options propKey={'potassiumClass'} options={props.config.qualityClasses} onChange={ResolveDisabledManureInputs}/></Row>
        </GridSection>
        <GridSection>
            <Row>
                <Box sx={{display: 'flex'}}>
                    <Title text={`${t('calculator.harvest-count')}, ${t('common.qty')}`}/>
                </Box>
                <Box sx={{marginRight: 0, marginLeft: 'auto'}}>
                    <OptionSelect 
                        value={cuts}
                        vState={vState}
                        vRules={[
                            x => x !== 0
                        ]}
                        required
                        options={props.config.cuts.map(c => ({ value: c, text: String(c) }))} 
                        onChange={opt => {
                            setCuts(opt.value);
                        }}
                    />
                </Box>
            </Row>
        </GridSection>
        <GridSection>
            <Row>
                <Box sx={{display: 'flex'}}>
                    <Title text={t('calculator.manure-in-use')}/>
                </Box>
                <Box sx={{marginRight: 0, marginLeft: 'auto'}}>
                    <LargeSwitch checked={input.useManure} onChange={(e) => {
                        setInput(prev => {
                            prev.useManure = !prev.useManure
                            ResolveDisabledManureInputs();
                            return {...prev};
                        });
                    }}/>
                </Box>
            </Row>
        </GridSection>
        {input.useManure && (<>
            <GridSection>
                <Row><Typography>{t('calculator.info.liquid-manure-in-use')}</Typography></Row>
                <Row><Title text={`${t('calculator.used-manure')}, ${t('calculator.tons-per-hectare-per-harvest')}`}/></Row>
                <ManureUsageInput 
                    vState={vState} 
                    options={props.config.manureUsageValues} 
                    maxAllowed={manureMax}
                    cuts={input.batchInputs.filter(x => x.number <= cuts)}
                    disable={disabledManureInputs}
                />
            </GridSection>
        </>)}
        <GridSection>
            <Row><Title text={t('calculator.grass-mix')}/></Row>
            <Row><Options propKey={'grassMix'} options={props.config.grassMixes}/></Row>
        </GridSection>
        <Button onClick={Send} disabled={!vState.valid}>
            {t('calculator.get-best-fertilizers')}
        </Button>
    </GridSection>);
}