
import { useState } from 'react';
import HeaderActionButton from './HeaderActionButton';
import HeaderDropDownMenu from '../appContainers/HeaderDropdownMenu';
import { CommonProps } from '@mui/material/OverridableComponent';
import MenuIcon from '../icons/MenuIcon';
import { features } from '../../consts/features';

export default features.empty('AppAuthorized') || function MenuButton(props: CommonProps){

    const [showMenu, setShowMenu] = useState<boolean>(false);

    return (<>
        <HeaderActionButton {...props} onClick={() => setShowMenu(true)}>
            <MenuIcon style={{
                height: '24px', 
                width: '24px'
            }}/>
        </HeaderActionButton>
        {showMenu && (<HeaderDropDownMenu top={15} onClose={() => setShowMenu(false)} />)}
    </>);
}