import { Typography } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import { IParagraph } from "../interfaces/ITerms";
import { useState } from "react";
import { TextToHtml } from "../classes/TextToHtml";

const textToHtml = new TextToHtml();

export default function Paragraph(props: IParagraph & CommonProps){

    const [jsxContent] = useState<(JSX.Element)[]>(() => {
        return textToHtml.toElements(props.content || '');
    });

    return (<div {...props} style={{marginTop: '10px'}}>
        {props.title && (
            <Typography variant='subtitle2' sx={(theme) => ({
                fontWeight: 'bold'
            })}>
                {props.title}
            </Typography>)
        }
        {jsxContent}
    </div>)
}