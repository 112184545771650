import { Grid, Typography } from "@mui/material";
import GridRow from "../../GridRow";
import GridSection from "../../GridSection";
import Subtitle2 from "../../labels/Subtitle2";
import { useTranslation } from "react-i18next";
import { ICalculatorConfig } from "../../../interfaces/ICalculatorConfig";
import { IOption } from "../../../interfaces/IOption";
import { IUIGrainInput, IUIGrassInput, UIInput } from "../../../interfaces/payloads/ICalculatorInput";
import { OutputMode } from "./CalculatorOutput";
import { useState } from "react";

const leftSize = 6;
const rightSize = 12 - leftSize;

function Left({ children }: any){
    const sz = leftSize;
    return (<Grid item xs={sz} sm={sz} md={sz} lg={sz}>
        {children}
    </Grid>);
}
function Right({ children }: any){
    const sz = rightSize;
    return (<Grid item xs={sz} sm={sz} md={sz} lg={sz}>
        {children}
    </Grid>);
}

function resolve<T>(value: T, options: IOption<T>[]) {
    return options.find(x => x.value === value)?.text ?? '';
}

function InputItem<T>({ title, value, options }: { title: string, value: T, options?: IOption<T>[]}){
    const { t } = useTranslation();

    return (<>
        <Left>
            <Subtitle2>
                {t(title)}
            </Subtitle2>
        </Left>
        <Right>
            <Typography>
                {options ? t(resolve(value, options)) : String(value)}
            </Typography>
        </Right>
    </>);
}

interface IProps {
    mode: OutputMode;
    config: ICalculatorConfig;
    input: UIInput;
}
export default function CalculatorInputSummary(props: IProps){

    const { t } = useTranslation();

    const config = props.config;
    const input: IUIGrassInput&IUIGrainInput = props.input as any;
    
    const [grass] = useState(props.mode === OutputMode.Grass);
    const [grain] = useState(props.mode === OutputMode.Grain);
    
    return (<GridSection>
        <Grid container sx={{display:'flex'}}>
            <InputItem title="calculator.cultivated-plant" 
                value={input.cultivatedPlant} options={config.cultivatedPlants}
            />
            {input.cropLevel && <InputItem title="calculator.crop-level" 
                value={input.cropLevel}
            />}
            <InputItem title="calculator.soil-type" 
                value={input.soil} options={config.soilTypes}
            />
            <InputItem title="calculator.phosphorous-class" 
                value={input.phosphorousClass} options={config.qualityClasses}
            />
            {input.potassiumClass && <InputItem title="calculator.potassium-class" 
                value={input.potassiumClass} options={config.qualityClasses}
            />}
            {grass && (<>
                {input.cuts && <InputItem title="calculator.harvest-count" 
                    value={input.cuts}
                />}
            </>)}
            {grain && (<>
                {input.phosphorousFix ? <InputItem title={t('calculator.p-fix')}
                    value={`${input.phosphorousFix} kg P/${t('unit.hect')}`}
                /> : <InputItem title={t('calculator.p-fix')}
                    value={`${t('common.no')}`}
                />}
                <InputItem title="calculator.p-target-short"
                    value={`${input.phosphorousTarget}`}
                />
                <InputItem title="calculator.planned-n-short"
                    value={input.batchInputs.map(bi => bi.input).reduce((a,b) => a + b, 0)}
                />
                {input.batchInputs.length > 1 && (<>
                    <InputItem title="calculator.grain-n-1"
                        value={input.batchInputs[0].input}
                    />
                </>)}
                {input.batchInputs.length > 1 && (<>
                    <InputItem title="calculator.grain-n-2"
                        value={input.batchInputs[1].input}
                    />
                </>)}
            </>)}
            {grass && input.useManure && (<>
                <Left>
                    <Subtitle2>
                        {t('calculator.used-manure')}
                    </Subtitle2>
                </Left>
                <Right>
                    {input.batchInputs.filter(cut => cut.number <= input.cuts).map(cut => (<Typography key={`cut-manure-usage-input-value-${cut.number}`}>
                        <b>{cut.number}</b>: {cut.input} t/{t('unit.hect')}
                    </Typography>))}
                </Right>
                <Left>
                    <Subtitle2>
                        {t('calculator.manure-type')}
                    </Subtitle2>
                </Left>
                <Right>
                    <Typography>
                        {t(resolve(input.manure.type || config.manureTypes[0].value, config.manureTypes))}
                    </Typography>
                    <GridRow sx={{
                        display: 'inline-flex',
                        justifyContent: 'space-between',
                        width: '10em', 
                    }}>
                        <Typography>
                            <b>N:</b> {input.manure.n}
                        </Typography>
                        <Typography>
                            <b>P:</b> {input.manure.p}
                        </Typography>
                        <Typography>
                            <b>K:</b> {input.manure.k}
                        </Typography>
                    </GridRow>
                </Right>
            </>)}
            {input.grassMix && <InputItem title="calculator.grass-mix" 
                value={input.grassMix} options={config.grassMixes}
            />}
        </Grid>
    </GridSection>);
}