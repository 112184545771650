import { IOption } from '../interfaces/IOption';
import { IOptionGroup } from '../interfaces/IOptionGroup';
import { IProduct } from '../interfaces/IProduct';
import { yaraApi } from './YaraApi';

class ParamService {

    products: IProduct[] = [];

    reclamationTypes: IOptionGroup[] = [];

    supportedCultures: IOption<string>[] = [];

    load = async (): Promise<boolean> => {
        const promises: Array<Promise<boolean>> = [];

        promises.push(yaraApi.get<IProduct[]>('products/all').then(res => {
            this.products = res;
            return true;
        }));

        promises.push(yaraApi.get<IOptionGroup[]>('params/reclamation-types').then(res => {
            this.reclamationTypes = res;
            return true;
        }));

        promises.push(yaraApi.get<IOption<string>[]>('params/supported-cultures').then(res => {
            this.supportedCultures = res;
            return true;
        }));

        const results = await Promise.all(promises);
        return results.find(ok => !ok) === null;
    }
}

export const paramService = new ParamService();