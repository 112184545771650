import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { NotificationType } from "../../enums/NotificationType";
import { INotification } from "../../interfaces/INotification";
import DeliveryContent from "../delivery/DeliveryContent";
import { UIDateFormat } from "../../consts/uiDateFormat";
import moment from "moment";
import { useState } from "react";
import DeliveryArrivalText from "../delivery/DeliveryArrivalText";
import DeliveryArrivalDate from "../delivery/DeliveryArrivalDate";
import GridRow from "../GridRow";

export default function NotificationDetails({ delivery, notificationType }: INotification){
    const { t } = useTranslation();

    const [orderDate] = useState<moment.Moment>(moment(delivery && delivery.orderDate));
    
    const DeliveryData = () => (<>
        {delivery && (<>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{marginTop: '10px'}}>
            <DeliveryArrivalText {...delivery} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{margin: '15px', marginTop: '5px'}}>
            <DeliveryArrivalDate {...delivery} showExplanation/>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="body1" fontWeight="bold">{t('delivery.order')} {orderDate.format(UIDateFormat)}</Typography>
            <Typography variant="body1">#{delivery.nr}</Typography>
        </Grid>
        <DeliveryContent {...delivery}/>
        </>)}
    </>);

    const Content = () => {
        switch(notificationType){
            case NotificationType.DeliveryTimeConfirmation:
                return (<>
                    <DeliveryData />
                    <Button>
                        {t('common.confirm')}
                    </Button>
                </>);
                
            case NotificationType.InDelivery:
                return (<>
                    <DeliveryData />
                </>);
                
            case NotificationType.Modified:
                return (<>
                    <DeliveryData />
                </>);
                
            case NotificationType.OrderRejected:
                return (<>
                    {delivery && (<>
                        <GridRow sx={{display: 'inline-flex'}}>
                            <Typography variant="body1" fontWeight="bold">
                                {t('delivery.order')} {orderDate && (orderDate.format(UIDateFormat))}
                            </Typography>
                            {delivery.rejected && 
                                <Typography variant="body1" color='error'>
                                    &nbsp;({t('common.cancelled')})
                                </Typography>
                            }
                        </GridRow>
                        <Typography variant="body1">{`#${delivery.nr}`}</Typography>
                        <DeliveryContent {...delivery}/>
                    </>)}
                </>);

            default: return (<></>);
        }
    }

    return (
    <Grid container sx={(theme) => ({
        backgroundColor: theme.palette.Gray.light,
        paddingLeft: '20px', 
        paddingRight: '20px', 
        color: 'black'
    })}>
        <Content />
    </Grid>
    );
}