import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ValidationState } from "../../classes/ValidationState";
import { UIDateFormat } from "../../consts/uiDateFormat";
import { IDelivery } from "../../interfaces/IDelivery";
import { yaraApi } from "../../services/YaraApi";
import Subtitle2 from "../labels/Subtitle2";
import Loading from "../Loading";
import VSelect from "../validatable/VSelect";

interface IDeliveryWeek {
    week: number;
    year: number;
    desc: string;
}

interface IProps {
    data: IDelivery;
    afterSave: () => void;
}
export default function DeliveryTimeRequest(props: IProps & CommonProps){
    const { t } = useTranslation();
    const { 
        data, 
        afterSave, 
        ...commonProps
    } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [state] = useState<ValidationState>(new ValidationState());
    const [valid, setValid] = useState<boolean>();
    const [deliveryWeek, setDeliveryWeek] = useState<number>();
    const [addInfo, setAddInfo] = useState<string>(props.data.desiredDelivery?.addInfo || '');

    const [deliveryWeeks, setDeliveryWeeks] = useState<IDeliveryWeek[]>([])

    useEffect(() => {
        const weeks: IDeliveryWeek[] = [];
        const weeksToGet = 20;

        for(let next = 0; next <= weeksToGet; next++){
            const nextWeek = moment().add(next, 'week');
            const nextWeekNr = nextWeek.isoWeek();
            const nextWeekStart = nextWeek.startOf('isoWeek');
            const nextWeekEnd = moment(nextWeekStart).add(6, 'days');

            const start = nextWeekStart.year === nextWeekEnd.year
                ? `${nextWeekStart.date()}`
                : `${nextWeekStart.format(UIDateFormat)}`;
            const end = `${nextWeekEnd.format(UIDateFormat)}`;

            weeks.push({
                week: nextWeekNr,
                year: nextWeek.year(),
                desc: `${t('common.week')} ${nextWeekNr} (${start}. - ${end})`
            });
        }
        setDeliveryWeeks(weeks);
        let week = weeks.find(w => w.week === props.data.desiredDelivery?.week && w.year === props.data.desiredDelivery?.year);
        setDeliveryWeek(week?.week || weeks[0].week);
    }, [
        t,
        props.data.desiredDelivery?.week,
        props.data.desiredDelivery?.year
    ]);

    const save = () => {
        const week = deliveryWeeks.find(x => x.week === deliveryWeek);
        if(!week) return;

        setLoading(true);
        yaraApi
            .sendDeliveryTimeRequest({
                orderId: data.id,
                week: week.week,
                year: week.year,
                addInfo: addInfo
            })
            .then(() => {
                afterSave();
            })
            .finally(() => setLoading(false));
        
    }

    return loading ? (<Loading show/>) : (<Grid container {...commonProps}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Subtitle2>
                {t('delivery.select-delivery-time')}
            </Subtitle2>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{marginBottom: '20px'}}>
            <VSelect
                id='delivery-time'
                value={deliveryWeek || ''}
                onChange={(e) => setDeliveryWeek(Number(e.target.value))}
                required
                vState={state}
                onValidate={setValid}
            >
                {deliveryWeeks.map(dw => (<MenuItem key={`week-${dw.week}-${dw.year}`} value={dw.week}>{dw.desc}</MenuItem>))}
            </VSelect>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Subtitle2>
                {t('delivery.add-info-for-driver')}
            </Subtitle2>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{marginBottom: '20px'}}>
            <TextField 
                style={{width: '100%'}}
                multiline
                rows={5}
                value={addInfo}
                onChange={(e) => {
                    setAddInfo(e.target.value);
                }}
            />
        </Grid>
        <Button onClick={save} disabled={!valid}>
            {t('delivery.send-time-request')}
        </Button>
    </Grid>);
}