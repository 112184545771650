import axios, { AxiosResponse } from 'axios';
import { UserManagerInstance } from '../users/userManagerProvider';
import { IBlob } from '../interfaces/IBlob';

export default abstract class ApiServiceBase {

    protected baseUrl: string;

    
    public errorMessager: (error: string|string[], autoHide: number | null) => void;
    public loadingHider: () => void;

    /**
     *
     */
    constructor(
        baseUrl: string
    ) {
        this.baseUrl = baseUrl;
        if (this.baseUrl[this.baseUrl.length - 1] !== '/')
            this.baseUrl += '/';
        this.errorMessager = (reason) => console.log(reason);
        this.loadingHider = () => {};
    }

    /** GET http request */
    public get = <T>(endPoint: string): Promise<T> => {
        return axios
            .get<T>(this.fullUrl(endPoint))
            .then(this.handleAxiosResponse)
            .catch<T>(this.handleAxiosError);
    }

    /** POST http request */
    public post = <T, TData>(endPoint: string, data?: TData): Promise<T> => {
        return (data != null
            ? axios.post<T>(this.fullUrl(endPoint), data)
            : axios.post<T>(this.fullUrl(endPoint))
        )
            .then(this.handleAxiosResponse)
            .catch<T>(this.handleAxiosError);
    }

    /** POST multipart/form-data http request */
    public upload = (endPoint: string, formData: FormData): Promise<boolean> => {
        return axios.post(this.fullUrl(endPoint), formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(this.handleAxiosResponse)
        .catch(this.handleAxiosError);
    }

    /** GET file and download to user */
    public getDownload = (endPoint: string): Promise<void> => {
        return this.blobRequest('GET', endPoint).then(blob => {
            if(!blob) return;
            this.downloadBlob(blob);
        });
    }

    /** POST file and download to user */
    public postDownload = (endPoint: string, data: any): Promise<void> => {
        return this.blobRequest('POST', endPoint, data).then(blob => {
            if(!blob) return;
            this.downloadBlob(blob);
        });
    }

    public downloadBlob = (blob: IBlob) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(blob.data);
    
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', blob.name); //or any other extension
        document.body.appendChild(link);
        link.click();
    
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    public blobRequest = (method: string, endPoint: string, data?: any): Promise<IBlob|null> => {
        return axios({
            url: this.fullUrl(endPoint),
            method: method,
            data: data,
            responseType: 'blob'
        }).then(response => {
            var name = response.headers['content-disposition']?.split('filename=')[1].split(';')[0];
            if(!name) return null;
            const blob = {
                name: name,
                data: response.data
            } as IBlob;
            return blob;
        });
    }

    /** PATCH http request */
    public patch = <T>(endPoint: string, data?: any): Promise<T> => {
        return (data != null
            ? axios.patch<T>(this.fullUrl(endPoint), data)
            : axios.patch<T>(this.fullUrl(endPoint))
        )
            .then(this.handleAxiosResponse)
            .catch<T>(this.handleAxiosError);
    }

    /** DELETE http request */
    public delete = <T>(endPoint: string): Promise<T> => {
        return axios.delete<T>(this.fullUrl(endPoint))
            .then(this.handleAxiosResponse)
            .catch<T>(this.handleAxiosError);
    }

    /** Common handling for axios request responses. */
    protected handleAxiosResponse = <T>(res: AxiosResponse<T>): T => {
        return res.status === 204 ? (null as any) : res.data;
    }

    /** Common handling for axios request errors. */
    protected handleAxiosError = <T>(error: any): Promise<T> => {
        if (error.response && error.response.status === 401)
            UserManagerInstance.signoutRedirect();

        if(error instanceof String)
            this.errorMessager(error as string, 5000);

        else if (Array.isArray(error.response?.data))
            this.errorMessager(error.response.data as string[], 5000);

        else if(error.message)
            this.errorMessager(error.message, 5000);
        this.loadingHider();
        return Promise.reject(error);
    }

    /** Construct the full endpoint url. */
    protected fullUrl = (endPoint: string): string => {
        return this.baseUrl + endPoint;
    }

    protected fake = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            window.setTimeout(() => {
                resolve(true);
            }, 500);
        })
        //return Promise.resolve(true);
    }
}