import { Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "../../consts/routes";
import CardListItem from "../CardListItem";
import GridRow from "../GridRow";
import GridSection from "../GridSection";

export default function TermsCard(){
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (<CardListItem>
        <GridSection>
            <Typography variant="h6" fontWeight="bold">{t('pages.terms-and-conditions')}</Typography> 
        </GridSection>
        <GridRow>
            <Link variant="subtitle1" component="button" underline="none" onClick={() => navigate(routes.TermsAndConditions)}>{t('settings.app-terms')}</Link>
        </GridRow>
    </CardListItem>);
}