import { useCallback, useEffect, useState } from "react";
import { ValidationState } from "../../../../classes/ValidationState";
import { IAutofillConfig, IGrainConfig } from "../../../../interfaces/ICalculatorConfig";
import { IBatchInput, IUIGrainInput } from "../../../../interfaces/payloads/ICalculatorInput";
import { CultivatedPlant } from "../../../../enums/calculator/CultivatedPlant";
import { Theme } from "@emotion/react";
import { SxProps, Typography } from "@mui/material";
import GridRow from "../../../GridRow";
import { LargeSwitch } from "../../../LargeSwitch";
import DecimalField from "../../../inputs/DecimalField";
import { useTranslation } from "react-i18next";

const ExtraNMin = 15;

function Title(props: { text: string, sx?: SxProps<Theme> }){
    return <Typography fontWeight={"bold"} sx={{...props.sx, marginTop: 'auto', marginBottom: 'auto'}}>
        {props.text}
    </Typography>
}

function Row({ children, spacing: padding }: { children: any, spacing?: number}) {
    return <GridRow sx={(t) => ({ display: 'inline-flex', width: '100%', marginTop: t.spacing(padding || 1) })}>{children}</GridRow>
};

interface IProps {
    input: IUIGrainInput;
    configInput: IUIGrainInput;
    autofillConfig: IAutofillConfig;
    setValid: (v: boolean) => void;
    config: IGrainConfig;
    onChange: (input: IUIGrainInput) => void;
}
export default function GrainNInputForm(props: IProps){

    const { t } = useTranslation();

    const [valid, setValid] = useState<boolean>(false);
    useEffect(() => {
        props.setValid(valid);
    }, [valid, props])

    const [vState] = useState(new ValidationState((v) => {
        setValid(v);
    }));

    const [input, setInput] = useState<IUIGrainInput>(props.input);
    const [autofillConfig, setAutofillConfig] = useState(props.autofillConfig);
    const [splitN, setSplitN] = useState<boolean>(props.configInput.batchInputs.length > 1);
    const [plannedN, setPlannedN] = useState<number>();
    const [initialBatch, setInitialBatch] = useState<IBatchInput>(input.batchInputs[0] || {
        number: 1,
        input: 0
    });
    const [extraBatch, setExtraBatch] = useState<IBatchInput>(input.batchInputs[1] || {
        number: 2,
        input: 0
    });

    const getNMax = useCallback((split?: boolean) => {
        if(!autofillConfig) return 0;
        const max = autofillConfig.maxN;
        if (!split && max > autofillConfig.absoluteMaxNPerCrop)
            return autofillConfig.absoluteMaxNPerCrop;
        return max;
    }, [autofillConfig]);

    const getNMin = useCallback((max: number, split: boolean = false) => {
        const minNPerCrop = autofillConfig?.minNPerCrop || 0;
        if (props.input.cultivatedPlant === CultivatedPlant.WinterWheat)
            return minNPerCrop;
        const yieldMin = Math.round(props.config.yields.nitrogen * props.input.cropLevel);
        const min = split 
            ? yieldMin
            : yieldMin;
        return min > max ? max : min;
    }, [
        autofillConfig,
        props.input.cropLevel, 
        props.input.cultivatedPlant, 
        props.config.yields.nitrogen
    ]);

    const getInitNMin = useCallback((plannedN: number) => {
        const min = Math.ceil(plannedN * 0.5);
        return min;
    }, []);

    const nMax = getNMax(splitN);
    const nMin = getNMin(nMax, splitN);
    const initNMin = getInitNMin(input.plannedN);

    useEffect(() => {
        input.batchInputs = splitN ? [initialBatch, extraBatch] : [initialBatch];
        props.onChange(input);
    }, [props, splitN, input, initialBatch, extraBatch]);

    const ApplySplit = useCallback((plannedN: number, split?: boolean) => {
        if(autofillConfig && split){
            setInitialBatch(ib => {
                let newValue = Math.ceil(0.75 * plannedN);
                newValue = Math.round(newValue * 0.1) * 10;
                const max = autofillConfig.maxNPerCrop;
                const extraMax = plannedN - ExtraNMin;
                ib.input = newValue > max ? max : newValue;
                if (ib.input > extraMax)
                    ib.input = extraMax;
                setExtraBatch(prev => {
                    prev.input = plannedN - ib.input;
                    if (prev.input < 0) {
                        prev.input = 0;
                    }
                    return {...prev};
                });
                return {...ib};
            })
        }
        else {
            setInitialBatch(prev => {
                prev.input = plannedN;
                return {...prev};
            });
        }
    }, [autofillConfig]);
    
    useEffect(() => {
        const defaultN = (split: boolean) => {
            const max = getNMax(split);
            const min = getNMin(max, split);
            let defaultValue = Math.round(props.config.yields.nitrogen * props.input.cropLevel * 1.5);
            if (defaultValue > max)
                defaultValue = max;
            if (defaultValue < min)
                defaultValue = min;
            return Math.round(defaultValue * 0.1) * 10;
        };
        setAutofillConfig(props.autofillConfig);
        const split = defaultN(false) > 100;
        if(!plannedN && props.configInput.plannedN){
            setPlannedN(props.configInput.plannedN);
        }
        else {
            setSplitN(split);
            setInput(prev => {
                prev.plannedN = defaultN(split);
                ApplySplit(prev.plannedN, split);
                return {...prev};
            });
        }
    }, [
        ApplySplit,
        props.input.cropLevel,
        props.autofillConfig,
        props.configInput.soil, 
        props.configInput.cultivatedPlant,
        props.config.yields,
        props.configInput.plannedN
    ]);
    
    return autofillConfig ? (<>
        <Row><Title text={t('calculator.planned-n')}/></Row>
        <Row>
            <DecimalField 
                id="planned-n"
                key={`planned-n-${splitN}-${input.cultivatedPlant}-${input.soil}`}
                cornerInfo={`${nMin}-${nMax}`}
                value={input.plannedN} 
                vState={vState}
                vRules={splitN ? [
                    x => x >= nMin,
                    x => x - initialBatch.input >= ExtraNMin
                ] : [
                    x => x >= nMin
                ]}
                onChange={e => {
                    setInput(prev => {
                        if (e.target.value === ''){
                            prev.plannedN = 0;
                        }
                        else {
                            const value = Number(e.target.value);
                            prev.plannedN = isNaN(value) ? 0 : value;
                            const nMax = getNMax(splitN);
                            if (prev.plannedN > nMax)
                                prev.plannedN = nMax;
                        }
                        ApplySplit(prev.plannedN, splitN);
                        return {...prev};
                    });
                }}
            />
        </Row>
        {autofillConfig.splitAllowed && <Row>
            <Title text={t('calculator.split-n')}/>
            <LargeSwitch checked={splitN} sx={{marginLeft: '5px'}} onChange={(e) => {
                setSplitN(() => {
                    const split = e.target.checked;
                    input.batchInputs = split
                        ? [initialBatch, extraBatch]
                        : [initialBatch];
                    setInput(prev => {
                        const nMax = getNMax(split);
                        if (prev.plannedN > nMax) prev.plannedN = nMax;
                        ApplySplit(prev.plannedN, split);
                        return {...prev};
                    });
                    return split;
                });
            }}/>
        </Row>}
        {splitN && <><Row>
            <Title text={t(`calculator.grain-n-1`)} sx={{width: '50%'}}/>
            <DecimalField 
                id="grain-batch-1-n"
                cornerInfo={`${initNMin}-${autofillConfig.maxNPerCrop}`}
                vState={vState}
                vRules={[
                    x => x >= initNMin,
                    x => x <= autofillConfig.absoluteMaxNPerCrop,
                    x => input.plannedN - initialBatch.input >= ExtraNMin
                ]}
                sx={{
                    width: '50%'
                }}
                value={initialBatch.input}
                onChange={e => {
                    setInitialBatch(prev => {
                        if (e.target.value === ''){
                            prev.input = 0;
                        }
                        else {
                            const value = Number(e.target.value);
                            prev.input = isNaN(value) ? 0 : value;
                        }
                        setExtraBatch(extra => {
                            extra.input = input.plannedN - prev.input;
                            if (extra.input < 0) {
                                extra.input = 0;
                            }
                            return {...extra};
                        });
                        return {...prev};
                    });
                }}
            />
        </Row>
        <Row>
            <Title text={t(`calculator.grain-n-2`)} sx={{width: '50%'}}/>
            <DecimalField 
                id="grain-batch-2-n"
                vState={vState}
                vRules={[
                    x => x >= ExtraNMin
                ]}
                cornerInfo={`≥${ExtraNMin}`}
                sx={{
                    width: '50%'
                }}
                value={extraBatch.input}
                aria-readonly
            />
        </Row></>}
    </>) : (<></>);
}
