import { useCallback, useEffect, useState } from "react";
import { ICalculatorOutput } from "../../../interfaces/ICalculatorOutput";
import { Accordion, AccordionDetails, AccordionSummary, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExpandModeIcon from '@mui/icons-material/ExpandMore';
import GridSection from "../../GridSection";
import { ToFormData, ToPdfBlob } from "./pdf/PdfExport";
import Loading from "../../Loading";

import GridRow from "../../GridRow";
import CalculatorInputSummary from "./CalculatorInputSummary";
import { ICalculatorConfig } from "../../../interfaces/ICalculatorConfig";
import { yaraApi } from "../../../services/YaraApi";
import { IBlob } from "../../../interfaces/IBlob";
import { userService } from "../../../services/UserService";
import { useMessager } from "../../../providers/MessagerProvider";
import CardListItem from "../../CardListItem";
import CalculatorFooter from "./CalculatorFooter";
import CalculatorHeader from "./CalculatorHeader";
import GrainOutputLegend from "./GrainOutputLegend";
import { InfoOutlined } from "@mui/icons-material";
import DistributorOutput from "./DistributorOutput";

export enum OutputMode {
    None = 0,
    Grass = 1,
    Grain = 2
}

interface IProps{
    mode: OutputMode;
    config: ICalculatorConfig;
    output: ICalculatorOutput;
}
export default function CalculatorOutput(props: IProps){

    const { t } = useTranslation();
    const msg = useMessager();

    const [grass] = useState(props.mode === OutputMode.Grass);
    const [grain] = useState(props.mode === OutputMode.Grain);

    const [blob, setBlob] = useState<IBlob|null>(null);
    const [email, setEmail] = useState<string>('');
    const [output] = useState<ICalculatorOutput>(props.output);
    const [config] = useState<ICalculatorConfig>(props.config);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        userService.getUserContactInfo()
            .then((ci) => {
                setEmail(ci.email);
            });
    }, []);

    const getPdfBlob = useCallback(async () => {
        if (blob !== null) return blob;
        try {
            const plantName = config.cultivatedPlants.find(x => x.value === output.usedInput.cultivatedPlant);
            const fileName = t('calculator.pdf.filename').replace('{0}', t((plantName ? plantName.text : '')));
            const newBlob = await ToPdfBlob(
                fileName, 
                config, 
                output
            );
            if (newBlob){
                newBlob.name = `${fileName}.pdf`;
            }
            setBlob(newBlob);
            return newBlob;
        } catch(x){
            console.log(x);
        }
        return null;
    }, [
        output, 
        config, 
        t, 
        blob
    ]);

    const downloadPdf = useCallback(async () => {
        setLoading(true);
        try {
            const blob = await getPdfBlob();
            if (blob) yaraApi.downloadBlob(blob);
        } catch(x){
            console.log(x);
        }
        setLoading(false);
    }, [getPdfBlob]);

    const sendPdf = useCallback(async () => {
        setLoading(true);
        try {
            const blob = await getPdfBlob();
            if (blob)
                await yaraApi.sendFile(ToFormData(
                    blob, 
                    email,
                    t('calculator.pdf.title')
                ));
            msg.success(t('calculator.print-sent'));
        } catch (x){
            console.log(x);
        }
        setLoading(false);
    }, [email, t, msg, getPdfBlob]);

    const UsedInputValues = useCallback(() => {
        return (<Accordion defaultExpanded={true}>
            <AccordionSummary>{t('calculator.input-values')}</AccordionSummary>
            <AccordionDetails sx={{
                paddingBottom: 0
            }}>
                <CalculatorInputSummary mode={props.mode} input={props.output.usedInput} config={props.config}/>
            </AccordionDetails>
        </Accordion>);
    }, [t, props.mode, props.config, props.output.usedInput]);

    return loading ? <Loading show={loading}/> : (<GridSection>
        <UsedInputValues />
        <CalculatorHeader />
        <GridSection sx={{
            width: '100%'
        }}>
            <GridRow sx={{width:'100%', display: 'inline-flex'}}>
                <Typography><b>N max: {props.output.maxNutrients.nitrogen}</b></Typography>
                |
                <Typography><b>P max: {props.output.maxNutrients.phosphorous}</b></Typography>
            </GridRow>
        </GridSection>
        {grain && <GrainOutputLegend />}
        <Accordion disableGutters>
            <AccordionSummary expandIcon={<InfoOutlined />}
                sx={(t) => ({
                    backgroundColor: t.palette.warning.main
                })}>
                <Typography><b>{t('calculator.best-solutions')}</b></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <CardListItem>
                    {grass && <Typography sx={{width:'100%'}}>{t('calculator.info.header.2')}</Typography>}
                    {grass && <Typography sx={{width:'100%'}}>{t('calculator.info.nutrient-max')}</Typography>}
                    {grain && <Typography sx={{width:'100%'}}>{t('calculator.output.balance-desc')}</Typography>}
                    {grain && <Typography sx={{width:'100%'}}>{t('calculator.output.quantity-grain-desc')}</Typography>}
                    {grass && <Typography sx={{width:'100%'}}>{t('calculator.output.quantity-grass-desc')}</Typography>}
                </CardListItem>
            </AccordionDetails>
        </Accordion>
        {output.recommendations && output.recommendations.length > 0 && <Accordion disableGutters>
            <AccordionSummary expandIcon={<ExpandModeIcon/>}
                            sx={(t) => ({
                                backgroundColor: t.palette.warning.main
                            })}>
                <Typography fontWeight={"bold"} sx={{ marginTop: 'auto', marginBottom: 'auto'}}>
                    {t('calculator.recommendations')}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {output.recommendations.map((r,i) => <Typography key={`recomm-${i}`} sx={{margin: '5px'}}>{t(r)}</Typography>)}
            </AccordionDetails>
        </Accordion>}
        {output.batches.map(distributor => 
            <DistributorOutput 
                key={distributor.name} 
                mode={props.mode} 
                output={props.output} 
                distributor={distributor}
            />
        )}
        <Accordion expanded>
            <AccordionSummary>
                <Typography>{t('calculator.print-best-fertilizers')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Button  onClick={downloadPdf} sx={(t) => ({
                        marginTop: '10px',
                        marginBottom: '10px'
                    })}>
                    {t('common.download')} (pdf)
                </Button>
                <GridRow sx={{
                    display: 'inline-flex',
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%'
                }}>
                    <TextField placeholder={t('contact-method.email')} value={email} onChange={(e => {
                        setEmail(e.target.value);
                    })}/>
                    <Button  onClick={sendPdf} sx={(t) => ({
                        marginTop: 'auto',
                        marginBottom: 'auto',
                        width: '50%'
                    })}>
                        {t('common.send')} (pdf)
                    </Button>
                </GridRow>
            </AccordionDetails>
        </Accordion>
        {grass && <CalculatorFooter />}
    </GridSection>);
}