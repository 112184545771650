import { Grid, IconButton, Theme } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import CloseIcon from '@mui/icons-material/Close';
import { HeaderIconSize } from '../../consts/headerIconSize';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createNavItems } from "../NavItems";
import { INavItem } from "../../interfaces/INavItem";
import HeaderDropMenuButton from "../buttons/HeaderDropDownMenuButton";
import NavItem from "../NavItem";

interface IProps {
    top?: number;
    onClose: () => void;
    children?: React.ReactNode;
}
export default function HeaderDropDownMenu(props: IProps & CommonProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [navItems, setNavItems] = useState<INavItem[]>([]);
    useEffect(() => {
        setNavItems(createNavItems(t, navigate));
    }, [t, navigate]);

    return (
        <Grid container {...props} sx={(theme) => ({
            width: '100%',
            maxWidth: '320px',
            position: 'absolute',
            padding: 0,
            paddingBottom: '20px',
            top: 0,
            right: 0,
            backgroundColor: theme.palette.primary.main,
            color: '#ffffff',
            zIndex: 99
        })}>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={(theme) => ({
                display: 'flex',
                justifyContent: 'right',
                padding: theme.spacing(2)
            })}>
                <IconButton 
                    sx={(theme: Theme) => ({
                        height: HeaderIconSize, 
                        width: HeaderIconSize,
                        color: '#ffffff'
                    })}
                    onClick={() => props.onClose()} 
                >
                    <CloseIcon/>
                </IconButton>
            </Grid>
            {navItems.filter(x => x.hasAccess).map((navItem, i) => (
                <Grid key={`hddm-${navItem.key}`} container sx={(theme) => ({
                    padding: theme.spacing(0)
                })}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <HeaderDropMenuButton 
                        {...{
                            href: navItem.href, 
                            target: navItem.target,
                            onClick: () => {
                                if (navItem.action)
                                    navItem.action();
                                props.onClose();
                            }
                        }}>
                            <NavItem navItem={navItem} variant="subtitle2" color="#ffffff"/>
                        </HeaderDropMenuButton>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}