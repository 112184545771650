import moment from "moment";
import { ICalculatorOutput } from "../../../../interfaces/ICalculatorOutput";
import { yaraApi } from "../../../../services/YaraApi";
import { ContentHtml } from "./PdfContent";
import { ICalculatorConfig } from "../../../../interfaces/ICalculatorConfig";
import { IBlob } from "../../../../interfaces/IBlob";

function pdfParams(fileName: string, config: ICalculatorConfig, output: ICalculatorOutput){
    fileName = `${fileName}_${moment(output.calculationDate).format('yyyy-mm-DD')}`;
    const htmlContent = ContentHtml(config, output);
    return {
        fileName: fileName,
        html: {
            header: '',
            content: htmlContent,
            footer: ''
        },
        options: {
            marginTop: 0,
            marginBottom: 0,
        }
    };
}

export function ExportToPdf(fileName: string, config: ICalculatorConfig, output: ICalculatorOutput){
    return yaraApi.convertToPdf(pdfParams(fileName, config, output));
}

export function ToPdfBlob(fileName: string, config: ICalculatorConfig, output: ICalculatorOutput){
    return yaraApi.getPdfBlob(pdfParams(fileName, config, output));
}

export function ToFormData(blob: IBlob, email: string, title?: string, content?: string) {
    const formData = new FormData();
    formData.append('type', '1');
    formData.append('target', email);
    if (title) formData.append('title', title);
    if (content) formData.append('content', content);
    formData.append('file', blob.data, blob.name);
    return formData;
}