import { useTranslation } from "react-i18next";
import Loading from "../Loading";
import AppContainer from "./AppContainer";

export default function AppContainerEmpty(){
    const { t } = useTranslation();
    
    return (
    <AppContainer title={`...${t('common.loading')}`}>
        <Loading show/>
    </AppContainer>
    );
}